import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./signupPage.scss";
import {
  signUpService,
  verifyOtp,
  verifyUserName,
} from "../../services/loginapis.js";
import { useStore } from "../../components/store/store.ts";
import logo from "./logo.svg";
import loadinglogo from "./loadinglogo.svg";
import info from "./info.svg";
import OtpInput from "react-otp-input";

const SignUpPage = () => {
  let navigate = useNavigate();
  const { loginType, setLoginType } = useStore();
  const [email, setemail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmpassword] = useState("");
  const [iserror, setiserror] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [available, setAvailable] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const [passwordValid, setPasswordValid] = useState(null);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(null);
  const [step, setStep] = useState(1);
  const [pin, setPin] = useState("");
  const [pinMisMatch, setPinMisMatch] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSignUp = () => {
    if (!available) {
      setErrorMessage("Invalid UserName");
      setiserror(true);
      return;
    } else if (!emailValid) {
      setErrorMessage("Invalid Email");
      setiserror(true);
      return;
    } else if (!passwordValid) {
      setErrorMessage("Invalid Password");
      setiserror(true);
      return;
    } else if (!confirmPasswordValid) {
      setErrorMessage("Password Mismatch");
      setiserror(true);
      return;
    } else {
      setIsLoading(true);
      let obj = {
        username: username,
        email: email,
        role: loginType === "Users" ? "user" : "partner",
        password: password,
      };
      signUpService(obj)
        .then((response) => {
          let result = response?.data;
          if (result?.successful) {
            setiserror(false);
            setIsLoading(false);
            setStep(2);
            setToken(result.token);
            // if (loginType === "Users") {
            //   navigate("/dashboard/users/profile");
            // } else {
            //   navigate("/dashboard/accountants/profile");
            // }
          } else {
            setiserror(true);
            setIsLoading(false);
            setErrorMessage(result?.message ?? "Something went wrong!!");
          }
        })
        .catch((err) => {
          setiserror(true);
          setIsLoading(false);
          setErrorMessage(
            err.response?.data?.message ?? "Something went wrong!!"
          );
        });
    }
  };

  const handleUserName = (text) => {
    setUsername(text);
    let obj = { username: text };
    verifyUserName(obj)
      .then((response) => {
        let result = response?.data;
        if (result?.successful) {
          setAvailable(true);
        } else {
          setAvailable(false);
        }
      })
      .catch((err) => {
        setAvailable(false);
      });
  };

  const handleEmail = (e) => {
    setiserror(false);
    setemail(e.target.value);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handlePassword = (e) => {
    setiserror(false);
    setpassword(e.target.value);
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    if (strongPassword.test(e.target.value)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleConfirmPassword = (e) => {
    setiserror(false);
    setConfirmpassword(e.target.value);
    if (password === e.target.value) {
      setConfirmPasswordValid(true);
    } else {
      setConfirmPasswordValid(false);
    }
  };

  const verifyPin = () => {
    setIsLoading(true);
    let obj = {
      token,
      otp: pin,
    };
    verifyOtp(obj)
      .then((response) => {
        let result = response?.data;
        if (result?.success) {
          setiserror(false);
          setIsLoading(false);
          setStep(3);
          // if (loginType === "Users") {
          //   navigate("/dashboard/users/profile");
          // } else {
          //   navigate("/dashboard/accountants/profile");
          // }
        } else {
          setiserror(true);
          setIsLoading(false);
          setPinMisMatch(true);
        }
      })
      .catch((err) => {
        setiserror(true);
        setIsLoading(false);
        setPinMisMatch(true);
      });
  };

  return (
    <div className="login-main">
      {step === 1 ? (
        <div className="login-box">
          <div className="full-logo-box">
            <img
              className="full-logo"
              src={logo}
              alt=""
              style={{ width: "50%" }}
            />
          </div>
          <div className="toggle-box">
            <div
              className="toggle-each"
              style={{
                background: loginType === "Users" ? "#F1F4F6" : "",
                fontWeight: loginType === "Users" ? "600" : "",
                fontSize: loginType === "Users" ? "18px" : "",
              }}
              onClick={() => setLoginType("Users")}
            >
              Users
            </div>
            <div
              className="toggle-each"
              style={{
                background: loginType === "Accountants" ? "#F1F4F6" : "",
                fontWeight: loginType === "Accountants" ? "600" : "",
                fontSize: loginType === "Accountants" ? "18px" : "",
              }}
              onClick={() => setLoginType("Accountants")}
            >
              Partners
            </div>
          </div>
          {iserror && (
            <div className="prompt-div">
              <div>
                <img src={info} alt="" />
              </div>
              <div>{errorMessage}</div>
            </div>
          )}
          <div className="input-box-dot">
            <input
              className="input-inp-dot"
              type="text"
              placeholder="UserName"
              required
              id="username"
              name="username"
              autoComplete="username"
              value={username}
              onInput={(e) => {
                setiserror(false);
                handleUserName(e.target.value);
              }}
            />
            {available !== null &&
              (available ? (
                <div className="greenDot"></div>
              ) : (
                <div className="redDot"></div>
              ))}
          </div>
          <div className="input-box-dot">
            <input
              className="input-inp-dot"
              type="text"
              placeholder="Email"
              required
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onInput={handleEmail}
            />
            {emailValid !== null &&
              (emailValid ? (
                <div className="greenDot"></div>
              ) : (
                <div className="redDot"></div>
              ))}
          </div>
          <div className="input-box-dot">
            <input
              className="input-inp-dot"
              type="password"
              placeholder="Password"
              id="password"
              autoComplete="new-password"
              name="password"
              required
              value={password}
              onInput={handlePassword}
            />
            {passwordValid !== null &&
              (passwordValid ? (
                <div className="greenDot"></div>
              ) : (
                <div className="redDot"></div>
              ))}
          </div>
          <div className="input-box-dot">
            <input
              className="input-inp-dot"
              type="password"
              placeholder="Confirm Password"
              id="confirm-password"
              autoComplete="new-password"
              name="confirmPassword"
              required
              value={confirmPassword}
              onInput={handleConfirmPassword}
            />
            {confirmPasswordValid !== null &&
              (confirmPasswordValid ? (
                <div className="greenDot"></div>
              ) : (
                <div className="redDot"></div>
              ))}
          </div>
          <div className="login-btn" onClick={handleSignUp}>
            Create Account
          </div>
        </div>
      ) : step === 2 ? (
        <div className="login-box">
          <div className="full-logo-box">
            <img
              className="full-logo"
              src={logo}
              alt=""
              style={{ width: "50%" }}
            />
          </div>
          <div className="desktopUI">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="https://chatsgx.s3-us-east-2.amazonaws.com/chiranjib.jena@gmail.com/1596869973584.png"
                  alt=""
                  style={{ width: "70px" }}
                />
              </div>
            </div>
            <div className="stepOneTitle">
              {pinMisMatch
                ? "Pin Mismatch. Please Type The Correct Pin."
                : `Enter The Code That Just Went To ${email}`}
            </div>
            <div className="otpView">
              <OtpInput
                value={pin}
                onChange={setPin}
                numInputs={6}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                shouldAutoFocus
                containerStyle="otpInputWrapper"
                inputStyle="otpInput"
              />
            </div>
            <div
              style={{
                paddingTop: "75px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="whiteButton" onClick={handleSignUp}>
                Resend Code
              </div>
              <div
                className="colouredButton"
                style={{
                  background: "#176AB4",
                  opacity: pin.length === 6 ? 1 : 0.5,
                }}
                onClick={(e) => {
                  if (pin.length === 6) {
                    verifyPin();
                  }
                }}
              >
                Proceed
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: "20px",
            color: "#464B4E",
            textAlign: "center",
            height: "50vh",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            Congratulations <span style={{ fontWeight: 700 }}>{username},</span>
            Your Account Has Been Created Successfully
          </div>
          <br />
          <div
            style={{
              width: "200px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#176AB4",
              color: "white",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => navigate("/login")}
          >
            Login
          </div>
        </div>
      )}
      <div>
        {isLoading ? (
          <div className="otclogo">
            <img className="otclogoimg" src={loadinglogo} alt="" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
