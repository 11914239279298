import axios from "axios";

export const loginApi = async (body) => {
  try {
    const data = await axios.post(
      "https://gxauth.apimachine.com/gx/user/auth/login",
      body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const registerApp = async ({ email, app_code }) => {
  try {
    const data = await axios.post(
      "https://comms.globalxchange.io/gxb/apps/register/user",
      {
        email,
        app_code,
        fromAppCreation: true,
      }, 
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //   },
      // }
    );
    return data;
  } catch (error) {
    return error;
  }
};
