import React, { useState, useEffect } from "react";
import { useCoinContextData } from "../../context/CoinContext";
import Skeleton from "react-loading-skeleton";
import "./mypaths.scss";
import axios from "axios";

// images
import dummy from "./dummy.svg";
import closepop from "../../static/images/dashboard/closepop.svg";
import lg1 from "../../static/images/login/lg1.svg";
import backIcon from "../../static/images/icons/backIcon.svg";
import nextIcon from "../../static/images/icons/nextIcon.svg";
import dropdownIcon from "../../static/images/icons/dropdownIcon.svg";

const MyPaths = ({ search, localRefresh }) => {
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { mypathsMenu, setMypathsMenu } = useCoinContextData();
  const [partnerPathData, setPartnerPathData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerStepsData, setPartnerStepsData] = useState([]);
  const [selectedPathId, setSelectedPathId] = useState("");
  const [pathActionEnabled, setPathActionEnabled] = useState(false);
  const [pathActionStep, setPathActionStep] = useState(1);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState("");
  const [selectedStep, setSelectedStep] = useState([]);
  const [stepActionEnabled, setStepActionEnabled] = useState(false);
  const [stepActionStep, setStepActionStep] = useState(1);
  const [selectedPath, setSelectedPath] = useState([]);
  const [viewPathEnabled, setViewPathEnabled] = useState(false);
  const [viewPathLoading, setViewPathLoading] = useState(false);
  const [viewPathData, setViewPathData] = useState([]);

  // edit path
  const [editPathData, setEditPathData] = useState({});
  const pathTypeList = ["education", "career", "immigration"];
  const pathGradeList = ["9", "10", "11", "12"];
  const gradePointAvg = ["0-35", "36-60", "61-75", "76-85", "86-95", "96-100"];
  const curriculumList = ["IB", "CBSE", "IG", "CSE", "ICSE", "Nordic"];
  const streamList = ["MPC", "BIPC", "CEC", "HEC", "MEC"];
  const financeList = ["0-25L", "25L-75L", "75L-3CR", "3CR+"];
  const [universityList, setUniversityList] = useState([]);
  const [universityLoading, setUniversityLoading] = useState(true);
  const [universitySearch, setUniversitySearch] = useState("");
  const personalityList = [
    "realistic",
    "social",
    "enterprising",
    "artistic",
    "conventional",
  ];
  const degreeList = ["Bachelor", "Master", "Diploma"];
  const pathStatusList = ["active", "inactive"];

  // edit step
  const [editStepData, setEditStepdata] = useState({});
  const [mapPathData, setMapPathData] = useState([]);
  const [macroServiceData, setMacroServiceData] = useState([]);
  const [microServiceData, setMicroServiceData] = useState([]);
  const [nanoServiceData, setNanoServiceData] = useState([]);
  const stepTypeList = ["free", "paid"];
  const stepStatusList = ["active", "inactive"];
  const [pathSearchTerm, setPathSearchTerm] = useState("");
  const [macroSearchTerm, setMacroSearchTerm] = useState("");
  const [microSearchTerm, setMicroSearchTerm] = useState("");
  const [nanoSearchTerm, setNanoSearchTerm] = useState("");
  const [macroServicesSameType, setMacroServicesSameType] = useState([]);
  const [macroServicesDiffType, setMacroServicesDiffType] = useState([]);
  const [microServicesSameType, setMicroServicesSameType] = useState([]);
  const [microServicesDiffType, setMicroServicesDiffType] = useState([]);
  const [nanoServicesSameType, setNanoServicesSameType] = useState([]);
  const [nanoServicesDiffType, setNanoServicesDiffType] = useState([]);

  const getAllPaths = () => {
    setLoading(true);
    let email = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get?email=${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "partnerPathData result");
        setPartnerPathData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in partnerPathData");
      });
  };

  const getAllSteps = () => {
    setLoading(true);
    let email = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/steps/get?email=${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "partnerStepsData result");
        setPartnerStepsData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in partnerStepsData");
      });
  };

  useEffect(() => {
    getAllPaths();
    getAllSteps();
  }, [localRefresh]);

  const filteredPartnerPathData = partnerPathData?.filter((entry) =>
    entry?.nameOfPath?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const filteredPartnerStepsData = partnerStepsData?.filter((entry) =>
    entry?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  // path functions
  const myPathsTimeout = () => {
    setTimeout(reload1, 2000);
  };

  function reload1() {
    getAllPaths();
    setPathActionEnabled(false);
    setPathActionStep(1);
    setSelectedPathId("");
    setSelectedPath([]);
    setUniversityList("");
    setUniversitySearch("");
  }

  const deletePath = () => {
    setActionLoading(true);
    axios
      .delete(
        `https://oldnaavi.apimachine.com/paths/delete/${selectedPathId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "deletePath result");
        if (result?.status) {
          setActionLoading(false);
          setPathActionStep(3);
          myPathsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "error in deletePath");
      });
  };

  const resetPathAction = () => {
    setPathActionEnabled(false);
    setPathActionStep(1);
    setSelectedPathId("");
    setSelectedPath([]);
    setViewPathData([]);
    setEditPathData({});
  };

  const editPathFields = () => {
    setActionLoading(true);
    // console.log(editPathData, "editPathData");

    let obj = editPathData;

    const areArraysEqualByGrade = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].grade !== array2[i].grade) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByStream = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].stream !== array2[i].stream) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByGradeAvg = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].grade_avg !== array2[i].grade_avg) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByCurriculum = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].curriculum !== array2[i].curriculum) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByFinance = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].finance !== array2[i].finance) {
          return false;
        }
      }
      return true;
    };

    if (
      obj?.grade &&
      selectedPath?.grade &&
      areArraysEqualByGrade(obj?.grade, selectedPath?.grade)
    ) {
      const { grade, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.stream &&
      selectedPath?.stream &&
      areArraysEqualByStream(obj?.stream, selectedPath?.stream)
    ) {
      const { stream, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.grade_avg &&
      selectedPath?.grade_avg &&
      areArraysEqualByGradeAvg(obj?.grade_avg, selectedPath?.grade_avg)
    ) {
      const { grade_avg, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.curriculum &&
      selectedPath?.curriculum &&
      areArraysEqualByCurriculum(obj?.curriculum, selectedPath?.curriculum)
    ) {
      const { curriculum, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.financialSituation &&
      selectedPath?.financialSituation &&
      areArraysEqualByFinance(
        obj?.financialSituation,
        selectedPath?.financialSituation
      )
    ) {
      const { financialSituation, ...rest } = obj;
      obj = rest;
    }

    // console.log(obj, "final api body");

    axios
      .put(
        `https://oldnaavi.apimachine.com/paths/update/${selectedPathId}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "editPathFields result");
        if (result?.status) {
          setActionLoading(false);
          setPathActionStep(7);
          myPathsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "editPathFields error");
      });
  };

  const viewPath = (path) => {
    setViewPathLoading(true);
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get?nameOfPath=${path}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data[0];
        // console.log(result, "viewPathData result");
        setViewPathData(result);
        setViewPathLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in fetching viewPathData");
      });
  };

  const handleMultipleInputArray = (item, array, object) => {
    // Check if the item is already selected

    const isSelected = editPathData[array]?.filter((itemm) => {
      return itemm[object] === item;
    });

    // If it's already selected, remove it from the array, otherwise add it
    if (Array.isArray(isSelected) && isSelected?.length > 0) {
      setEditPathData((prev) => {
        return {
          ...prev,
          [array]: Array.isArray(prev[array])
            ? prev[array]?.filter(
                (selectedItem) => selectedItem[object] !== item
              )
            : [{ [object]: item }],
        };
      });
    } else {
      setEditPathData((prev) => {
        return {
          ...prev,
          [array]: Array.isArray(prev[array])
            ? [
                ...prev[array],
                {
                  [object]: item,
                },
              ]
            : [{ [object]: item }],
        };
      });
    }
  };

  const getAllUniversities = () => {
    setUniversityLoading(true);
    axios
      .get(`https://oldnaavi.apimachine.com/universities/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getAllUniversities result');
        setUniversityList(result);
        setUniversityLoading(false);
      })
      .catch((error) => {
        console.log("error in getAllUniversities");
      });
  };

  useEffect(() => {
    if (pathActionEnabled && pathActionStep === 4) {
      getAllUniversities();
    }
  }, [pathActionEnabled, pathActionStep]);

  // step functions
  const myStepsTimeout = () => {
    setTimeout(reload2, 2000);
  };

  function reload2() {
    getAllSteps();
    setStepActionEnabled(false);
    setStepActionStep(1);
    setSelectedStepId("");
    setSelectedStep([]);
    setEditStepdata({});
    setPathSearchTerm("");
    setMacroSearchTerm("");
    setMicroSearchTerm("");
    setNanoSearchTerm("");
    setMapPathData([]);
    setMacroServiceData([]);
    setMicroServiceData([]);
    setNanoServiceData([]);
    setMacroServicesSameType([]);
    setMacroServicesDiffType([]);
    setMicroServicesSameType([]);
    setMicroServicesDiffType([]);
    setNanoServicesSameType([]);
    setNanoServicesDiffType([]);
  }

  const deleteStep = () => {
    setActionLoading(true);
    axios
      .delete(
        `https://oldnaavi.apimachine.com/steps/delete/${selectedStepId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "deleteStep result");
        if (result?.status) {
          setActionLoading(false);
          setStepActionStep(3);
          myStepsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "error in deleteStep");
      });
  };

  const resetStepAction = () => {
    setStepActionEnabled(false);
    setStepActionStep(1);
    setSelectedStepId("");
    setSelectedStep([]);
    setEditStepdata({});
    setPathSearchTerm("");
    setMacroSearchTerm("");
    setMicroSearchTerm("");
    setNanoSearchTerm("");
    setMapPathData([]);
    setMacroServiceData([]);
    setMicroServiceData([]);
    setNanoServiceData([]);
    setMacroServicesSameType([]);
    setMacroServicesDiffType([]);
    setMicroServicesSameType([]);
    setMicroServicesDiffType([]);
    setNanoServicesSameType([]);
    setNanoServicesDiffType([]);
  };

  const getServiceDataForMacro = (cost) => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Macro&cost=${cost}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceDataForMacro result');
        setMacroServiceData(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceDataForMacro");
      });
  };

  const getServiceDataForMicro = (cost) => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Micro&cost=${cost}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceDataForMicro result');
        setMicroServiceData(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceDataForMicro");
      });
  };

  const getServiceDataFornano = (cost) => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Nano&cost=${cost}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceDataFornano result');
        setNanoServiceData(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceDataFornano");
      });
  };

  useEffect(() => {
    if (stepActionEnabled) {
      let email = userDetails?.user?.email;
      axios
        .get(`https://oldnaavi.apimachine.com/paths/get?email=${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let result = response?.data?.data;
          // console.log(result, "mapPathData result");
          setMapPathData(result);
        })
        .catch((error) => {
          console.log(error, "error in mapPathData");
        });
    }

    if (stepActionEnabled && editStepData?.macro_cost) {
      if (editStepData?.macro_cost && editStepData?.macro_cost === "free") {
        getServiceDataForMacro("free");
      } else {
        getServiceDataForMacro("paid");
      }
    }

    if (stepActionEnabled && editStepData?.micro_cost) {
      if (editStepData?.micro_cost && editStepData?.micro_cost === "free") {
        getServiceDataForMicro("free");
      } else {
        getServiceDataForMicro("paid");
      }
    }

    if (stepActionEnabled && editStepData?.nano_cost) {
      if (editStepData?.nano_cost && editStepData?.nano_cost === "free") {
        getServiceDataFornano("free");
      } else {
        getServiceDataFornano("paid");
      }
    }
  }, [
    stepActionEnabled,
    editStepData?.macro_cost,
    editStepData?.micro_cost,
    editStepData?.nano_cost,
  ]);

  const editStepFields = () => {
    setActionLoading(true);
    let obj = editStepData;

    const areArraysEqualByServices = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }
      return true;
    };

    if (
      editStepData?.macro_cost &&
      selectedStep?.macro_cost !== editStepData?.macro_cost
    ) {
      obj.macroservices = macroServicesDiffType;
    } else if (
      editStepData?.macro_cost &&
      selectedStep?.macro_cost === editStepData?.macro_cost
    ) {
      if (
        !areArraysEqualByServices(
          macroServicesSameType,
          selectedStep?.macroservices
        )
      ) {
        obj.macroservices = macroServicesSameType;
      }
    }

    if (
      editStepData?.micro_cost &&
      selectedStep?.micro_cost !== editStepData?.micro_cost
    ) {
      obj.microservices = microServicesDiffType;
    } else if (
      editStepData?.micro_cost &&
      selectedStep?.micro_cost === editStepData?.micro_cost
    ) {
      if (
        !areArraysEqualByServices(
          microServicesSameType,
          selectedStep?.microservices
        )
      ) {
        obj.microservices = microServicesSameType;
      }
    }

    if (
      editStepData?.nano_cost &&
      selectedStep?.nano_cost !== editStepData?.nano_cost
    ) {
      obj.nanoservices = nanoServicesDiffType;
    } else if (
      editStepData?.nano_cost &&
      selectedStep?.nano_cost === editStepData?.nano_cost
    ) {
      if (
        !areArraysEqualByServices(
          nanoServicesSameType,
          selectedStep?.nanoservices
        )
      ) {
        obj.nanoservices = nanoServicesSameType;
      }
    }

    console.log(obj, "obj");

    axios
      .put(
        `https://oldnaavi.apimachine.com/steps/update/${selectedStepId}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "editStepFields result");
        if (result?.status) {
          setActionLoading(false);
          setStepActionStep(8);
          myStepsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "editStepFields error");
      });
  };

  const handleMultipleServicesMacroSameType = (each) => {
    // console.log(each?._id, "each?._id, sametype");
    if (editStepData?.macro_cost) {
      if (macroServicesSameType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setMacroServicesSameType(
          macroServicesSameType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setMacroServicesSameType([...macroServicesSameType, each?._id]);
      }
    }
  };

  const handleMultipleServicesMacroDiffType = (each) => {
    // console.log(each?._id, "each?._id, difftype");
    if (editStepData?.macro_cost) {
      if (macroServicesDiffType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setMacroServicesDiffType(
          macroServicesDiffType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setMacroServicesDiffType([...macroServicesDiffType, each?._id]);
      }
    }
  };

  const handleMultipleServicesMicroSameType = (each) => {
    // console.log(each?._id, "each?._id, sametype");
    if (editStepData?.micro_cost) {
      if (microServicesSameType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setMicroServicesSameType(
          microServicesSameType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setMicroServicesSameType([...microServicesSameType, each?._id]);
      }
    }
  };

  const handleMultipleServicesMicroDiffType = (each) => {
    // console.log(each?._id, "each?._id, difftype");
    if (editStepData?.micro_cost) {
      if (microServicesDiffType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setMicroServicesDiffType(
          microServicesDiffType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setMicroServicesDiffType([...microServicesDiffType, each?._id]);
      }
    }
  };

  const handleMultipleServicesNanoSameType = (each) => {
    // console.log(each?._id, "each?._id, sametype");
    if (editStepData?.nano_cost) {
      if (nanoServicesSameType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setNanoServicesSameType(
          nanoServicesSameType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setNanoServicesSameType([...nanoServicesSameType, each?._id]);
      }
    }
  };

  const handleMultipleServicesNanoDiffType = (each) => {
    // console.log(each?._id, "each?._id, difftype");
    if (editStepData?.nano_cost) {
      if (nanoServicesDiffType?.includes(each?._id)) {
        // If the _id is already selected, remove it
        setNanoServicesDiffType(
          nanoServicesDiffType?.filter((o) => o !== each?._id)
        );
      } else {
        // If the _id is not selected, add it
        setNanoServicesDiffType([...nanoServicesDiffType, each?._id]);
      }
    }
  };

  return (
    <div className="mypaths">
      <div className="mypaths-menu">
        <div
          className="each-mypath-menu"
          style={{
            fontWeight: mypathsMenu === "Paths" ? "700" : "",
            background:
              mypathsMenu === "Paths" ? "rgba(241, 241, 241, 0.5)" : "",
          }}
          onClick={() => {
            setMypathsMenu("Paths");
            if (viewPathEnabled) {
              setViewPathEnabled(false);
              setViewPathData([]);
            }
          }}
        >
          Paths
        </div>
        <div
          className="each-mypath-menu"
          style={{
            fontWeight: mypathsMenu === "Steps" ? "700" : "",
            background:
              mypathsMenu === "Steps" ? "rgba(241, 241, 241, 0.5)" : "",
          }}
          onClick={() => {
            setMypathsMenu("Steps");
            if (viewPathEnabled) {
              setViewPathEnabled(false);
              setViewPathData([]);
            }
          }}
        >
          Steps
        </div>
      </div>
      <div className="mypaths-content">
        {viewPathEnabled ? (
          <div className="viewpath-container">
            <div className="viewpath-top-area">
              <div>Your Selected Path:</div>
              {viewPathLoading ? (
                <Skeleton width={150} height={30} />
              ) : (
                <div className="viewpath-bold-text">
                  {viewPathData?.length > 0
                    ? viewPathData?.destination_institution
                    : ""}
                </div>
              )}
              {viewPathLoading ? (
                <Skeleton width={500} height={20} />
              ) : (
                <div className="viewpath-des">
                  {viewPathData?.length > 0 ? viewPathData?.description : ""}
                </div>
              )}
              <div
                className="viewpath-goBack-div"
                onClick={() => {
                  setViewPathEnabled(false);
                }}
              >
                Go Back
              </div>
            </div>
            <div className="viewpath-steps-area">
              {viewPathLoading
                ? Array(6)
                    .fill("")
                    .map((e, i) => {
                      return (
                        <div
                          className="viewpath-each-j-step viewpath-relative-div"
                          key={i}
                        >
                          <div className="viewpath-each-j-img">
                            <Skeleton width={75} height={75} />
                          </div>
                          <div className="viewpath-each-j-step-text">
                            <Skeleton width={200} height={30} />
                          </div>
                          <div className="viewpath-each-j-step-text1">
                            <Skeleton width={250} height={25} />
                          </div>
                          <div className="viewpath-each-j-amount-div">
                            <div className="viewpath-each-j-amount">
                              <Skeleton width={100} height={30} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : viewPathData?.length > 0
                ? viewPathData?.StepDetails?.map((e, i) => {
                    return (
                      <div
                        className="viewpath-each-j-step viewpath-relative-div"
                        key={i}
                      >
                        <div className="viewpath-each-j-img">
                          <img src={e?.icon} alt="" />
                        </div>
                        <div className="viewpath-each-j-step-text">
                          {e?.name}
                        </div>
                        <div className="viewpath-each-j-step-text1">
                          {e?.description}
                        </div>
                        <div className="viewpath-each-j-amount-div">
                          <div className="viewpath-each-j-amount">
                            {e?.cost}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : mypathsMenu === "Paths" && !viewPathEnabled ? (
          <>
            <div className="mypathsNav">
              <div className="mypaths-name-div">Name</div>
              <div className="mypaths-description-div">Description</div>
            </div>
            <div className="mypathsScroll-div">
              {loading
                ? Array(10)
                    .fill("")
                    .map((e, i) => {
                      return (
                        <div className="each-mypaths-data" key={i}>
                          <div className="each-mypaths-name">
                            <Skeleton width={100} height={30} />
                          </div>
                          <div className="each-mypaths-desc">
                            <Skeleton width={"100%"} height={30} />
                          </div>
                        </div>
                      );
                    })
                : filteredPartnerPathData?.map((e, i) => {
                    return (
                      <div
                        className="each-mypaths-data"
                        key={i}
                        onClick={() => {
                          setPathActionEnabled(true);
                          setSelectedPathId(e?._id);
                          setSelectedPath(e);
                          // console.log(e, "selected path details");
                          viewPath(e?.nameOfPath);
                          setEditPathData((prev) => {
                            const arrayWithoutIdGrade = Array?.isArray(e?.grade)
                              ? e?.grade?.map((obj) => {
                                  const { _id, ...rest } = obj;
                                  return rest;
                                })
                              : [];
                            const arrayWithoutIdGradeAvg = Array?.isArray(
                              e?.grade_avg
                            )
                              ? e?.grade_avg?.map((obj) => {
                                  const { _id, ...rest } = obj;
                                  return rest;
                                })
                              : [];
                            const arrayWithoutIdFinance = Array?.isArray(
                              e?.financialSituation
                            )
                              ? e?.financialSituation?.map((obj) => {
                                  const { _id, ...rest } = obj;
                                  return rest;
                                })
                              : [];
                            const arrayWithoutIdCurriculum = Array?.isArray(
                              e?.curriculum
                            )
                              ? e?.curriculum?.map((obj) => {
                                  const { _id, ...rest } = obj;
                                  return rest;
                                })
                              : [];
                            const arrayWithoutIdStream = Array?.isArray(
                              e?.stream
                            )
                              ? e?.stream?.map((obj) => {
                                  const { _id, ...rest } = obj;
                                  return rest;
                                })
                              : [];
                            // console.log(arrayWithoutId, 'arrayWithoutId', arrayWithoutIdFinance, 'arrayWithoutIdFinance', arrayWithoutIdStream, 'arrayWithoutIdStream' )
                            return {
                              ...prev,
                              grade: arrayWithoutIdGrade,
                              grade_avg: arrayWithoutIdGradeAvg,
                              financialSituation: arrayWithoutIdFinance,
                              curriculum: arrayWithoutIdCurriculum,
                              stream: arrayWithoutIdStream,
                            };
                          });
                        }}
                      >
                        <div className="each-mypaths-name">{e?.nameOfPath}</div>
                        <div className="each-mypaths-desc">
                          {e?.description}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </>
        ) : (
          <>
            <div className="mypathsNav">
              <div className="mypathsName">Step name</div>
              <div className="mypathsCountry">Path name</div>
              <div className="mypathsCountry">Status</div>
              <div className="mypathsMicrosteps">Services</div>
            </div>
            <div className="mypathsScroll-div">
              {loading
                ? Array(10)
                    .fill("")
                    ?.map((e, i) => {
                      return (
                        <div className="each-mypaths-data1" key={i}>
                          <div className="each-mypaths-detail">
                            <div className="each-mypathsName">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsCountry">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsCountry">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsMicrosteps">
                              <Skeleton width={100} height={30} />
                            </div>
                          </div>
                          <div className="each-mypaths-desc">
                            <div className="each-mypaths-desc-txt">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypaths-desc-txt1">
                              <Skeleton width={"100%"} height={30} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredPartnerStepsData?.map((e, i) => {
                    return (
                      <div
                        className="each-mypaths-data1"
                        key={i}
                        onClick={() => {
                          setSelectedStep(e);
                          setSelectedStepId(e?._id);
                          setStepActionEnabled(true);
                          setMacroServicesSameType(e?.macroservices);
                          setMicroServicesSameType(e?.microservices);
                          setNanoServicesSameType(e?.nanoservices);
                        }}
                      >
                        <div className="each-mypaths-detail">
                          <div className="each-mypathsName">
                            <div>
                              <div>{e?.name}</div>
                              {/* <div
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "300",
                                }}
                              >
                                {e?._id}
                              </div> */}
                            </div>
                          </div>
                          <div className="each-mypathsCountry">
                            {e?.pathDetails[0]?.nameOfPath}
                          </div>
                          <div className="each-mypathsCountry">{e?.status}</div>
                          <div className="each-mypathsMicrosteps">
                            {e?.macroservices?.length +
                              e?.microservices?.length +
                              e?.nanoservices?.length}
                          </div>
                        </div>
                        {/* <div className="each-mypaths-desc">
                          <div className="each-mypaths-desc-txt">
                            Description
                          </div>
                          <div className="each-mypaths-desc-txt1">
                            {e?.description}
                          </div>
                        </div> */}
                      </div>
                    );
                  })}
            </div>
          </>
        )}

        {pathActionEnabled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.6)",
              zIndex: "25",
              top: "0",
              left: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="acc-popular" style={{ background: "#e5e5e5" }}>
              <div
                className="acc-popular-top"
                style={{
                  display: pathActionStep === 3 ? "none" : "",
                }}
              >
                <div className="acc-popular-head">
                  {pathActionStep > 3 ? "Edit Path" : "Path Actions"}
                </div>
                <div
                  className="acc-popular-img-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    resetPathAction();
                  }}
                >
                  <img className="acc-popular-img1" src={closepop} alt="" />
                </div>
              </div>

              {pathActionStep === 1 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setPathActionStep(4);
                      }}
                    >
                      Edit path
                    </div>
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setPathActionStep(2);
                      }}
                    >
                      Delete path
                    </div>
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setViewPathEnabled(true);
                        setPathActionEnabled(false);
                      }}
                    >
                      View path
                    </div>
                  </div>
                </>
              )}

              {pathActionStep === 2 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        deletePath();
                      }}
                    >
                      Confirm And Delete
                    </div>

                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setPathActionStep(1);
                      }}
                    >
                      Never Mind
                    </div>
                  </div>

                  {actionLoading && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {pathActionStep === 3 && (
                <div
                  className="successMsg"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Path Successfully Deleted
                </div>
              )}

              {pathActionStep === 4 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">Name</div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedPath?.nameOfPath}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Name"
                          onChange={(e) => {
                            setEditPathData((prev) => {
                              return {
                                ...prev,
                                nameOfPath: e.target.value,
                              };
                            });
                          }}
                          value={editPathData?.nameOfPath}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Description
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedPath?.description}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <textarea
                          type="text"
                          placeholder="Enter new Description"
                          onChange={(e) => {
                            setEditPathData((prev) => {
                              return {
                                ...prev,
                                description: e.target.value,
                              };
                            });
                          }}
                          value={editPathData?.description}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Current Coordinates
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedPath?.current_coordinates}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Current Coordinates"
                          onChange={(e) => {
                            setEditPathData((prev) => {
                              return {
                                ...prev,
                                current_coordinates: e.target.value,
                              };
                            });
                          }}
                          value={editPathData?.current_coordinates}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Future Coordinates
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedPath?.feature_coordinates}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Future Coordinates"
                          onChange={(e) => {
                            setEditPathData((prev) => {
                              return {
                                ...prev,
                                feature_coordinates: e.target.value,
                              };
                            });
                          }}
                          value={editPathData?.feature_coordinates}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Type of Path
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.path_type}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {pathTypeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editPathData?.path_type === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditPathData((prev) => {
                                  return {
                                    ...prev,
                                    path_type: e,
                                  };
                                });
                              }}
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Ideal Grade
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.grade?.length > 0 &&
                            selectedPath?.grade.map((e, i) => {
                              return selectedPath?.grade?.length - 1 === i
                                ? e?.grade + "th" + " "
                                : e?.grade + "th" + "," + " ";
                            })}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {pathGradeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  Array.isArray(editPathData?.grade) &&
                                  editPathData?.grade.filter((itemm) => {
                                    return itemm?.grade === e;
                                  })?.length > 0
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() =>
                                handleMultipleInputArray(e, "grade", "grade")
                              }
                            >
                              {e + "th"}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setPathActionStep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setPathActionStep(5);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pathActionStep === 5 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Ideal grade points average
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.grade_avg?.length > 0 &&
                            selectedPath?.grade_avg.map((e, i) => {
                              return selectedPath?.grade_avg?.length - 1 === i
                                ? e?.grade_avg + "%" + " "
                                : e?.grade_avg + "%" + "," + " ";
                            })}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {gradePointAvg?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  Array.isArray(editPathData?.grade_avg) &&
                                  editPathData?.grade_avg.filter((itemm) => {
                                    return itemm?.grade_avg === e;
                                  })?.length > 0
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() =>
                                handleMultipleInputArray(
                                  e,
                                  "grade_avg",
                                  "grade_avg"
                                )
                              }
                            >
                              {e + "%"}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Ideal curriculum
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.curriculum?.length > 0 &&
                            selectedPath?.curriculum.map((e, i) => {
                              return selectedPath?.curriculum?.length - 1 === i
                                ? e?.curriculum + " "
                                : e?.curriculum + "," + " ";
                            })}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {curriculumList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  Array.isArray(editPathData?.curriculum) &&
                                  editPathData?.curriculum.filter((itemm) => {
                                    return itemm?.curriculum === e;
                                  })?.length > 0
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() =>
                                handleMultipleInputArray(
                                  e,
                                  "curriculum",
                                  "curriculum"
                                )
                              }
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Ideal stream
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.stream?.length > 0 &&
                            selectedPath?.stream.map((e, i) => {
                              return selectedPath?.stream?.length - 1 === i
                                ? e?.stream + " "
                                : e?.stream + "," + " ";
                            })}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {streamList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  Array.isArray(editPathData?.stream) &&
                                  editPathData?.stream.filter((itemm) => {
                                    return itemm?.stream === e;
                                  })?.length > 0
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() =>
                                handleMultipleInputArray(e, "stream", "stream")
                              }
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Ideal financial situation
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.financialSituation?.length > 0 &&
                            selectedPath?.financialSituation.map((e, i) => {
                              return selectedPath?.financialSituation?.length -
                                1 ===
                                i
                                ? e?.finance + " "
                                : e?.finance + "," + " ";
                            })}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {financeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  Array.isArray(
                                    editPathData?.financialSituation
                                  ) &&
                                  editPathData?.financialSituation.filter(
                                    (itemm) => {
                                      return itemm?.finance === e;
                                    }
                                  )?.length > 0
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() =>
                                handleMultipleInputArray(
                                  e,
                                  "financialSituation",
                                  "finance"
                                )
                              }
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        What program will they be studying?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedPath?.program}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Program"
                          onChange={(e) => {
                            setEditPathData((prev) => {
                              return {
                                ...prev,
                                program: e.target.value,
                              };
                            });
                          }}
                          value={editPathData?.program}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        University
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.UniversityDetails[0]?.name}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            borderRadius: "15px",
                            padding: "1.5rem",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                          type="text"
                          placeholder="Search University"
                          onChange={(e) => {
                            setUniversitySearch(e.target.value);
                          }}
                          value={universitySearch}
                        />
                      </div>
                      <div
                        className="each-editservice-fied-dd-ss"
                        style={{
                          height: "50vh",
                          overflowY: "scroll",
                        }}
                      >
                        {universityLoading
                          ? Array(10)
                              .fill(" ")
                              .map((item, index) => {
                                return (
                                  <div key={index} className="dd-fields">
                                    <Skeleton width={"100%"} height={30} />
                                  </div>
                                );
                              })
                          : universityList
                              ?.filter((each) =>
                                each?.name
                                  ?.toLowerCase()
                                  .includes(
                                    universitySearch?.toLocaleLowerCase()
                                  )
                              )
                              ?.map((e, i) => {
                                return (
                                  <div
                                    className="dd-fields"
                                    style={{
                                      border:
                                        editPathData?.university === e?._id
                                          ? "2px solid #59A2DD"
                                          : "",
                                    }}
                                    onClick={() => {
                                      setEditPathData((prev) => {
                                        return {
                                          ...prev,
                                          university: e?._id,
                                        };
                                      });
                                    }}
                                  >
                                    {e?.name}
                                  </div>
                                );
                              })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setPathActionStep(4);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setPathActionStep(6);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pathActionStep === 6 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        What personality suits this path?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.personality}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {personalityList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editPathData?.personality === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditPathData((prev) => {
                                  return {
                                    ...prev,
                                    personality: e,
                                  };
                                });
                              }}
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Destination Degree Type
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.destination_degree}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {degreeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editPathData?.destination_degree === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditPathData((prev) => {
                                  return {
                                    ...prev,
                                    destination_degree: e,
                                  };
                                });
                              }}
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Destination Degree Type
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedPath?.status}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {pathStatusList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editPathData?.status === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditPathData((prev) => {
                                  return {
                                    ...prev,
                                    status: e,
                                  };
                                });
                              }}
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setPathActionStep(5);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        editPathFields();
                      }}
                    >
                      Apply Changes
                    </div>
                  </div>
                  {actionLoading && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {pathActionStep === 7 && (
                <div
                  className="successMsg"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  You have successfully updated the changes for{" "}
                  {selectedPath?.nameOfPath}
                </div>
              )}
            </div>
          </div>
        )}

        {stepActionEnabled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.6)",
              zIndex: "25",
              top: "0",
              left: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="acc-popular" style={{ background: "#e5e5e5" }}>
              <div
                className="acc-popular-top"
                style={{
                  display:
                    stepActionStep === 3
                      ? "none"
                      : stepActionStep === 8
                      ? "none"
                      : "",
                }}
              >
                <div className="acc-popular-head">
                  {stepActionStep < 3
                    ? "Step Actions"
                    : stepActionStep === 4
                    ? "Choose Path"
                    : stepActionStep === 5
                    ? "Add Macro Step"
                    : stepActionStep === 6
                    ? "Add Micro Step"
                    : stepActionStep === 7
                    ? "Add Nano Step"
                    : ""}
                </div>
                <div
                  className="acc-popular-img-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    resetStepAction();
                  }}
                >
                  <img className="acc-popular-img" src={closepop} alt="" />
                </div>
              </div>
              {stepActionStep === 1 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setStepActionStep(4);
                      }}
                    >
                      Edit step
                    </div>

                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setStepActionStep(2);
                      }}
                    >
                      Delete step
                    </div>
                  </div>
                </>
              )}

              {stepActionStep === 2 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        deleteStep();
                      }}
                    >
                      Confirm and delete
                    </div>
                    <div
                      className="each-acc-editservice-button"
                      onClick={() => {
                        setStepActionStep(1);
                      }}
                    >
                      Never Mind
                    </div>
                  </div>
                  {actionLoading && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {stepActionStep === 3 && (
                <div
                  className="successMsg"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Step Successfully Deleted
                </div>
              )}

              {stepActionStep === 4 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Step name
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.name}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter Step name"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                name: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.name}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">Map Path</div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            background: "silver",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "100%",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {selectedStep?.pathDetails[0]?.nameOfPath}
                          </div>
                          <div
                            style={{
                              maxWidth: "100%",
                              whiteSpace: "break-spaces",
                              fontSize: "0.8rem",
                              fontWeight: "300",
                              lineHeight: "1.6",
                            }}
                          >
                            {selectedStep?.pathDetails[0]?.description}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            borderRadius: "15px",
                            padding: "1.5rem",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                          type="text"
                          placeholder="Search Path"
                          onChange={(e) => {
                            setPathSearchTerm(e.target.value);
                          }}
                          value={pathSearchTerm}
                        />
                      </div>
                      <div
                        className="each-editservice-fied-dd-ss"
                        style={{
                          // height: "50vh",
                          // overflowY: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {mapPathData
                          ?.filter((entry) =>
                            entry?.nameOfPath
                              ?.toLowerCase()
                              .includes(pathSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                className="dd-fields"
                                style={{
                                  border:
                                    editStepData?.path_id === e?._id
                                      ? "2px solid #59A2DD"
                                      : "",
                                }}
                                onClick={() => {
                                  setEditStepdata((prev) => {
                                    return {
                                      ...prev,
                                      path_id: e?._id,
                                    };
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {e?.nameOfPath}
                                </div>
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">Order</div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.step_order}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new Order"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                step_order: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.step_order}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Step status
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedStep?.status}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {stepStatusList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editStepData?.status === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditStepdata((prev) => {
                                  return {
                                    ...prev,
                                    status: e,
                                  };
                                });
                              }}
                            >
                              <div>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(5);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {stepActionStep === 5 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Step Name
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.macro_name}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Step Name"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                macro_name: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.macro_name}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        How long does this Step take?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.macro_length}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new Macro Step duration"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                macro_length: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.macro_length}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Instructions / Description
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.macro_description}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <textarea
                          type="text"
                          placeholder="Enter new description"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                macro_description: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.macro_description}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Type of step?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedStep?.macro_cost}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {stepTypeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editStepData?.macro_cost === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditStepdata((prev) => {
                                  return {
                                    ...prev,
                                    macro_cost: e,
                                  };
                                });
                              }}
                            >
                              <div>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Map Services
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{
                          pointerEvents: "none",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {selectedStep?.MacroServicesDetails?.map((e, i) => {
                          return (
                            <div
                              key={e?._id}
                              className="existing-values"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                background: "silver",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {e?.nameOfService}
                              </div>
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                  fontSize: "0.8rem",
                                  fontWeight: "300",
                                  lineHeight: "1.6",
                                }}
                              >
                                {e?.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            borderRadius: "15px",
                            padding: "1.5rem",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMacroSearchTerm(e.target.value);
                          }}
                          value={macroSearchTerm}
                        />
                      </div>
                      <div
                        className="each-editservice-fied-dd-ss"
                        style={{
                          // height: "50vh",
                          // overflowY: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {macroServiceData
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              .includes(macroSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                className="dd-fields"
                                style={{
                                  border: macroServicesSameType?.includes(
                                    e?._id
                                  )
                                    ? "2px solid #59A2DD"
                                    : macroServicesDiffType?.includes(e?._id)
                                    ? "2px solid #59A2DD"
                                    : "",
                                }}
                                onClick={() => {
                                  if (
                                    editStepData?.macro_cost &&
                                    selectedStep?.macro_cost ===
                                      editStepData?.macro_cost
                                  ) {
                                    handleMultipleServicesMacroSameType(e);
                                  } else if (
                                    editStepData?.macro_cost &&
                                    selectedStep?.macro_cost !==
                                      editStepData?.macro_cost
                                  ) {
                                    handleMultipleServicesMacroDiffType(e);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        No of chances
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.macro_chances}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new No of chance for Macro Step"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                macro_chances: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.macro_chances}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(4);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(6);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {stepActionStep === 6 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Step Name
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.micro_name}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter new Step Name"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                micro_name: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.micro_name}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        How long does this Step take?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.micro_length}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new Micro Step duration"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                micro_length: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.micro_length}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Instructions / Description
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.micro_description}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <textarea
                          type="text"
                          placeholder="Enter new description"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                micro_description: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.micro_description}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Type of step?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedStep?.micro_cost}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {stepTypeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editStepData?.micro_cost === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditStepdata((prev) => {
                                  return {
                                    ...prev,
                                    micro_cost: e,
                                  };
                                });
                              }}
                            >
                              <div>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Map Services
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{
                          pointerEvents: "none",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {selectedStep?.MicroServicesDetails?.map((e, i) => {
                          return (
                            <div
                              className="existing-values"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                background: "silver",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {e?.nameOfService}
                              </div>
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                  fontSize: "0.8rem",
                                  fontWeight: "300",
                                  lineHeight: "1.6",
                                }}
                              >
                                {e?.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            borderRadius: "15px",
                            padding: "1.5rem",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMicroSearchTerm(e.target.value);
                          }}
                          value={microSearchTerm}
                        />
                      </div>
                      <div
                        className="each-editservice-fied-dd-ss"
                        style={{
                          // height: "50vh",
                          // overflowY: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {microServiceData
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              .includes(microSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                className="dd-fields"
                                style={{
                                  border: microServicesSameType?.includes(
                                    e?._id
                                  )
                                    ? "2px solid #59A2DD"
                                    : microServicesDiffType?.includes(e?._id)
                                    ? "2px solid #59A2DD"
                                    : "",
                                }}
                                onClick={() => {
                                  if (
                                    editStepData?.micro_cost &&
                                    selectedStep?.micro_cost ===
                                      editStepData?.micro_cost
                                  ) {
                                    handleMultipleServicesMicroSameType(e);
                                  } else if (
                                    editStepData?.micro_cost &&
                                    selectedStep?.micro_cost !==
                                      editStepData?.micro_cost
                                  ) {
                                    handleMultipleServicesMicroDiffType(e);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        No of chances
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.micro_chances}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new No of chance for Micro Step"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                micro_chances: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.micro_chances}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(5);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(7);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {stepActionStep === 7 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Step Name
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.nano_name}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="text"
                          placeholder="Enter Step Name"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                nano_name: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.nano_name}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        How long does this Step take?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.nano_length}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new Nano Step duration"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                nano_length: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.nano_length}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Instructions / Description
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.nano_description}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <textarea
                          type="text"
                          placeholder="Enter new description"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                nano_description: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.nano_description}
                        />
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Type of step?
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="existing-values"
                          style={{ background: "silver" }}
                        >
                          {selectedStep?.nano_cost}
                        </div>
                      </div>
                      <div className="each-editservice-fied-dd-ss">
                        {stepTypeList?.map((e, i) => {
                          return (
                            <div
                              className="dd-fields"
                              style={{
                                border:
                                  editStepData?.nano_cost === e
                                    ? "2px solid #59A2DD"
                                    : "",
                              }}
                              onClick={() => {
                                setEditStepdata((prev) => {
                                  return {
                                    ...prev,
                                    nano_cost: e,
                                  };
                                });
                              }}
                            >
                              <div>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        Map Services
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{
                          pointerEvents: "none",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {selectedStep?.nanoServicesDetails?.map((e, i) => {
                          return (
                            <div
                              className="existing-values"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                background: "silver",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {e?.nameOfService}
                              </div>
                              <div
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "break-spaces",
                                  fontSize: "0.8rem",
                                  fontWeight: "300",
                                  lineHeight: "1.6",
                                }}
                              >
                                {e?.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            borderRadius: "15px",
                            padding: "1.5rem",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setNanoSearchTerm(e.target.value);
                          }}
                          value={nanoSearchTerm}
                        />
                      </div>
                      <div
                        className="each-editservice-fied-dd-ss"
                        style={{
                          // height: "50vh",
                          // overflowY: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        {nanoServiceData
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              .includes(nanoSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                className="dd-fields"
                                style={{
                                  border: nanoServicesSameType?.includes(e?._id)
                                    ? "2px solid #59A2DD"
                                    : nanoServicesDiffType?.includes(e?._id)
                                    ? "2px solid #59A2DD"
                                    : "",
                                }}
                                onClick={() => {
                                  if (
                                    editStepData?.nano_cost &&
                                    selectedStep?.nano_cost ===
                                      editStepData?.nano_cost
                                  ) {
                                    handleMultipleServicesNanoSameType(e);
                                  } else if (
                                    editStepData?.nano_cost &&
                                    selectedStep?.nano_cost !==
                                      editStepData?.nano_cost
                                  ) {
                                    handleMultipleServicesNanoDiffType(e);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    whiteSpace: "break-spaces",
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="each-acc-editservice-field">
                      <div className="each-editservice-fieldname">
                        No of chances
                      </div>
                      <div
                        className="each-editservice-fied-input"
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="existing-values">
                          {selectedStep?.nano_chances}
                        </div>
                      </div>
                      <div className="each-editservice-fied-input">
                        <input
                          type="number"
                          placeholder="Enter new No of chance for Nano Step"
                          onChange={(e) => {
                            setEditStepdata((prev) => {
                              return {
                                ...prev,
                                nano_chances: e.target.value,
                              };
                            });
                          }}
                          value={editStepData?.nano_chances}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setStepActionStep(5);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        editStepFields();
                      }}
                    >
                      Apply Changes
                    </div>
                  </div>
                  {actionLoading && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {stepActionStep === 8 && (
                <div
                  className="successMsg"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  You have successfully updated the changes for{" "}
                  {selectedStep?.name}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPaths;
