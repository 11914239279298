import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../accDashbaoard/accDashboard.scss";
import searchic from "../../static/images/dashboard/searchic.svg";
import downarrow from "../../static/images/dashboard/downarrow.svg";
import uploadv from "../../static/images/dashboard/uploadv.svg";
import nvest from "../../static/images/dashboard/nvest.svg";
import profile from "../../static/images/dashboard/profile.svg";
import closepop from "../../static/images/dashboard/closepop.svg";
import accounts from "../../static/images/dashboard/accounts.svg";
import vaults from "../../static/images/dashboard/vaults.svg";
import profilea from "../../static/images/dashboard/profilea.svg";
import support from "../../static/images/dashboard/support.svg";
import settings from "../../static/images/dashboard/settings.svg";
import sidearrow from "../../static/images/dashboard/sidearrow.svg";
import logout from "../../static/images/dashboard/logout.svg";
import upgif from "../../static/images/dashboard/upgif.gif";
import lg1 from "../../static/images/login/lg1.svg";
import threedot from "../../static/images/dashboard/threedot.svg";
import { useStore } from "../../components/store/store.ts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AccDashsidebar from "../../components/accDashsidebar/accDashsidebar";
import {
  GetFollowersPerAccount,
  GetCategoriesAcc,
  GetAllCustomerLicenses,
  GetLogServices,
  GetAllCurrencies,
  CreatePopularService,
  CheckStatusAccountant,
} from "../../services/accountant";
import { formatDate } from "../../utils/time";
import * as jose from "jose";
import { LoadingAnimation1 } from "../../components/LoadingAnimation1";
import { useCoinContextData } from "../../context/CoinContext";
import NewStep1 from "../../globalComponents/GlobalDrawer/NewStep1";

import cover from "../../images/cover.svg";
import uploadGrey from "../../images/uploadGrey.svg";
import close from "../../images/close.svg";
import arrow from "../../images/arrow.svg";
import colorArrow from "../../images/colorArrow.svg";
import edit from "../../images/edit.svg";
import downArrow from "../../images/downArrow.svg";
import upArrow from "../../images/upArrow.svg";
import upload from "../../images/upload.svg";
import {
  InputDivsCheck,
  InputDivsTextArea1,
  InputDivsWithMT,
  InputDivsWithColorCode,
} from "../../components/createAccountant/CreatePlanB";
import { uploadImageFunc } from "../../utils/imageUpload";
import classNames from "../../components/createAccountant/components.module.scss";
import trash from "../accDashbaoard/trash.svg";
import backIcon from "../../static/images/icons/backIcon.svg";
import nextIcon from "../../static/images/icons/nextIcon.svg";
import dropdownIcon from "../../static/images/icons/dropdownIcon.svg";

const AccProfile = () => {
  const { accsideNav, setaccsideNav, ispopular, setispopular } = useStore();
  const [search, setSearch] = useState("");
  const [crmMenu, setcrmMenu] = useState("Followers");
  const [servicesMenu, setservicesMenu] = useState("Services");
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [isCatoading, setIsCatLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [followCount, setfollowCount] = useState(0);
  const [followData, setfollowData] = useState([]);
  const [coverImageS3url, setCoverImageS3url] = useState("");
  const [selectedFollower, setSelectedFollower] = useState({});
  const [pstep, setpstep] = useState(1);
  const [selectNew, setselectNew] = useState("");
  const [billingType, setbillingType] = useState("");
  const [categoriesData, setcategoriesData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [selectCategory, setselectCategory] = useState("");
  const [serviceNameInput, setServiceNameInput] = useState("");
  const [serviceCodeInput, setServiceCodeInput] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [serviceTagline, setServiceTagline] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [showDrop, setShowDrop] = useState(false);
  const [isCurrencies, setIsCurrencies] = useState(false);
  const [allCurrencies, setallCurrencies] = useState([]);
  const [searchCurrency, setSearchCurrency] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [firstMonthPrice, setfirstMonthPrice] = useState("");
  const [monthlyPrice, setmonthlyPrice] = useState("");
  const [gracePeriod, setgracePeriod] = useState("");
  const [secondChargeAttempt, setsecondChargeAttempt] = useState("");
  const [thirdChargeAttempt, setthirdChargeAttempt] = useState("");
  const [image, setImage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isServicesAcc, setIsServicesAcc] = useState(false);
  const [servicesAcc, setservicesAcc] = useState([]);
  const [isProfileData, setIsProfileData] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [profileSpecalities, setprofileSpecalities] = useState([]);
  const [email, setemail] = useState("");
  const [brandtag, setbrandtag] = useState("");
  const [country, setcountry] = useState("");
  const [address, setaddress] = useState("");
  const [displayname, setdisplayname] = useState("");
  const [phno, setphno] = useState("");
  const [description, setdescription] = useState("");
  const [colorcode, setcolorcode] = useState("");
  const [patneringinstitution, setpatneringinstitution] = useState("");
  const [autodeposit, setautodeposit] = useState("");
  const [cashback, setcashback] = useState("");
  const [category, setcategory] = useState("");
  const [subcategory, setsubcategory] = useState("");

  // create brand profile
  const [createBrandProfile, setCreateBrandProfile] = useState(false);
  const [createBrandProfileStep, setCreateBrandProfileStep] = useState(1);
  const [profilePicture, setProfilePicture] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userName, setUserName] = useState("");
  const [coverPhoto1, setCoverPhoto1] = useState();
  const [brandDisplayName, setBrandDisplayName] = useState();
  const [brandUserName, setBrandUserName] = useState("");
  const [brandDescription, setBrandDescription] = useState();
  const [brandColorCode, setBrandColorCode] = useState();
  const [headquarter, setHeadquarter] = useState();
  const [brandAddress, setBrandAddress] = useState();
  const [whiteProPic, setWhiteProPic] = useState();
  const [isloading, setIsloading] = useState(false);
  const [accStatus, setAccStatus] = useState("");
  const [hidden, setHidden] = useState(false);
  const [userNameAvailable, setUserNameAvailable] = useState(false);
  const [userNameAvailable1, setUserNameAvailable1] = useState(false);
  const [changing, setChanging] = useState(false);
  const [loading, setLoading] = useState(false);

  // add path new form
  const [grade, setGrade] = useState([]);
  const [gradeAvg, setGradeAvg] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [stream, setStream] = useState([]);
  const [finance, setFinance] = useState([]);
  const [personality, setPersonality] = useState("");
  const streamList = ["MPC", "BIPC", "CEC", "HEC", "MEC"];
  const curriculumList = ["IB", "CBSE", "IG", "CSE", "ICSE", "Nordic"];
  const gradeList = ["9", "10", "11", "12"];
  const gradePointAvg = ["0-35", "36-60", "61-75", "76-85", "86-95", "96-100"];
  const financeList = ["0-25L", "25L-75L", "75L-3CR", "3CR+"];
  const personalityList = [
    "realistic",
    "social",
    "enterprising",
    "artistic",
    "conventional",
  ];
  const [pathTypeList, setPathTypeList] = useState([
    "education",
    "career",
    "immigration",
  ]);
  const [degreeList, setDegreeList] = useState([
    "Bachelor",
    "Master",
    "Diploma",
  ]);
  const [universityList, setUniversityList] = useState([]);
  const [pathStatusList, setPathStatusList] = useState(["active", "inactive"]);
  // addpath dropdown
  const [pathTypeDropdown, setPathTypeDropdown] = useState(false);
  const [pathGradeDropdown, setPathGradeDropdown] = useState(false);
  const [pathGradePointDropdown, setPathGradePointDropdown] = useState(false);
  const [pathCurriculumDropdown, setPathCurriculumDropdown] = useState(false);
  const [pathStreamDropdown, setPathStreamDropdown] = useState(false);
  const [pathFinancialDropdown, setPathFinancialDropdown] = useState(false);
  const [pathUniversityDropdown, setPathUniversityDropdown] = useState(false);
  const [pathPersonalityDropdown, setPathPersonalityDropdown] = useState(false);
  const [pathDestinationDropdown, setPathDestinationDropdown] = useState(false);
  const [pathStatusDropdown, setPathStatusDropdown] = useState(false);
  // addpath selected
  const [pathTypeSelected, setPathTypeSelected] = useState(false);
  const [pathGradeSelected, setPathGradeSelected] = useState(false);
  const [pathGradePointSelected, setPathGradePointSelected] = useState(false);
  const [pathCurriculumSelected, setPathCurriculumSelected] = useState(false);
  const [pathStreamSelected, setPathStreamSelected] = useState(false);
  const [pathFinancialSelected, setPathFinancialSelected] = useState(false);
  const [pathUniversitySelected, setPathUniversitySelected] = useState(false);
  const [pathPersonalitySelected, setPathPersonalitySelected] = useState(false);
  const [pathDestinationSelected, setPathDestinationSelected] = useState(false);
  const [pathStatusSelected, setPathStatusSelected] = useState(false);
  // addpath values
  const [pathTypeVal, setpathTypeVal] = useState();
  const [pathNameVal, setPathNameVal] = useState();
  const [pathDescriptionVal, setPathDescriptionVal] = useState();
  const [pathCurrentCoordinatesVal, setPathCurrentCoordinatesVal] = useState();
  const [pathFutureCoordinatesVal, setPathFutureCoordinatesVal] = useState();
  const [pathProgramVal, setPathProgramVal] = useState();
  const [pathUniversityVal, setPathUniversityVal] = useState(null);
  const [pathDestinationVal, setPathDestinationVal] = useState();
  const [universityLoading, setUniversityLoading] = useState(true);
  const [pathStatusVal, setPathStatusVal] = useState();
  const [universitySearch, setUniversitySearch] = useState("");

  const {
    allSteps,
    setAllSteps,
    stepsToggle,
    setStepsToggle,
    pathSteps,
    setPathSteps,
    creatingPath,
    setCreatingPath,
    mypathsMenu,
    setMypathsMenu,
    selectedSteps,
    setSelectedSteps,
  } = useCoinContextData();

  let navigate = useNavigate();

  // edit accountant data
  const [editProfilePic, setEditProfilePic] = useState(false);
  const [editCountry, setEditCountry] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editDisplayName, setEditDisplayName] = useState(false);
  const [editPhoneNo, setEditPhoneNo] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editCoverPic, setEditCoverPic] = useState(false);
  const [editColorCode, setEditColorCode] = useState(false);
  const [editPartneringInstitutions, setEditPartneringInstitutions] =
    useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editSubCategory, setEditSubCategory] = useState(false);
  const [editSpecialities, setEditSpecialities] = useState(false);

  //accountant profile new values
  const [newAddress, setNewAddress] = useState();
  const [newDisplayName, setNewDisplayName] = useState();
  const [newPhoneNo, setNewPhoneNo] = useState();
  const [newDescription, setNewDescription] = useState();
  const [newColorCode, setNewColorCode] = useState();
  const [newCountry, setNewCountry] = useState();
  const [newPartneringInstitutions, setNewPartneringInstitutions] = useState();
  const [newAutoDeposit, setNewAutoDeposit] = useState();
  const [newCashBack, setNewCashBack] = useState();
  const [newCategory, setNewCategory] = useState(false);
  const [newSubCategory, setNewSubCategory] = useState();
  const [newSpecialities, setNewSpecialities] = useState(false);
  const [newCoverPic, setNewCoverPic] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState();

  const [backupPathList, setBackupPathList] = useState([]);
  const [showBackupPathList, setShowBackupPathList] = useState(false);

  // add service new format
  const [serviceTypeList, setServiceTypeList] = useState([
    "distributor",
    "vendor",
    "mentor",
  ]);
  const [serviceAccessList, setServiceAccessList] = useState([
    "Free",
    "Covered under Subscription",
  ]);
  const [serviceCostList, setServiceCostList] = useState(["free", "paid"]);
  const [serviceDiscountList, setServiceDiscountList] = useState([
    "percentage",
    "flat",
    "discounted price",
  ]);
  const [serviceStatusList, setServiceStatusList] = useState([
    "active",
    "inactive",
  ]);
  const [serviceGradeList, setServiceGradeList] = useState([
    "9",
    "10",
    "11",
    "12",
  ]);
  const [serviceFinancialList, setServiceFinancialList] = useState([
    "0-25L",
    "25-75L",
    "75L-3CR",
    "3CR+",
  ]);
  const [serviceStreamList, setServiceStreamList] = useState([
    "MPC",
    "BIPC",
    "CEC",
    "HEC",
    "MEC",
  ]);
  const [serviceStepList, setServiceStepList] = useState([
    "Micro",
    "Macro",
    "Nano",
  ]);
  const [serviceIterationList, setServiceIterationList] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "Unlimited",
  ]);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(false);
  const [serviceAccessSelected, setServiceAccessSelected] = useState(false);
  const [serviceCostSelected, setServiceCostSelected] = useState(false);
  const [serviceDiscountSelected, setServiceDiscountSelected] = useState(false);
  const [serviceStatusSelected, setServiceStatusSelected] = useState(false);
  const [serviceGradeSelected, setServiceGradeSelected] = useState(false);
  const [serviceFinancialSelected, setServiceFinancialSelected] =
    useState(false);
  const [serviceStreamSelected, setServiceStreamSelected] = useState(false);
  const [serviceStepSelected, setServiceStepSelected] = useState(false);
  const [serviceIterationSelected, setServiceIterationSelected] =
    useState(false);
  // addservice dropdown
  const [serviceTypeDropdown, setServiceTypeDropdown] = useState(false);
  const [serviceAccessDropdown, setServiceAccessDropdown] = useState(false);
  const [serviceCostDropdown, setServiceCostDropdown] = useState(false);
  const [serviceDiscountDropdown, setServiceDiscountDropdown] = useState(false);
  const [serviceStatusDropdown, setServiceStatusDropdown] = useState(false);
  const [serviceGradeDropdown, setServiceGradeDropdown] = useState(false);
  const [serviceFinancialDropdown, setServiceFinancialDropdown] =
    useState(false);
  const [serviceStreamDropdown, setServiceStreamDropdown] = useState(false);
  const [serviceStepDropdown, setServiceStepDropdown] = useState(false);
  const [serviceIterationDropdown, setServiceIterationDropdown] =
    useState(false);
  // addservice value
  const [serviceNameVal, setServiceNameVal] = useState();
  const [serviceDescriptionVal, setServiceDescriptionVal] = useState();
  const [typeVal, setTypeVal] = useState();
  const [accessVal, setAccessVal] = useState();
  const [goalVal, setGoalVal] = useState();
  const [costVal, setCostVal] = useState();
  const [priceVal, setPriceVal] = useState();
  const [discountVal, setDiscountVal] = useState();
  const [discountInputVal, setDiscountInputVal] = useState();
  const [durationVal, setDurationVal] = useState();
  const [featureVal, setFeatureVal] = useState();
  const [statusVal, setStatusVal] = useState();
  const [gradeVal, setGradeVal] = useState([]);
  const [finVal, setFinVal] = useState([]);
  const [streamVal, setStreamVal] = useState([]);
  const [outcomeVal, setOutcomeVal] = useState();
  const [stepVal, setStepVal] = useState();
  const [iterationVal, setIterationVal] = useState();

  // add step new format
  const [macroStepTypeList, setMacroStepTypeList] = useState(["free", "paid"]);
  const [microStepTypeList, setMicroStepTypeList] = useState(["free", "paid"]);
  const [nanoStepTypeList, setNanoStepTypeList] = useState(["free", "paid"]);
  const stepStatusList = ["active", "inactive"];
  const [pathList, setPathList] = useState([]);
  const [macroServiceList, setMacroServiceList] = useState([]);
  const [microServiceList, setMicroServiceList] = useState([]);
  const [nanoServiceList, setNanoServiceList] = useState([]);
  // addstep selected
  const [generalMapPathSelected, setGeneralMapPathSelected] = useState(false);
  const [macroServiceSelected, setMacroServiceSelected] = useState(false);
  const [microServiceSelected, setMicroServiceSelected] = useState(false);
  const [nanoServiceSelected, setNanoServiceSelected] = useState(false);
  // addstep dropdown
  const [generalMapPathDropDown, setGeneralMapPathDropdown] = useState(false);
  const [macroStepTypeDropdown, setMacroStepTypeDropdown] = useState(false);
  const [macroMapServiceDropDown, setMacroMapServiceDropDown] = useState(false);
  const [microStepTypeDropdown, setMicroStepTypeDropdown] = useState(false);
  const [microMapServiceDropDown, setMicroMapServiceDropDown] = useState(false);
  const [nanoStepTypeDropdown, setNanoStepTypeDropdown] = useState(false);
  const [nanoMapServiceDropDown, setNanoMapServiceDropDown] = useState(false);
  const [stepStatusDropdown, setStepStatusDropdown] = useState(false);
  // addstep values
  const [stepNameVal, setStepNameVal] = useState();
  const [stepOrderVal, setStepOrderVal] = useState();
  const [macroStepNameVal, setMacroStepNameVal] = useState();
  const [microStepNameVal, setMicroStepNameVal] = useState();
  const [nanoStepNameVal, setNanoStepNameVal] = useState();
  const [macroStepDescriptionVal, setMacroStepDescriptionVal] = useState();
  const [microStepDescriptionVal, setMicroStepDescriptionVal] = useState();
  const [nanoStepDescriptionVal, setNanoStepDescriptionVal] = useState();
  const [macroStepLengthVal, setMacroStepLengthVal] = useState();
  const [microStepLengthVal, setMicroStepLengthVal] = useState();
  const [nanoStepLengthVal, setNanoStepLengthVal] = useState();
  const [macroStepChanceVal, setMacroStepChanceVal] = useState();
  const [microStepChanceVal, setMicroStepChanceVal] = useState();
  const [nanoStepChanceVal, setNanoStepChanceVal] = useState();
  const [stepStatusVal, setStepStatusVal] = useState();
  const [macroStepTypeVal, setMacroStepTypeVal] = useState();
  const [microStepTypeVal, setMicroStepTypeVal] = useState();
  const [nanoStepTypeVal, setNanoStepTypeVal] = useState();
  const [selectedPathVal, setSelectedPathVal] = useState();
  const [macroServiceVal, setMacroServiceVal] = useState([]);
  const [microServiceVal, setMicroServiceVal] = useState([]);
  const [nanoServiceVal, setNanoServiceVal] = useState([]);
  const [mapPathSearchTerm, setMapPathSearchTerm] = useState("");
  const [macroServiceSearchTerm, setMacroServiceSearchTerm] = useState("");
  const [microServiceSearchTerm, setMicroServiceSearchTerm] = useState("");
  const [nanoServiceSearchTerm, setNanoServiceSearchTerm] = useState("");

  // //upload part starts here

  const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
  const emailDev = "rahulrajsb@outlook.com"; // email of the developer.
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const uploadCoverImage = async (file) => {
    setIsUploadLoading(true);

    const fileName = `${new Date().getTime()}${file.name.substr(
      file.name.lastIndexOf(".")
    )}`;

    const formData = new FormData();
    const newfile = renameFile(file, fileName);
    formData.append("files", newfile);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    // console.log(jwts, "lkjkswedcf");
    let { data } = await axios.post(
      `https://insurance.apimachine.com/insurance/general/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (data?.length > 0) {
      console.log(data[0], "dfile name upload");
      setCoverImageS3url(data[0]?.urlName);
      setIsUploadLoading(false);
      return data[0]?.urlName;
    } else {
      // setIsUploadLoading(false);
      console.log("error in uploading image");
    }
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  useEffect(() => {
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        // console.log(result, "all paths fetched");
        setBackupPathList(result);
      });
  }, []);

  const addBackupPath = (backupPathId, selectedStepId) => {
    // console.log(pathSteps, "kjedkjweld");

    pathSteps?.the_ids.map((item) => {
      if (item.step_id === selectedStepId) {
        item.backup_pathId = backupPathId;
      }
    });
    setShowBackupPathList(false);
    // console.log(selectedSteps, "lkashclkweoiuk");
    // const found = pathSteps.find((element) => element._id === backupPathId);
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  //upload end here

  const handleGrade = (item) => {
    if (grade.some((o) => o.grade === item)) {
      // If the grade is already selected, remove it
      setGrade(grade.filter((o) => o.grade !== item));
    } else {
      // If the grade is not selected, add it
      setGrade([...grade, { grade: item }]);
    }
  };

  const handleGradeAvg = (item) => {
    if (gradeAvg.some((o) => o.grade_avg === item)) {
      // If the gradeAvg is already selected, remove it
      setGradeAvg(gradeAvg.filter((o) => o.grade_avg !== item));
    } else {
      // If the gradeAvg is not selected, add it
      setGradeAvg([...gradeAvg, { grade_avg: item }]);
    }
  };

  const handleCurriculum = (item) => {
    if (curriculum.some((o) => o.curriculum === item)) {
      // If the curriculum is already selected, remove it
      setCurriculum(curriculum.filter((o) => o.curriculum !== item));
    } else {
      // If the curriculum is not selected, add it
      setCurriculum([...curriculum, { curriculum: item }]);
    }
  };

  const handleStream = (item) => {
    if (stream.some((o) => o.stream === item)) {
      // If the stream is already selected, remove it
      setStream(stream.filter((o) => o.stream !== item));
    } else {
      // If the stream is not selected, add it
      setStream([...stream, { stream: item }]);
    }
  };

  const handleFinance = (item) => {
    if (finance.some((o) => o.finance === item)) {
      // If the finance is already selected, remove it
      setFinance(finance.filter((o) => o.finance !== item));
    } else {
      // If the finance is not selected, add it
      setFinance([...finance, { finance: item }]);
    }
  };

  const handlePersonality = (item) => {
    setPersonality(item);
  };

  const handleFollowerPerAccountants = () => {
    setIsLoading(true);
    GetFollowersPerAccount()
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setfollowCount(result.data.count);
          setfollowData(result.data.followers);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "jkjkk");
        setIsLoading(false);
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleAllCustomerLicenses = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    setIsPurchaseLoading(true);
    GetAllCustomerLicenses(userDetails.user.email)
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setPurchaseData(result.licenses);
          setIsPurchaseLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setIsPurchaseLoading(false);
      });
  };

  const handleCategories = () => {
    setIsCatLoading(true);
    GetCategoriesAcc()
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setcategoriesData(result.categories);
          setIsCatLoading(false);
        }
      })
      .catch((err) => {
        setIsCatLoading(false);
        console.log(err, "jkjkk");
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleGetCurrencies = () => {
    setIsCurrencies(true);
    GetAllCurrencies()
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setallCurrencies(result.coins);
          setIsCurrencies(false);
        }
      })
      .catch((err) => {
        console.log(err, "jkjkk");
        setIsCurrencies(false);
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const resetpop = () => {
    setispopular(false);
    setpstep(1);
    setbillingType("");
    setselectNew("");
    setselectCategory("");
    setcategoriesData([]);
    setSearch("");
    setSelectedCurrency({});
    setServiceNameInput("");
    setServiceCodeInput("");
    setProductLabel("");
    setServiceTagline("");
    setServiceDescription("");
    setfirstMonthPrice("");
    setmonthlyPrice("");
    setgracePeriod("");
    setsecondChargeAttempt("");
    setthirdChargeAttempt("");
    setfirstMonthPrice("");
    setmonthlyPrice("");
    setgracePeriod("");
    setsecondChargeAttempt("");
    setthirdChargeAttempt("");
    setCoverImageS3url("");
    setImage(null);
    setPathSteps({
      email: userDetails?.user?.email,
      nameOfPath: "",
      description: "",
      length: "",
      path_type: "",
      the_ids: [],
      destination_institution: "",
    });
    setSelectedSteps([]);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setSearchCurrency("");
    setIsSubmit(false);

    // addservice new form fields
    setServiceTypeSelected(false);
    setServiceAccessSelected(false);
    setServiceCostSelected(false);
    setServiceDiscountSelected(false);
    setServiceStatusSelected(false);
    setServiceGradeSelected(false);
    setServiceFinancialSelected(false);
    setServiceStreamDropdown(false);
    setServiceStepSelected(false);
    setServiceIterationSelected(false);

    setServiceTypeDropdown(false);
    setServiceAccessDropdown(false);
    setServiceCostDropdown(false);
    setServiceDiscountDropdown(false);
    setServiceStatusDropdown(false);
    setServiceGradeDropdown(false);
    setServiceFinancialDropdown(false);
    setServiceStreamDropdown(false);
    setServiceStepDropdown(false);
    setServiceIterationDropdown(false);

    setServiceNameVal("");
    setServiceDescriptionVal("");
    setTypeVal("");
    setAccessVal("");
    setGoalVal("");
    setCostVal("");
    setPriceVal("");
    setDiscountVal("");
    setDiscountInputVal("");
    setDurationVal("");
    setFeatureVal("");
    setStatusVal("");
    setGradeVal([]);
    setFinVal([]);
    setStreamVal([]);
    setOutcomeVal("");
    setStepVal("");
    setIterationVal("");

    // addpath new form fields
    setPathTypeDropdown(false);
    setPathGradeDropdown(false);
    setPathGradePointDropdown(false);
    setPathCurriculumDropdown(false);
    setPathStreamDropdown(false);
    setPathFinancialDropdown(false);
    setPathUniversityDropdown(false);
    setPathPersonalityDropdown(false);
    setPathDestinationDropdown(false);
    setPathStatusDropdown(false);

    setPathTypeSelected(false);
    setPathGradeSelected(false);
    setPathGradePointSelected(false);
    setPathCurriculumSelected(false);
    setPathStreamSelected(false);
    setPathFinancialSelected(false);
    setPathUniversitySelected(false);
    setPathPersonalitySelected(false);
    setPathDestinationSelected(false);
    setPathStatusSelected(false);

    setpathTypeVal("");
    setPathNameVal("");
    setPathDescriptionVal("");
    setPathCurrentCoordinatesVal("");
    setPathFutureCoordinatesVal("");
    setPathProgramVal("");
    setPathUniversityVal(null);
    setPathDestinationVal("");
    setUniversityLoading(false);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setPathStatusVal("");
    setUniversityList([]);
    setUniversitySearch("");

    // addstep new form fields
    setGeneralMapPathDropdown(false);
    setMacroStepTypeDropdown(false);
    setMacroMapServiceDropDown(false);
    setMicroStepTypeDropdown(false);
    setMicroMapServiceDropDown(false);
    setNanoStepTypeDropdown(false);
    setNanoMapServiceDropDown(false);
    setStepStatusDropdown(false);

    setGeneralMapPathSelected(false);
    setMacroServiceSelected(false);
    setMicroServiceSelected(false);
    setNanoServiceSelected(false);

    setStepNameVal('');
    setStepOrderVal('');
    setMacroStepNameVal('');
    setMicroStepNameVal('');
    setNanoStepNameVal('');
    setMacroStepDescriptionVal('');
    setMicroStepDescriptionVal('');
    setNanoStepDescriptionVal('');
    setMacroStepLengthVal('');
    setMicroStepLengthVal('');
    setNanoStepLengthVal('');
    setMacroStepChanceVal('');
    setMicroStepChanceVal('');
    setNanoStepChanceVal('');
    setMacroStepTypeVal("");
    setMicroStepTypeVal("");
    setNanoStepTypeVal("");
    setSelectedPathVal("");
    setMacroServiceVal([]);
    setMicroServiceVal([]);
    setNanoServiceVal([]);
    setMapPathSearchTerm("");
    setMacroServiceSearchTerm("");
    setMicroServiceSearchTerm("");
    setNanoServiceSearchTerm("");
    setStepStatusVal('');
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleServicesForLogged = () => {
    setIsServicesAcc(true);
    GetLogServices()
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setservicesAcc(result.products);
          setIsServicesAcc(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsServicesAcc(false);
      });
  };

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    setImage(e.target.files[0]);
    uploadCoverImage(e.target.files[0]);
  };

  const myTimeoutService = () => {
    setTimeout(reloadService, 3000);
  };

  function reloadService() {
    setispopular(false);
    setpstep(1);
    navigate("/dashboard/accountants");
    setaccsideNav("My Services");
    setservicesMenu("Services");
    handleServicesForLogged(userDetails.user.email);

    // addservice new form fields
    setServiceTypeSelected(false);
    setServiceAccessSelected(false);
    setServiceCostSelected(false);
    setServiceDiscountSelected(false);
    setServiceStatusSelected(false);
    setServiceGradeSelected(false);
    setServiceFinancialSelected(false);
    setServiceStreamDropdown(false);
    setServiceStepSelected(false);
    setServiceIterationSelected(false);

    setServiceTypeDropdown(false);
    setServiceAccessDropdown(false);
    setServiceCostDropdown(false);
    setServiceDiscountDropdown(false);
    setServiceStatusDropdown(false);
    setServiceGradeDropdown(false);
    setServiceFinancialDropdown(false);
    setServiceStreamDropdown(false);
    setServiceStepDropdown(false);
    setServiceIterationDropdown(false);

    setServiceNameVal("");
    setServiceDescriptionVal("");
    setTypeVal("");
    setAccessVal("");
    setGoalVal("");
    setCostVal("");
    setPriceVal("");
    setDiscountVal("");
    setDiscountInputVal("");
    setDurationVal("");
    setFeatureVal("");
    setStatusVal("");
    setGradeVal([]);
    setFinVal([]);
    setStreamVal([]);
    setOutcomeVal("");
    setStepVal("");
    setIterationVal("");
  }

  const handleFinalSubmit = () => {
    setIsSubmit(true);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let objmonthly = {
      email: userDetails.email,
      token: userDetails.token,
      product_code: serviceCodeInput,
      product_name: serviceNameInput,
      product_icon: coverImageS3url,
      revenue_account: userDetails.email,
      client_app: "naavi",
      product_category_code: "CoE",
      sub_category_code: "",
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: serviceTagline,
      full_description: serviceDescription,
      first_purchase: {
        price: firstMonthPrice !== "" ? parseFloat(firstMonthPrice) : 0,
        coin: selectedCurrency.coinSymbol,
      },
      billing_cycle: {
        monthly: {
          price:
            billingType === "One Time"
              ? firstMonthPrice !== ""
                ? parseFloat(firstMonthPrice)
                : 0
              : monthlyPrice !== ""
              ? parseFloat(monthlyPrice)
              : 0,
          coin: selectedCurrency.coinSymbol,
        },
      },
      grace_period:
        billingType === "One Time"
          ? 0
          : gracePeriod !== ""
          ? parseFloat(gracePeriod)
          : 0,
      first_retry:
        billingType === "One Time"
          ? 0
          : secondChargeAttempt !== ""
          ? parseFloat(secondChargeAttempt)
          : 0,
      second_retry:
        billingType === "One Time"
          ? 0
          : thirdChargeAttempt !== ""
          ? parseFloat(thirdChargeAttempt)
          : 0,
      staking_allowed: false,
      staking_details: {},
    };

    let objone = {
      email: userDetails.user.email,
      token: userDetails.token,
      product_code: serviceCodeInput,
      product_name: serviceNameInput,
      product_icon: coverImageS3url,
      revenue_account: userDetails.user.email,
      client_app: "naavi",
      product_category_code: "CoE",
      sub_category_code: "",
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: serviceTagline,
      full_description: serviceDescription,
      first_purchase: {
        price: firstMonthPrice !== "" ? parseFloat(firstMonthPrice) : 0,
        coin: selectedCurrency.coinSymbol,
      },
      billing_cycle: {
        lifetime: {
          price:
            billingType === "One Time"
              ? firstMonthPrice !== ""
                ? parseFloat(firstMonthPrice)
                : 0
              : monthlyPrice !== ""
              ? parseFloat(monthlyPrice)
              : 0,
          coin: selectedCurrency.coinSymbol,
        },
      },
      grace_period:
        billingType === "One Time"
          ? 0
          : gracePeriod !== ""
          ? parseFloat(gracePeriod)
          : 0,
      first_retry:
        billingType === "One Time"
          ? 0
          : secondChargeAttempt !== ""
          ? parseFloat(secondChargeAttempt)
          : 0,
      second_retry:
        billingType === "One Time"
          ? 0
          : thirdChargeAttempt !== ""
          ? parseFloat(thirdChargeAttempt)
          : 0,
      staking_allowed: false,
      staking_details: {},
    };

    let obj = billingType === "One Time" ? objone : objmonthly;
    CreatePopularService(obj)
      .then((res) => {
        let result = res.data;
        if (result.status) {
          myTimeoutService();
          setpstep(7);
          setbillingType("");
          setselectNew("");
          setselectCategory("");
          setcategoriesData([]);
          setSearch("");
          setSelectedCurrency({});
          setServiceNameInput("");
          setServiceCodeInput("");
          setProductLabel("");
          setServiceTagline("");
          setServiceDescription("");
          setfirstMonthPrice("");
          setmonthlyPrice("");
          setgracePeriod("");
          setsecondChargeAttempt("");
          setthirdChargeAttempt("");
          setfirstMonthPrice("");
          setmonthlyPrice("");
          setgracePeriod("");
          setsecondChargeAttempt("");
          setthirdChargeAttempt("");
          setIsSubmit(false);
          setCoverImageS3url("");
          setImage(null);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  useEffect(() => {
    setaccsideNav("");
    resetpop();
    handleAccountantData();
    // const userDetails = JSON.parse(localStorage.getItem("user"));
    // if (userDetails === null || userDetails === undefined) {
    //   navigate("/login");
    // }
  }, []);

  const myTimeout1 = () => {
    setTimeout(reload1, 3000);
  };

  function reload1() {
    setCreateBrandProfile(false);
    setCreateBrandProfileStep(1);
    setProfilePicture("");
    setFirstName("");
    setLastName("");
    // setUserName("");
    setCoverPhoto1("");
    setBrandDisplayName("");
    setBrandUserName("");
    setBrandDescription("");
    setBrandColorCode("");
    setHeadquarter("");
    setBrandAddress("");
    setWhiteProPic("");
    handleAccountantData();
  }

  const handleAccountantData = () => {
    let mailId = userDetails?.user?.email;
    CheckStatusAccountant(mailId)
      .then((res) => {
        let result = res?.data;
        // console.log(result, 'resultttt')
        if (result.message === "") {
          // console.log(result?.data)
          setIsProfileData(true);
          setProfileData(result?.data[0]);
          setprofileSpecalities(result?.data?.specialities);
        } else {
          setIsProfileData(false);
          setProfileData({});
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const createLXProfile = () => {
    let email = userDetails?.user?.email;
    let token = userDetails?.token;
    axios
      .post(
        "https://teller2.apimachine.com/lxUser/register",
        {
          profilePicURL: profilePicture,
          firstName: firstName,
          lastName: lastName,
          lxTag: userDetails?.user?.username,
        },
        {
          headers: {
            email,
            token,
            // Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'createLXProfile result');
        if (result?.message === "Email is Already Registered as LX User") {
          setCreateBrandProfileStep(2);
        }
      })
      .catch((error) => {
        console.log(error, "error in createLXProfile");
      });
  };

  const createBankerProfile = () => {
    setIsloading(true);
    let email = userDetails?.user?.email;
    let token = userDetails?.token;
    axios
      .post(
        "https://teller2.apimachine.com/lxUser/register/banker",
        {
          bankerTag: brandUserName,
          colorCode: brandColorCode,
          address: brandAddress,
          coverPicURL: coverPhoto1,
          displayName: brandDisplayName,
          description: brandDescription,
          partneringInstitutions: [],
          country: headquarter,
          profilePicURL: profilePicture,
          profilePicPNG: profilePicture,
          profilePicWhite: whiteProPic,
          profilePicWhitePNG: whiteProPic,
          autoDeposit: false,
          sefcoin_cashback: false,
          other_data: {},
        },
        {
          headers: {
            email,
            token,
            // Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'createBankerProfile result');
        setIsloading(false);
        setCreateBrandProfileStep(3);
        myTimeout1();
      })
      .catch((error) => {
        console.log(error, "error in createBankerProfile");
      });
  };

  useEffect(() => {
    accountantStatus();
  }, []);

  const accountantStatus = () => {
    let userEmail = userDetails?.user?.email;
    axios
      .get(
        `https://teller2.apimachine.com/admin/allBankers?email=${userEmail}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data?.[0]?.category;
        // console.log(result, "accountantStatus result");
        if (result === "marketmakers") {
          setAccStatus("Private");
        } else if (result === "naavi partners") {
          setAccStatus("Public");
        }
      });
  };

  const changeStatus = (value) => {
    setChanging(true);
    let email = userDetails?.user?.email;
    let token = userDetails?.token;

    if (email) {
      axios
        .post(
          "https://teller2.apimachine.com/banker/assignCategory",
          {
            categoryName: value,
            email,
          },
          {
            headers: {
              email,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          let result = response?.data;
          // console.log(result, "changeStatus result");
          if (result?.status) {
            accountantStatus();
            setChanging(false);
          } else {
            setChanging(false);
          }
        })
        .catch((error) => {
          console.log(error, "error in changeStatus");
        });
    }
  };

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  // const fetchData = debounce(async () => {
  //   const response = await fetch(
  //     `https://teller2.apimachine.com/lxUser/checkLXTag?lxTag=${userName}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   );
  //   const data = await response.json();
  //   // console.log(data, "username data");
  //   if (data?.data && data?.status && userName.length < 1) {
  //     setUserNameAvailable(false);
  //   } else if (!data?.data && data?.status && userName.length > 0) {
  //     setUserNameAvailable(true);
  //   } else {
  //     setUserNameAvailable(false);
  //   }
  // }, 200);

  // useEffect(() => {
  //   fetchData();
  // }, [userName]);

  const fetchData1 = debounce(async () => {
    const response = await fetch(
      `https://teller2.apimachine.com/lxUser/checkBankerTag?bankerTag=${brandUserName}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await response.json();
    // console.log(data, "username data");
    if (data?.data && data?.status && brandUserName.length < 1) {
      setUserNameAvailable1(false);
    } else if (!data?.data && data?.status && brandUserName.length > 0) {
      setUserNameAvailable1(true);
    } else {
      setUserNameAvailable1(false);
    }
  }, 200);

  useEffect(() => {
    fetchData1();
  }, [brandUserName]);

  const myTimeout = () => {
    setTimeout(reload, 2000);
  };

  function reload() {
    if (editAddress) {
      setEditAddress(false);
      setNewAddress("");
    } else if (editDisplayName) {
      setEditDisplayName(false);
      setNewDisplayName("");
    } else if (editDescription) {
      setEditDescription(false);
      setNewDescription("");
    } else if (editPhoneNo) {
      setEditPhoneNo(false);
      setNewPhoneNo("");
    } else if (editColorCode) {
      setEditColorCode(false);
      setNewColorCode("");
    } else if (editCountry) {
      setEditCountry(false);
      setNewCountry("");
    }
    // else if (editPartneringInstitutions) {
    //   setEditPartneringInstitutions(false);
    //   setNewPartneringInstitutions("");
    // } else if (editCategory) {
    //   setEditCategory(false);
    //   setNewCategory("");
    // } else if (editSubCategory) {
    //   setEditSubCategory(false);
    //   setNewSubCategory("");
    // } else if (editSpecialities) {
    //   setEditSpecialities(false);
    //   setNewSpecialities("");
    // }
    else if (editCoverPic) {
      setEditCoverPic(false);
      setNewCoverPic("");
    } else if (editProfilePic) {
      setEditProfilePic(false);
      setNewProfilePic("");
    }
    handleAccountantData();
  }

  const editData = async (field, value) => {
    setLoading(true);

    let body = {
      [field]: value,
    };

    let email = userDetails?.user?.email;
    let token = userDetails?.token;

    try {
      let result = await axios.put(
        "https://teller2.apimachine.com/lxUser/update/banker",
        body,
        {
          headers: {
            email,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(result, 'editData result');
      if (result?.data?.status) {
        myTimeout();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "error in editData");
    }
  };

  const handleChangeAccDashsidebar = () => {
    if (showDrop) {
      setShowDrop(false);
    }
    navigate("/dashboard/accountants");
  };

  useEffect(() => {
    let email = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/steps/get?email=${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "all steps fetched accprofile");
        setAllSteps(result);
      })
      .catch((error) => {
        console.log(error, "error in fetching all steps accprofile");
      });
  }, []);

  // const removeStep = (stepId) => {
  //   const updatedSelectedSteps = selectedSteps.filter(
  //     (step) => step._id !== stepId
  //   );
  //   setSelectedSteps(updatedSelectedSteps);

  //   const updatedStepIds = pathSteps?.step_ids?.filter((id) => id !== stepId);
  //   setPathSteps({
  //     ...pathSteps,
  //     step_ids: updatedStepIds,
  //   });
  // };

  const removeStep = (stepId) => {
    // Remove the step from selectedSteps
    const updatedSelectedSteps = selectedSteps.filter(
      (step) => step._id !== stepId
    );
    setSelectedSteps(updatedSelectedSteps);

    // Remove the step_id from pathSteps
    const updatedTheIds = pathSteps?.the_ids?.filter(
      (obj) => obj.step_id !== stepId
    );
    setPathSteps({
      ...pathSteps,
      the_ids: updatedTheIds,
    });
  };

  // addservice new
  const handleGradeForAddService = (item) => {
    if (gradeVal.some((o) => o.grade === item)) {
      // If the grade is already selected, remove it
      setGradeVal(gradeVal.filter((o) => o.grade !== item));
    } else {
      // If the grade is not selected, add it
      setGradeVal([...gradeVal, { grade: item }]);
    }
  };

  const handleFinancialForAddService = (item) => {
    if (finVal.some((o) => o.finance === item)) {
      // If the financial is already selected, remove it
      setFinVal(finVal.filter((o) => o.finance !== item));
    } else {
      // If the financial is not selected, add it
      setFinVal([...finVal, { finance: item }]);
    }
  };

  const handleStreamForAddService = (item) => {
    if (streamVal.some((o) => o.stream === item)) {
      // If the stream is already selected, remove it
      setStreamVal(streamVal.filter((o) => o.stream !== item));
    } else {
      // If the stream is not selected, add it
      setStreamVal([...streamVal, { stream: item }]);
    }
  };

  const handleAddNewService = () => {
    // console.log(gradeVal, 'grade values');
    // console.log(finVal, 'financial values');
    // console.log(streamVal, 'stream values');
    setIsSubmit(true);
    let obj = {
      email: userDetails?.user?.email,
      nameOfService: serviceNameVal,
      icon: coverImageS3url,
      description: serviceDescriptionVal,
      serviceProvider: typeVal,
      access: accessVal,
      goal: goalVal,
      gradeData: gradeVal,
      financialData: finVal,
      stream: streamVal,
      cost: costVal,
      price: priceVal,
      discountType: discountVal,
      discountAmount: discountInputVal,
      duration: durationVal,
      features: featureVal,
      status: statusVal,
      outcome: outcomeVal,
      type: stepVal,
      iterations: iterationVal,
    };

    axios
      .post(`https://oldnaavi.apimachine.com/services/add`, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'add new service response');
        if (result?.status) {
          setIsSubmit(false);
          setpstep(5);
          myTimeoutService();
        }
      })
      .catch((error) => {
        console.log(error, "error in adding a new service");
      });
  };

  // addstep new
  const getAllPaths = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get?email=${mailId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getAllPaths result');
        setPathList(result);
      })
      .catch((error) => {
        console.log(error, "error in getAllPaths");
      });
  };

  const getServiceForMacro = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Macro&cost=${macroStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForMacro result');
        setMacroServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForMacro");
      });
  };

  const getServiceForMicro = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Micro&cost=${microStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForMicro result');
        setMicroServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForMicro");
      });
  };

  const getServiceForNano = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Nano&cost=${nanoStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForNano result');
        setNanoServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForNano");
      });
  };

  const handleMacroServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected macro service ids');
    if (macroServiceVal.some((o) => o?.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setMacroServiceVal(macroServiceVal.filter((o) => o?.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setMacroServiceVal([...macroServiceVal, item]);
    }
  };

  const handleMicroServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected micro service ids');
    if (microServiceVal.some((o) => o.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setMicroServiceVal(microServiceVal.filter((o) => o.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setMicroServiceVal([...microServiceVal, item]);
    }
  };

  const handleNanoServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected nano service ids');
    if (nanoServiceVal.some((o) => o.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setNanoServiceVal(nanoServiceVal.filter((o) => o.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setNanoServiceVal([...nanoServiceVal, item]);
    }
  };

  useEffect(() => {
    if (ispopular) {
      getAllPaths();
    }

    if (macroStepTypeVal) {
      getServiceForMacro();
    }

    if (microStepTypeVal) {
      getServiceForMicro();
    }

    if (nanoStepTypeVal) {
      getServiceForNano();
    }
  }, [ispopular, macroStepTypeVal, microStepTypeVal, nanoStepTypeVal]);

  const myTimeoutStep = () => {
    setTimeout(reloadStep, 3000);
  };

  function reloadStep() {
    setpstep(1);
    setispopular(false);
    navigate("/dashboard/accountants");
    setaccsideNav("Paths");
    setMypathsMenu("Steps");

    // addstep new form fields
    setGeneralMapPathDropdown(false);
    setMacroStepTypeDropdown(false);
    setMacroMapServiceDropDown(false);
    setMicroStepTypeDropdown(false);
    setMicroMapServiceDropDown(false);
    setNanoStepTypeDropdown(false);
    setNanoMapServiceDropDown(false);
    setStepStatusDropdown(false);

    setGeneralMapPathSelected(false);
    setMacroServiceSelected(false);
    setMicroServiceSelected(false);
    setNanoServiceSelected(false);

    setStepNameVal('');
    setStepOrderVal('');
    setMacroStepNameVal('');
    setMicroStepNameVal('');
    setNanoStepNameVal('');
    setMacroStepDescriptionVal('');
    setMicroStepDescriptionVal('');
    setNanoStepDescriptionVal('');
    setMacroStepLengthVal('');
    setMicroStepLengthVal('');
    setNanoStepLengthVal('');
    setMacroStepChanceVal('');
    setMicroStepChanceVal('');
    setNanoStepChanceVal('');
    setMacroStepTypeVal("");
    setMicroStepTypeVal("");
    setNanoStepTypeVal("");
    setSelectedPathVal("");
    setMacroServiceVal([]);
    setMicroServiceVal([]);
    setNanoServiceVal([]);
    setMapPathSearchTerm("");
    setMacroServiceSearchTerm("");
    setMicroServiceSearchTerm("");
    setNanoServiceSearchTerm("");
    setStepStatusVal('');
  }

  const handleNewStep = () => {
    const macroIds = macroServiceVal?.map(obj => obj._id);
    const microIds = microServiceVal?.map(obj => obj._id);
    const nanoIds = nanoServiceVal?.map(obj => obj._id);

    // console.log(macroIds, 'selected macroIds');
    // console.log(microIds, 'selected microIds');
    // console.log(nanoIds, 'selected nanoIds');

    let obj = {
      email: userDetails?.user?.email,
      name: stepNameVal,
      micro_name: microStepNameVal,
      micro_description: microStepDescriptionVal,
      micro_length: microStepLengthVal,
      micro_cost: microStepTypeVal,
      micro_chances: microStepChanceVal,
      microservices: microIds,
      macro_name: macroStepNameVal,
      macro_description: macroStepDescriptionVal,
      macro_length: macroStepLengthVal,
      macro_cost: macroStepTypeVal,
      macro_chances: macroStepChanceVal,
      macroservices: macroIds,
      nano_name: nanoStepNameVal,
      nano_description: nanoStepDescriptionVal,
      nano_length: nanoStepLengthVal,
      nano_cost: nanoStepTypeVal,
      nano_chances: nanoStepChanceVal,
      nanoservices: nanoIds,
      step_order: stepOrderVal,
      path_id: selectedPathVal?._id,
      status: stepStatusVal,
    };

    // console.log(obj, 'addNewStep api body');

    axios.post(`https://oldnaavi.apimachine.com/steps/add`, obj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      let result = response?.data;
      console.log(result, 'handleNewStep result');
      setpstep(14);
      myTimeoutStep();
    }).catch((error) => {
      console.log(error, 'error in handleNewStep');
    })
  };

  // add path new
  const myTimeoutPath = () => {
    setTimeout(reloadPath, 3000);
  };

  function reloadPath() {
    setpstep(1);
    setispopular(false);
    navigate("/dashboard/accountants");
    setaccsideNav("Paths");
    setMypathsMenu("Paths");

    // addpath new form fields
    setPathTypeDropdown(false);
    setPathGradeDropdown(false);
    setPathGradePointDropdown(false);
    setPathCurriculumDropdown(false);
    setPathStreamDropdown(false);
    setPathFinancialDropdown(false);
    setPathUniversityDropdown(false);
    setPathPersonalityDropdown(false);
    setPathDestinationDropdown(false);
    setPathStatusDropdown(false);

    setPathTypeSelected(false);
    setPathGradeSelected(false);
    setPathGradePointSelected(false);
    setPathCurriculumSelected(false);
    setPathStreamSelected(false);
    setPathFinancialSelected(false);
    setPathUniversitySelected(false);
    setPathPersonalitySelected(false);
    setPathDestinationSelected(false);
    setPathStatusSelected(false);

    setpathTypeVal("");
    setPathNameVal("");
    setPathDescriptionVal("");
    setPathCurrentCoordinatesVal("");
    setPathFutureCoordinatesVal("");
    setPathProgramVal("");
    setPathUniversityVal(null);
    setPathDestinationVal("");
    setUniversityLoading(false);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setPathStatusVal("");
  }

  const handleAddNewPath = () => {
    setCreatingPath(true);
    let obj = {
      email: userDetails?.user?.email,
      nameOfPath: pathNameVal,
      description: pathDescriptionVal,
      current_coordinates: pathCurrentCoordinatesVal,
      feature_coordinates: pathFutureCoordinatesVal,
      program: pathProgramVal,
      university: [pathUniversityVal?._id],
      path_type: pathTypeVal,
      personality: personality,
      destination_degree: pathDestinationVal,
      status: pathStatusVal,
      grade: grade,
      grade_avg: gradeAvg,
      financialSituation: finance,
      stream: stream,
      curriculum: curriculum,
    };
    // console.log(obj, 'obj');
    axios
      .post(`https://oldnaavi.apimachine.com/paths/add`, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data;
        console.log(result, "handleAddNewPath result");
        if (result?.status) {
          setCreatingPath(false);
          setpstep(9);
          myTimeoutPath();
        } else {
          setCreatingPath(false);
        }
      })
      .catch((error) => {
        setCreatingPath(false);
        console.log(error, "error in handleAddNewPath");
      });
  };

  const getAllUniversities = () => {
    setUniversityLoading(true);
    axios
      .get(`https://oldnaavi.apimachine.com/universities/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getAllUniversities result');
        setUniversityList(result);
        setUniversityLoading(false);
      })
      .catch((error) => {
        console.log("error in getAllUniversities");
      });
  };

  useEffect(() => {
    if (ispopular) {
      getAllUniversities();
    }
  }, [ispopular]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="dashboard-main">
        <div className="dashboard-body">
          <div>
            <AccDashsidebar
              handleChangeAccDashsidebar={handleChangeAccDashsidebar}
            />
          </div>
          <div className="dashboard-screens" onClick={() => resetpop()}>
            <div style={{ height: "100%" }}>
              <div className="dash-nav">
                <div
                  className="search-input-box"
                  onClick={() => setShowDrop(false)}
                >
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    // value={search}
                    // onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="search-box" onClick={() => setShowDrop(false)}>
                  <img className="search-icon" src={searchic} alt="" />
                </div>
                <div
                  className="full-user"
                  onClick={() => setShowDrop(!showDrop)}
                >
                  <div className="user-box">
                    <img
                      className="user-icon"
                      src={
                        JSON.parse(localStorage.getItem("user"))?.user
                          ?.profile_img !== undefined
                          ? JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img
                          : profile
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className="arrow-box"
                    style={{
                      transform: showDrop ? "rotate(180deg)" : "",
                      cursor: "pointer",
                    }}
                  >
                    <img className="arrow-icon" src={downarrow} alt="" />
                  </div>
                </div>
              </div>
              <>
                {isProfileData ? (
                  <div
                    className="pf-main"
                    onClick={() => setShowDrop(false)}
                    style={{ padding: "0", height: "calc(100% - 70px)" }}
                  >
                    <div className="pf-left">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "4rem",
                          width: "100%",
                          cursor: "pointer",
                          borderBottom: "0.5px solid #E5E5E5",
                          padding: "0 35px",
                        }}
                        onClick={() => {
                          setHidden(!hidden);
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "#1F304F",
                          }}
                        >
                          Basic Profile Data
                        </div>
                        <div
                          style={{ transform: hidden ? "rotate(180deg)" : "" }}
                        >
                          <img src={upArrow} alt="" />
                        </div>
                      </div>

                      <div
                        style={{
                          display: hidden ? "none" : "flex",
                          flexDirection: "column",
                          padding: "0 35px",
                          width: "100%",
                          height: "calc(100% - 4rem)",
                          overflowY: "scroll",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "30px",
                            position: "relative",
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <div
                            className="editIconDiv"
                            style={{ top: "-7px", right: "3px" }}
                            onClick={() => {
                              setEditProfilePic(true);
                            }}
                          >
                            <img src={edit} alt="" />
                          </div>
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              border: "0.5px solid #e5e5e5",
                            }}
                            src={profileData?.profilePicURL}
                            alt=""
                          />
                        </div>
                        <div className="pfl-box">
                          <div className="pfl-boxl">
                            <div className="pfl-box-label">Email</div>
                            <div
                              className="pfl-box-inp"
                              style={{ textTransform: "lowercase" }}
                            >
                              {profileData?.email}
                            </div>
                          </div>
                          <div className="pfl-boxr">
                            <div className="pfl-box-label">Naavi Username</div>
                            <div className="pfl-box-inp">
                              {profileData?.bankerTag}
                            </div>
                          </div>
                        </div>
                        <div className="pfl-box">
                          <div className="pfl-boxl">
                            <div className="pfl-box-label">Country</div>
                            <div className="pfl-box-inp">
                              <div
                                className="editIconDiv"
                                onClick={() => {
                                  setEditCountry(true);
                                }}
                              >
                                <img src={edit} alt="" />
                              </div>
                              {profileData?.country}
                            </div>
                          </div>
                          <div className="pfl-boxr">
                            <div className="pfl-box-label">Address</div>
                            <div className="pfl-box-inp">
                              <div
                                className="editIconDiv"
                                onClick={() => {
                                  setEditAddress(true);
                                }}
                              >
                                <img src={edit} alt="" />
                              </div>
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {profileData?.address}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="pfl-box">
                          <div className="pfl-boxl">
                            <div className="pfl-box-label">Display Name</div>
                            <div className="pfl-box-inp">
                              <div
                                className="editIconDiv"
                                onClick={() => {
                                  setEditDisplayName(true);
                                }}
                              >
                                <img src={edit} alt="" />
                              </div>
                              {profileData?.displayName}
                            </div>
                          </div>
                          <div className="pfl-boxr">
                            <div className="pfl-box-label">Phone Number</div>
                            <div className="pfl-box-inp">
                              <div
                                className="editIconDiv"
                                onClick={() => {
                                  setEditPhoneNo(true);
                                }}
                              >
                                <img src={edit} alt="" />
                              </div>
                              {profileData?.phone}
                            </div>
                          </div>
                        </div>
                        <div className="pfl-box-full">
                          <div className="pfl-box-label">Description</div>
                          <div
                            className="pfl-box-inp-full"
                            style={{ borderRadius: "25px", minHeight: "10rem" }}
                          >
                            <div
                              className="editIconDiv"
                              onClick={() => {
                                setEditDescription(true);
                              }}
                            >
                              <img src={edit} alt="" />
                            </div>
                            {profileData?.description}
                          </div>
                        </div>
                        <div className="pfl-box-full">
                          <div className="pfl-box-label">Cover&nbsp;Photo</div>
                          <div
                            style={{
                              borderRadius: "25px",
                              border: "0.5px solid #e5e5e5",
                              position: "relative",
                            }}
                          >
                            <div
                              className="editIconDiv"
                              onClick={() => {
                                setEditCoverPic(true);
                              }}
                            >
                              <img src={edit} alt="" />
                            </div>
                            <img
                              style={{ width: "100%", borderRadius: "25px" }}
                              src={profileData?.coverPicURL}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="pfl-box">
                          <div className="pfl-boxl">
                            <div className="pfl-box-label">Colour Code</div>
                            <div
                              style={{
                                justifyContent: "space-between",
                              }}
                              className="pfl-box-inp"
                            >
                              <div
                                className="editIconDiv"
                                onClick={() => {
                                  setEditColorCode(true);
                                }}
                              >
                                <img src={edit} alt="" />
                              </div>
                              <div>{profileData?.colorCode}</div>
                              <div
                                style={{
                                  background: `#${profileData?.colorCode}`,
                                  height: "100%",
                                  borderRadius: "35px",
                                  width: "20%",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="pfl-boxr">
                            <div className="pfl-box-label">
                              Partnering Institutions
                            </div>
                            <div className="pfl-box-inp">
                              {/* <div className="editIconDiv">
                                <img src={edit} alt="" />
                              </div> */}
                              {profileData?.partneringInstitutions !==
                                undefined &&
                              profileData?.partneringInstitutions.length > 0
                                ? profileData?.partneringInstitutions[0]._id
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="pfl-box">
                          <div className="pfl-boxl">
                            <div className="pfl-box-label">Category</div>
                            <div className="pfl-box-inp">
                              {/* <div className="editIconDiv">
                                <img src={edit} alt="" />
                              </div> */}
                              {profileData?.category}
                            </div>
                          </div>
                          <div className="pfl-boxr">
                            <div className="pfl-box-label">Sub Category</div>
                            <div className="pfl-box-inp">
                              {/* <div className="editIconDiv">
                                <img src={edit} alt="" />
                              </div> */}
                              {profileData?.subCategory}
                            </div>
                          </div>
                        </div>
                        <div className="pfl-box">
                          <div
                            className="pfl-boxl"
                            style={{ position: "relative" }}
                          >
                            {/* <div className="editIconDiv">
                              <img src={edit} alt="" />
                            </div> */}
                            <div className="pfl-box-label">Specialties</div>
                            <>
                              {profileSpecalities?.length > 0 ? (
                                <>
                                  {profileSpecalities?.map((each, i) => (
                                    <div key={i} className="pfl-box-inp">
                                      {each}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: !hidden ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "4rem",
                          width: "100%",
                          cursor: "not-allowed",
                          borderBottom: "0.5px solid #E5E5E5",
                          padding: "0 35px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "#1F304F",
                          }}
                        >
                          Advanced Profile Data
                        </div>
                        <div style={{ opacity: "0.25" }}>
                          <img src={downArrow} alt="" />
                        </div>
                      </div>
                      <div
                        style={{
                          display: !hidden ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "4rem",
                          width: "100%",
                          cursor: "not-allowed",
                          borderBottom: "0.5px solid #E5E5E5",
                          padding: "0 35px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "#1F304F",
                          }}
                        >
                          Work History
                        </div>
                        <div style={{ opacity: "0.25" }}>
                          <img src={downArrow} alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="pf-right"
                      style={{ minWidth: "30%", height: "100%" }}
                    >
                      <div className="pfr-1">
                        <div>
                          <div className="pfr-head">Make Profile Public</div>
                          <div className="pfr-desc">
                            Making your profile public will allow you to be
                            discovered on the Naavi directory.
                          </div>
                        </div>
                        <div className="pfr-btn1">
                          <div
                            style={{
                              background:
                                accStatus === "Private" ? "#F1F4F6" : "",
                            }}
                            onClick={() => {
                              changeStatus("marketmakers");
                            }}
                          >
                            Private
                          </div>
                          <div
                            style={{
                              background:
                                accStatus === "Public" ? "#F1F4F6" : "",
                            }}
                            onClick={() => {
                              changeStatus("naavi partners");
                            }}
                          >
                            Public
                          </div>
                        </div>
                      </div>
                      <div className="pfr-1">
                        <div>
                          <div className="pfr-head">
                            Create An Vendor Profile
                          </div>
                          <div className="pfr-desc">
                            Click here to change your password. You will need to
                            verify your email again to reset your password.
                          </div>
                        </div>
                        <div className="pfr-btn">Change Password</div>
                      </div>
                      <div className="pfr-2">
                        <div>
                          <div className="pfr-head">Enable 2FA</div>
                          <div className="pfr-desc">
                            For an additional layer of security you can enable 2
                            factor authentication via Google Authenticator.
                          </div>
                        </div>
                        <div className="pfr-btn">Enable</div>
                      </div>
                      {changing && (
                        <div
                          className="loading-component"
                          style={{
                            top: "70px",
                            right: "0",
                            minWidth: "calc(80vw - 56%)",
                            height: "calc(100% - 70px)",
                            position: "absolute",
                            display: "flex",
                          }}
                        >
                          <LoadingAnimation1 icon={lg1} width={200} />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="create-acc"
                      onClick={() => {
                        setCreateBrandProfile(true);
                        setShowDrop(false);
                      }}
                    >
                      <div>Create An Partner Profile</div>
                      <div>
                        <img src={colorArrow} alt="" />
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: "25",
          top: "0",
          left: "0",
          display: ispopular ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {ispopular ? (
          <div
            className="acc-popular"
            onClick={() => setShowDrop(false)}
            onMouseDown={(e) => e.stopPropagation()}
            style={{ background: "#e5e5e5" }}
          >
            <div
              className="acc-popular-top"
              style={{
                display:
                  pstep === 5
                    ? "none"
                    : pstep === 9
                    ? "none"
                    : pstep === 14
                    ? "none"
                    : "flex",
              }}
            >
              <div className="acc-popular-head">
                {pstep > 5 && pstep < 9
                  ? "Add Path"
                  : pstep > 1 && pstep < 5
                  ? "Add Service"
                  : pstep === 10
                  ? "Choose Path"
                  : pstep === 11
                  ? "Add Macro Step"
                  : pstep === 12
                  ? "Add Micro Step"
                  : pstep === 13
                  ? "Add Nano Step"
                  : "Actions"}
              </div>
              <div
                className="acc-popular-img-box"
                onClick={() => resetpop()}
                style={{ cursor: "pointer" }}
              >
                <img className="acc-popular-img" src={closepop} alt="" />
              </div>
            </div>
            <>
              {pstep === 1 && (
                <div>
                  <div className="acc-step-text">New</div>
                  <div>
                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Service");
                        setpstep(2);
                      }}
                      style={{
                        background: selectNew === "Service" ? "#182542" : "",
                        color: selectNew === "Service" ? "#FFF" : "",
                      }}
                    >
                      Service
                    </div>

                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Path");
                        setpstep(6);
                      }}
                      style={{
                        background: selectNew === "Path" ? "#182542" : "",
                        color: selectNew === "Path" ? "#FFF" : "",
                      }}
                    >
                      Path
                    </div>

                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Step");
                        setpstep(10);
                      }}
                      style={{
                        background: selectNew === "Step" ? "#182542" : "",
                        color: selectNew === "Step" ? "#FFF" : "",
                      }}
                    >
                      Step
                    </div>
                  </div>
                </div>
              )}

              {pstep === 2 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Name"
                        value={serviceNameVal}
                        onChange={(e) => {
                          setServiceNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Description"
                        value={serviceDescriptionVal}
                        onChange={(e) => {
                          setServiceDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceTypeDropdown(!serviceTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceTypeSelected ? typeVal : "Type"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceTypeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceTypeSelected(true);
                                setTypeVal(e);
                                setServiceTypeDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceAccessDropdown(!serviceAccessDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {serviceAccessSelected ? accessVal : "Access"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceAccessDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceAccessDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceAccessList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceAccessSelected(true);
                                setAccessVal(e);
                                setServiceAccessDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Goal"
                        value={goalVal}
                        onChange={(e) => {
                          setGoalVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="acc-upload" style={{ marginBottom: "0" }}>
                      <div className="acc-upload-title">
                        Upload Logo
                        {/* <span style={{ color: "red", marginLeft: "2px" }}>*</span> */}
                      </div>
                      <div className="acc-upload-imgbox">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileInputChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <img
                          className="acc-upload-img"
                          src={
                            isUploadLoading
                              ? upgif
                              : coverImageS3url !== ""
                              ? coverImageS3url
                              : uploadv
                          }
                          alt=""
                          onClick={handleImageClick}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(3);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 3 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceCostDropdown(!serviceCostDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceCostSelected ? costVal : "Cost"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceCostDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceCostDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceCostList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceCostSelected(true);
                                setCostVal(e);
                                setServiceCostDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        display: costVal === "paid" ? "flex" : "none",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="Price"
                        onChange={(e) => {
                          setPriceVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={priceVal}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        flexDirection: "column",
                        background: "white",
                        display:
                          costVal === "paid" && priceVal ? "flex" : "none",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceDiscountDropdown(!serviceDiscountDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceDiscountSelected ? discountVal : "Discount"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceDiscountDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceDiscountDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceDiscountList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceDiscountSelected(true);
                                setDiscountVal(e);
                                setServiceDiscountDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        display: discountVal ? "flex" : "none",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="Discount value"
                        onChange={(e) => {
                          setDiscountInputVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={discountInputVal}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="Duration"
                        onChange={(e) => {
                          setDurationVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={durationVal}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Features"
                        value={featureVal}
                        onChange={(e) => {
                          setFeatureVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStatusDropdown(!serviceStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceStatusSelected ? statusVal : "Status"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStatusList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStatusSelected(true);
                                setStatusVal(e);
                                setServiceStatusDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceGradeDropdown(!serviceGradeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceGradeSelected && gradeVal?.length > 0 ? (
                            <>
                              {gradeVal?.map((e, i) => {
                                return gradeVal?.length - 1 === i
                                  ? e?.grade + "th" + " "
                                  : e?.grade + "th" + "," + " ";
                              })}
                            </>
                          ) : (
                            "Grade Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceGradeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceGradeDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceGradeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceGradeSelected(true);
                                handleGradeForAddService(e);
                                // setServiceGradeDropdown(false);
                              }}
                            >
                              <div key={i}>{e + "th"}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(2);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(4);
                          // console.log(gradeVal, 'service grade val');
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 4 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceFinancialDropdown(
                            !serviceFinancialDropdown
                          );
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceFinancialSelected && finVal?.length > 0 ? (
                            <>
                              {finVal?.map((e, i) => {
                                return finVal?.length - 1 === i
                                  ? e?.finance + " "
                                  : e?.finance + "," + " ";
                              })}
                            </>
                          ) : (
                            "Financial Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceFinancialDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceFinancialDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceFinancialList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceFinancialSelected(true);
                                handleFinancialForAddService(e);
                                // setServiceFinancialDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStreamDropdown(!serviceStreamDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceStreamSelected && streamVal?.length > 0 ? (
                            <>
                              {streamVal?.map((e, i) => {
                                return streamVal?.length - 1 === i
                                  ? e?.stream + " "
                                  : e?.stream + "," + " ";
                              })}
                            </>
                          ) : (
                            "Stream Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStreamDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStreamDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStreamList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStreamSelected(true);
                                handleStreamForAddService(e);
                                // setServiceStreamDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Outcome"
                        onChange={(e) => {
                          setOutcomeVal(e.target.value);
                          // console.log(e.target.value, 'outcomeVal')
                        }}
                        value={outcomeVal}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStepDropdown(!serviceStepDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceStepSelected ? stepVal : "Suitable Step"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStepDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStepDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStepList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStepSelected(true);
                                setStepVal(e);
                                setServiceStepDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceIterationDropdown(
                            !serviceIterationDropdown
                          );
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {serviceIterationSelected
                            ? iterationVal
                            : "Iterations"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceIterationDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceIterationDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceIterationList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceIterationSelected(true);
                                setIterationVal(e);
                                setServiceIterationDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(3);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        handleAddNewService();
                      }}
                    >
                      Add Service
                    </div>
                  </div>
                  {isSubmit && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {pstep === 5 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Service Added Successfully
                </div>
              )}

              {pstep === 6 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Path Name"
                        value={pathNameVal}
                        onChange={(e) => {
                          setPathNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Description"
                        value={pathDescriptionVal}
                        onChange={(e) => {
                          setPathDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        placeholder="Current Coordinates"
                        value={pathCurrentCoordinatesVal}
                        onChange={(e) => {
                          setPathCurrentCoordinatesVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        placeholder="Future Coordinates"
                        value={pathFutureCoordinatesVal}
                        onChange={(e) => {
                          setPathFutureCoordinatesVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathTypeDropdown(!pathTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {pathTypeSelected ? pathTypeVal : "Type of Path"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {pathTypeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathTypeSelected(true);
                                setpathTypeVal(e);
                                setPathTypeDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathGradeDropdown(!pathGradeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathGradeSelected && grade?.length > 0 ? (
                            <>
                              {grade?.map((e, i) => {
                                return grade?.length - 1 === i
                                  ? e?.grade + "th" + " "
                                  : e?.grade + "th" + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal Grade"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathGradeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathGradeDropdown ? "flex" : "none",
                        }}
                      >
                        {gradeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathGradeSelected(true);
                                handleGrade(e);
                                // setPathGradeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(7);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 7 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathGradePointDropdown(!pathGradePointDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathGradePointSelected && gradeAvg?.length > 0 ? (
                            <>
                              {gradeAvg?.map((e, i) => {
                                return gradeAvg?.length - 1 === i
                                  ? e?.grade_avg + " "
                                  : e?.grade_avg + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal grade points average"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathGradePointDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathGradePointDropdown ? "flex" : "none",
                        }}
                      >
                        {gradePointAvg?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathGradePointSelected(true);
                                handleGradeAvg(e);
                                // setPathGradePointDropdown(false);
                              }}
                            >
                              <div key={i}>{e + "%"}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathCurriculumDropdown(!pathCurriculumDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathCurriculumSelected && curriculum?.length > 0 ? (
                            <>
                              {curriculum?.map((e, i) => {
                                return curriculum?.length - 1 === i
                                  ? e?.curriculum + " "
                                  : e?.curriculum + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal curriculum"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathCurriculumDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathCurriculumDropdown ? "flex" : "none",
                        }}
                      >
                        {curriculumList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathCurriculumSelected(true);
                                handleCurriculum(e);
                                // setPathCurriculumDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathStreamDropdown(!pathStreamDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathStreamSelected && stream?.length > 0 ? (
                            <>
                              {stream?.map((e, i) => {
                                return stream?.length - 1 === i
                                  ? e?.stream + " "
                                  : e?.stream + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal stream"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathStreamDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathStreamDropdown ? "flex" : "none",
                        }}
                      >
                        {streamList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathStreamSelected(true);
                                handleStream(e);
                                // setPathStreamDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathFinancialDropdown(!pathFinancialDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathFinancialSelected && finance?.length > 0 ? (
                            <>
                              {finance?.map((e, i) => {
                                return finance?.length - 1 === i
                                  ? e?.finance + " "
                                  : e?.finance + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal financial situation"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathFinancialDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathFinancialDropdown ? "flex" : "none",
                        }}
                      >
                        {financeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathFinancialSelected(true);
                                handleFinance(e);
                                // setPathFinancialDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="What program will they be studying?"
                        value={pathProgramVal}
                        onChange={(e) => {
                          setPathProgramVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathUniversityDropdown(!pathUniversityDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathUniversitySelected && pathUniversityVal
                            ? pathUniversityVal?.name
                            : "University"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathUniversityDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: pathUniversityDropdown ? "flex" : "none",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search University"
                          onChange={(e) => {
                            setUniversitySearch(e.target.value);
                          }}
                          value={universitySearch}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathUniversityDropdown ? "flex" : "none",
                          height: "50vh",
                          overflowY: "scroll",
                        }}
                      >
                        {universityLoading
                          ? Array(10)
                              .fill(" ")
                              .map((item, index) => {
                                return (
                                  <div key={index} className="each-hidden-step">
                                    <Skeleton width={"100%"} height={30} />
                                  </div>
                                );
                              })
                          : universityList
                              ?.filter((each) =>
                                each?.name
                                  ?.toLowerCase()
                                  .includes(universitySearch?.toLowerCase())
                              )
                              ?.map((e, i) => {
                                return (
                                  <div
                                    className="each-hidden-step"
                                    onClick={() => {
                                      setPathUniversitySelected(true);
                                      setPathUniversityVal(e);
                                      setPathUniversityDropdown(false);
                                      setUniversitySearch("");
                                    }}
                                  >
                                    <div key={i}>{e?.name}</div>
                                  </div>
                                );
                              })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(6);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(8);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 8 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathPersonalityDropdown(!pathPersonalityDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textTransform:
                              pathPersonalitySelected && personality
                                ? "capitalize"
                                : "none",
                          }}
                        >
                          {pathPersonalitySelected && personality
                            ? personality
                            : "What personality suits this path?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathPersonalityDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathPersonalityDropdown ? "flex" : "none",
                        }}
                      >
                        {personalityList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathPersonalitySelected(true);
                                handlePersonality(e);
                                setPathPersonalityDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathDestinationDropdown(!pathDestinationDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathDestinationSelected && pathDestinationVal
                            ? pathDestinationVal
                            : "Destination Degree Type"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathDestinationDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathDestinationDropdown ? "flex" : "none",
                        }}
                      >
                        {degreeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathDestinationSelected(true);
                                setPathDestinationVal(e);
                                setPathDestinationDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathStatusDropdown(!pathStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {pathStatusSelected ? pathStatusVal : "Status"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {pathStatusList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathStatusSelected(true);
                                setPathStatusVal(e);
                                setPathStatusDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(7);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        handleAddNewPath();
                      }}
                    >
                      Add Path
                    </div>
                  </div>
                  {creatingPath && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {pstep === 9 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Path Added Successfully
                </div>
              )}

              {pstep === 10 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={stepNameVal}
                        onChange={(e) => {
                          setStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: generalMapPathDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setGeneralMapPathDropdown(!generalMapPathDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Path
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: generalMapPathDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: generalMapPathDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Path"
                          onChange={(e) => {
                            setMapPathSearchTerm(e.target.value);
                          }}
                          value={mapPathSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: generalMapPathDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {pathList
                          ?.filter((entry) =>
                            entry?.nameOfPath
                              ?.toLowerCase()
                              .includes(mapPathSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setSelectedPathVal(e);
                                  setGeneralMapPathSelected(true);
                                  setGeneralMapPathDropdown(false);
                                  setMapPathSearchTerm("");
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfPath}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {generalMapPathSelected && selectedPathVal ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            background: "white",
                            borderRadius: "15px",
                            padding: "1.5rem",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            {selectedPathVal?.nameOfPath}
                          </div>
                          <div
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "300",
                              lineHeight: "1.6",
                            }}
                          >
                            {selectedPathVal?.description}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="Order"
                        value={stepOrderVal}
                        onChange={(e) => {
                          setStepOrderVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setStepStatusDropdown(!stepStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {stepStatusVal
                            ? stepStatusVal
                            : "Step status"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: stepStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: stepStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {stepStatusList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setStepStatusVal(e);
                                setStepStatusDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(11);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 11 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={macroStepNameVal}
                        onChange={(e) => {
                          setMacroStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={macroStepLengthVal}
                        onChange={(e) => {
                          setMacroStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={macroStepDescriptionVal}
                        onChange={(e) => {
                          setMacroStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMacroStepTypeDropdown(!macroStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {macroStepTypeVal
                            ? macroStepTypeVal
                            : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: macroStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: macroStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {macroStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setMacroStepTypeVal(e);
                                setMacroStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: macroMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setMacroMapServiceDropDown(!macroMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: macroMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: macroMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMacroServiceSearchTerm(e.target.value);
                          }}
                          value={macroServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: macroMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {macroServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(macroServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setMacroServiceSelected(true);
                                  handleMacroServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {macroServiceSelected && macroServiceVal?.length > 0
                        ? macroServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={macroStepChanceVal}
                        onChange={(e) => {
                          setMacroStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(10);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(12);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 12 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={microStepNameVal}
                        onChange={(e) => {
                          setMicroStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={microStepLengthVal}
                        onChange={(e) => {
                          setMicroStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={microStepDescriptionVal}
                        onChange={(e) => {
                          setMicroStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMicroStepTypeDropdown(!microStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {microStepTypeVal
                            ? microStepTypeVal
                            : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: microStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: microStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {microStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setMicroStepTypeVal(e);
                                setMicroStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: microMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setMicroMapServiceDropDown(!microMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: microMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: microMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMicroServiceSearchTerm(e.target.value);
                          }}
                          value={microServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: microMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {microServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(microServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setMicroServiceSelected(true);
                                  handleMicroServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {microServiceSelected && microServiceVal?.length > 0
                        ? microServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={microStepChanceVal}
                        onChange={(e) => {
                          setMicroStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(11);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(13);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 13 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={nanoStepNameVal}
                        onChange={(e) => {
                          setNanoStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={nanoStepLengthVal}
                        onChange={(e) => {
                          setNanoStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={nanoStepDescriptionVal}
                        onChange={(e) => {
                          setNanoStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNanoStepTypeDropdown(!nanoStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {nanoStepTypeVal ? nanoStepTypeVal : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: nanoStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: nanoStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {nanoStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setNanoStepTypeVal(e);
                                setNanoStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: nanoMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setNanoMapServiceDropDown(!nanoMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: nanoMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: nanoMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setNanoServiceSearchTerm(e.target.value);
                          }}
                          value={nanoServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: nanoMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {nanoServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(nanoServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setNanoServiceSelected(true);
                                  handleNanoServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {nanoServiceSelected && nanoServiceVal?.length > 0
                        ? nanoServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={nanoStepChanceVal}
                        onChange={(e) => {
                          setNanoStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(12);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        
                        handleNewStep();
                      }}
                    >
                      Add Step
                    </div>
                  </div>
                </>
              )}

              {pstep === 14 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Step Added Successfully
                </div>
              )}
            </>
          </div>
        ) : (
          ""
        )}
      </div>

      <>
        {showDrop ? (
          <div className="m-drop" onMouseDown={(e) => e.stopPropagation()}>
            {/* <div className="m-each">
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={accounts} alt="" />
                </div>
                <div className="m-left-text">Accounts</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
            <div className="m-each">
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={vaults} alt="" />
                </div>
                <div className="m-left-text">Vaults</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div> */}
            <div
              className="m-each"
              onClick={() => {
                setaccsideNav("");
                navigate("/");
              }}
            >
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={profilea} alt="" />
                </div>
                <div className="m-left-text">Profile</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
            <div className="m-each-line"> </div>
            {/* <div className="m-each" style={{ opacity: "0.25" }}>
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={support} alt="" />
                </div>
                <div className="m-left-text">Support</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
            <div className="m-each" style={{ opacity: "0.25" }}>
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={settings} alt="" />
                </div>
                <div className="m-left-text">Settings</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div> */}
            <div className="m-each" onClick={() => handleLogout()}>
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={logout} alt="" />
                </div>
                <div className="m-left-text">Logout</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>

      <>
        {createBrandProfile && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.6)",
              zIndex: "25",
              top: "0px",
              left: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="popularS"
              style={{
                padding:
                  createBrandProfileStep === 2
                    ? "1rem 0rem 2rem"
                    : "1rem 3rem 2rem",
              }}
            >
              {createBrandProfileStep === 1 && (
                <>
                  <div className="head-txt" style={{ height: "4rem" }}>
                    <div>Step 1</div>
                    <div
                      onClick={() => {
                        setCreateBrandProfile(false);
                        // setUserName("");
                        setLastName("");
                        setFirstName("");
                        setProfilePicture("");
                      }}
                      className="close-div"
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                  <div
                    className="overall-div"
                    style={{ height: "calc(100% - 4rem)" }}
                  >
                    <div
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        marginTop: "2rem",
                      }}
                    >
                      Upload Profile Picture
                    </div>
                    <ImageUploadDivProfilePic1
                      setFunc={setProfilePicture}
                      funcValue={profilePicture}
                    />
                    <InputDivsWithMT
                      heading="What is your first name?"
                      placeholderText="First Name.."
                      setFunc={setFirstName}
                      funcValue={firstName}
                    />
                    <InputDivsWithMT
                      heading="What is your last name?"
                      placeholderText="Last Name.."
                      setFunc={setLastName}
                      funcValue={lastName}
                    />
                    {/* <InputDivsCheckFunctionality
                    heading="Select a username"
                    placeholderText="Username..."
                    setFunc={setUserName}
                    funcValue={userName}
                    userNameAvailable={userNameAvailable}
                  /> */}
                    <div className="stepBtns" style={{ marginTop: "3.5rem" }}>
                      <div
                        style={{
                          opacity: "0.25",
                          cursor: "not-allowed",
                          background: "#1F304F",
                          width: "48%",
                          minHeight: "3.5rem",
                          maxHeight: "3.5rem",
                        }}
                      >
                        Go Back
                      </div>
                      <div
                        style={{
                          minHeight: "3.5rem",
                          maxHeight: "3.5rem",
                          opacity:
                            profilePicture && firstName && lastName
                              ? // && userName.length > 0 &&
                                // userNameAvailable
                                "1"
                              : "0.25",
                          cursor:
                            profilePicture && firstName && lastName
                              ? // && userName.length > 0 &&
                                // userNameAvailable
                                "pointer"
                              : "not-allowed",
                          background: "#59A2DD",
                          width: "48%",
                        }}
                        onClick={() => {
                          if (
                            profilePicture &&
                            firstName &&
                            lastName
                            // && userName.length > 0 &&
                            // userNameAvailable
                          ) {
                            createLXProfile();
                          }
                        }}
                      >
                        Next Step
                      </div>
                    </div>
                  </div>
                </>
              )}

              {createBrandProfileStep === 2 && (
                <>
                  <div
                    className="head-txt"
                    style={{ padding: "0 3rem", height: "4rem" }}
                  >
                    <div>Step 2</div>
                    <div
                      onClick={() => {
                        setCreateBrandProfile(false);
                        setCreateBrandProfileStep(1);
                        setWhiteProPic("");
                        setBrandAddress("");
                        setHeadquarter("");
                        setBrandColorCode("");
                        setBrandDescription("");
                        setBrandUserName("");
                        setBrandDisplayName("");
                        // setUserName("");
                        setLastName("");
                        setFirstName("");
                        setProfilePicture("");
                      }}
                      className="close-div"
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                  <div
                    className="overall-div"
                    style={{ height: "calc(100% - 4rem)" }}
                  >
                    <div className="coverPic-container">
                      <div className="coverPicDiv">
                        <ImageUploadDivCoverPic1
                          setFunc={setCoverPhoto1}
                          funcValue={coverPhoto1}
                        />
                      </div>
                      <div className="logoDiv">
                        <img
                          src={profilePicture}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            border: "none",
                          }}
                        />
                      </div>
                    </div>
                    <div className="inputs-container">
                      <InputDivsWithMT
                        heading="Display Name"
                        placeholderText="Display Name.."
                        setFunc={setBrandDisplayName}
                        funcValue={brandDisplayName}
                      />
                      <InputDivsCheckFunctionality
                        heading="Naavi Username"
                        placeholderText="Username.."
                        setFunc={setBrandUserName}
                        funcValue={brandUserName}
                        userNameAvailable={userNameAvailable1}
                      />
                      <InputDivsTextArea1
                        heading="Naavi Bio"
                        placeholderText="Bio..."
                        setFunc={setBrandDescription}
                        funcValue={brandDescription}
                      />
                      <InputDivsWithColorCode
                        heading="Colour Code"
                        placeholderText="#000000"
                        setFunc={setBrandColorCode}
                        funcValue={brandColorCode}
                        colorCode={brandColorCode}
                      />
                      <InputDivsWithMT
                        heading="What country do you practice accounting in?"
                        placeholderText="Click To Select"
                        setFunc={setHeadquarter}
                        funcValue={headquarter}
                      />
                      <InputDivsWithMT
                        heading="What is your office address?"
                        placeholderText="Enter address..."
                        setFunc={setBrandAddress}
                        funcValue={brandAddress}
                      />
                      <div
                        style={{
                          marginTop: "3rem",
                          marginBottom: "0.5rem",
                          fontSize: "1.1rem",
                        }}
                      >
                        Upload white profile picture
                      </div>
                      <ImageUploadProfilePic2
                        setFunc={setWhiteProPic}
                        funcValue={whiteProPic}
                      />
                      <div className="stepBtns" style={{ marginTop: "3.5rem" }}>
                        <div
                          style={{
                            cursor: "pointer",
                            background: "#1F304F",
                            width: "48%",
                          }}
                          onClick={() => {
                            setWhiteProPic("");
                            setBrandAddress("");
                            setHeadquarter("");
                            setBrandColorCode("");
                            setBrandDescription("");
                            setBrandUserName("");
                            setBrandDisplayName("");
                            setCoverPhoto1("");
                            setCreateBrandProfileStep(1);
                          }}
                        >
                          Go Back
                        </div>
                        <div
                          style={{
                            opacity:
                              coverPhoto1 &&
                              whiteProPic &&
                              brandAddress &&
                              headquarter &&
                              brandColorCode &&
                              brandDescription &&
                              brandUserName.length > 0 &&
                              brandDisplayName &&
                              userNameAvailable1
                                ? "1"
                                : "0.25",
                            cursor:
                              coverPhoto1 &&
                              whiteProPic &&
                              brandAddress &&
                              headquarter &&
                              brandColorCode &&
                              brandDescription &&
                              brandUserName.length > 0 &&
                              brandDisplayName &&
                              userNameAvailable1
                                ? "pointer"
                                : "not-allowed",
                            background: "#59A2DD",
                            width: "48%",
                          }}
                          onClick={() => {
                            if (
                              coverPhoto1 &&
                              whiteProPic &&
                              brandAddress &&
                              headquarter &&
                              brandColorCode &&
                              brandDescription &&
                              brandUserName.length > 0 &&
                              brandDisplayName &&
                              userNameAvailable1
                            ) {
                              createBankerProfile();
                            }
                          }}
                        >
                          Complete
                        </div>
                      </div>
                    </div>
                  </div>
                  {isloading && (
                    <div
                      className="loading-component"
                      style={{
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display: "flex",
                      }}
                    >
                      <LoadingAnimation1 icon={lg1} width={200} />
                    </div>
                  )}
                </>
              )}

              {createBrandProfileStep === 3 && (
                <div className="successMsg">
                  You Have Successfully Created Your Naavi Profile.
                </div>
              )}
            </div>
          </div>
        )}
      </>

      {editCountry && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Country</div>
              <div
                onClick={() => {
                  setEditCountry(false);
                  setNewCountry("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1">
                <div>{profileData?.country}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Country.."
                  onChange={(e) => {
                    setNewCountry(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newCountry ? "1" : "0.25",
                  cursor: newCountry ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newCountry) {
                    editData("country", newCountry);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editAddress && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Address</div>
              <div
                onClick={() => {
                  setEditAddress(false);
                  setNewAddress("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1">
                <div>{profileData?.address}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Address.."
                  onChange={(e) => {
                    setNewAddress(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newAddress ? "1" : "0.25",
                  cursor: newAddress ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newAddress) {
                    editData("address", newAddress);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editDisplayName && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Display Name</div>
              <div
                onClick={() => {
                  setEditDisplayName(false);
                  setNewDisplayName("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1">
                <div>{profileData?.displayName}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Display Name.."
                  onChange={(e) => {
                    setNewDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newDisplayName ? "1" : "0.25",
                  cursor: newDisplayName ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newDisplayName) {
                    editData("displayName", newDisplayName);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editPhoneNo && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Phone Number</div>
              <div
                onClick={() => {
                  setEditPhoneNo(false);
                  setNewPhoneNo("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1">
                <div>{profileData?.phone}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="number"
                  placeholder="New Phone Number.."
                  onChange={(e) => {
                    setNewPhoneNo(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newPhoneNo ? "1" : "0.25",
                  cursor: newPhoneNo ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newPhoneNo) {
                    editData("phone", newPhoneNo);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editDescription && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Description</div>
              <div
                onClick={() => {
                  setEditDescription(false);
                  setNewDescription("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1">
                <div>{profileData?.description}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Description.."
                  onChange={(e) => {
                    setNewDescription(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newDescription ? "1" : "0.25",
                  cursor: newDescription ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newDescription) {
                    editData("description", newDescription);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editColorCode && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Colour Code</div>
              <div
                onClick={() => {
                  setEditColorCode(false);
                  setNewColorCode("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div className="each-action1" style={{ position: "relative" }}>
                <div>{profileData?.colorCode}</div>
                <div
                  className="bgColorDiv"
                  style={{
                    background: `#${profileData?.colorCode}`,
                  }}
                ></div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1" style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder="New Colour Code.."
                  onChange={(e) => {
                    setNewColorCode(e.target.value);
                  }}
                />
                <div
                  className="bgColorDiv"
                  style={{
                    background: newColorCode
                      ? `#${newColorCode}`
                      : "transparent",
                  }}
                ></div>
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newColorCode ? "1" : "0.25",
                  cursor: newColorCode ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newColorCode) {
                    editData("colorCode", newColorCode);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editProfilePic && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Profile Picture</div>
              <div
                onClick={() => {
                  setEditProfilePic(false);
                  setNewProfilePic("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div
                className="each-action1"
                style={{ border: "none", justifyContent: "center" }}
              >
                <div style={{ height: "120px", width: "120px" }}>
                  <img
                    src={profileData?.profilePicURL}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      border: "0.5px solid #e5e5e5",
                    }}
                  />
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div
                className="each-action1"
                style={{ border: "none", justifyContent: "center" }}
              >
                <ImageUploadDivProfilePic
                  setFunc={setNewProfilePic}
                  funcValue={newProfilePic}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newProfilePic ? "1" : "0.25",
                  cursor: newProfilePic ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newProfilePic) {
                    editData("profilePicURL", newProfilePic);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      {editCoverPic && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Cover Photo</div>
              <div
                onClick={() => {
                  setEditCoverPic(false);
                  setNewCoverPic("");
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="overall-div"
              style={{ height: "calc(100% - 10.5rem)" }}
            >
              <div
                className="each-action1"
                style={{ height: "12rem", padding: "0" }}
              >
                <div style={{ height: "100%", width: "100%" }}>
                  <img
                    src={profileData?.coverPicURL}
                    alt=""
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div
                className="each-action1"
                style={{ height: "12rem", padding: "0" }}
              >
                <ImageUploadDivCoverPic
                  setFunc={setNewCoverPic}
                  funcValue={newCoverPic}
                />
              </div>
            </div>

            <div className="stepBtns" style={{ height: "4.5rem" }}>
              <div
                style={{
                  opacity: newCoverPic ? "1" : "0.25",
                  cursor: newCoverPic ? "pointer" : "not-allowed",
                  background: "#59A2DD",
                }}
                onClick={() => {
                  if (newCoverPic) {
                    editData("coverPicURL", newCoverPic);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "calc(100% - 70px)",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AccProfile;

export const ImageUploadDivProfilePic1 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{
        minWidth: "140px",
        minHeight: "140px",
        maxWidth: "140px",
        maxHeight: "140px",
        border: "0.5px solid #e7e7e7",
        borderRadius: "50%",
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              borderRadius: "50%",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={uploadGrey}
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadProfilePic2 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{
        minWidth: "140px",
        minHeight: "140px",
        maxWidth: "140px",
        maxHeight: "140px",
        border: "0.5px solid #e7e7e7",
        borderRadius: "50%",
        display: "flex",
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "140px",
            height: "140px",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              borderRadius: "50%",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={uploadGrey}
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivCoverPic1 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%", borderRadius: "0" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img src={cover} alt="" />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const InputDivsCheckFunctionality = ({
  heading,
  placeholderText,
  setFunc,
  funcValue,
  userNameAvailable,
}) => {
  return (
    <div className={classNames.inputDivs} style={{ marginTop: "3rem" }}>
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.inputHolder}>
        <input
          className={classNames.inputFields}
          placeholder={placeholderText}
          onChange={(event) => {
            setFunc(event.target.value);
          }}
          value={funcValue ? funcValue : ""}
          style={{ borderRadius: "35px" }}
        />

        <div
          className={classNames.currencyDiv2}
          style={{ background: userNameAvailable ? "#86D5BD" : "#1f304f" }}
        >
          {userNameAvailable ? "Available" : "Check"}
        </div>
      </div>
      {funcValue?.length > 0 && !userNameAvailable && (
        <div
          style={{
            fontSize: "0.8rem",
            zIndex: "2",
            width: "95%",
            display: "flex",
            justifyContent: "center",
            background: "rgba(241, 244, 246)",
            padding: "5px",
            borderBottomLeftRadius: "35px",
            borderBottomRightRadius: "35px",
            margin: "-16px auto",
          }}
        >
          This username is not available. Please try again.
        </div>
      )}
    </div>
  );
};

export const InputDivsCheckFunctionality1 = ({
  heading,
  placeholderText,
  setFunc,
  funcValue,
  userNameAvailable,
}) => {
  return (
    <div className={classNames.inputDivs} style={{ marginTop: "3rem" }}>
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.inputHolder}>
        <input
          className={classNames.inputFields}
          placeholder={placeholderText}
          onChange={(event) => {
            setFunc(event.target.value);
          }}
          value={funcValue ? funcValue : ""}
          style={{ borderRadius: "35px" }}
        />

        <div
          className={classNames.currencyDiv2}
          style={{ background: userNameAvailable ? "#86D5BD" : "#1f304f" }}
        >
          {userNameAvailable ? "Available" : "Check"}
        </div>
      </div>
      {/* {funcValue?.length > 0 && !userNameAvailable && (
        <div
          style={{
            fontSize: "0.8rem",
            zIndex: "2",
            width: "95%",
            display: "flex",
            justifyContent: "center",
            background: "rgba(241, 244, 246)",
            padding: "5px",
            borderBottomLeftRadius: "35px",
            borderBottomRightRadius: "35px",
            margin: "-16px auto",
          }}
        >
          This username is not available. Please try again.
        </div>
      )} */}
    </div>
  );
};

export const ImageUploadDivProfilePic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{
        width: "120px",
        height: "120px",
        border: "0.5px solid #e7e7e7",
        borderRadius: "50%",
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivCoverPic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{ width: "100%", height: "100%" }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};
