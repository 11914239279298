import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./accDashboard.scss";
import searchic from "../../static/images/dashboard/searchic.svg";
import downarrow from "../../static/images/dashboard/downarrow.svg";
import uploadv from "../../static/images/dashboard/uploadv.svg";
import nvest from "../../static/images/dashboard/nvest.svg";
import profile from "../../static/images/dashboard/profile.svg";
import closepop from "../../static/images/dashboard/closepop.svg";
import accounts from "../../static/images/dashboard/accounts.svg";
import vaults from "../../static/images/dashboard/vaults.svg";
import profilea from "../../static/images/dashboard/profilea.svg";
import support from "../../static/images/dashboard/support.svg";
import settings from "../../static/images/dashboard/settings.svg";
import sidearrow from "../../static/images/dashboard/sidearrow.svg";
import logout from "../../static/images/dashboard/logout.svg";
import upgif from "../../static/images/dashboard/upgif.gif";
import lg1 from "../../static/images/login/lg1.svg";
import threedot from "../../static/images/dashboard/threedot.svg";
import close from "../../images/close.svg";
import upload from "../../images/upload.svg";
import { useStore } from "../../components/store/store.ts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AccDashsidebar from "../../components/accDashsidebar/accDashsidebar";
import {
  GetFollowersPerAccount,
  GetCategoriesAcc,
  GetAllCustomerLicenses,
  GetLogServices,
  GetAllCurrencies,
  CreatePopularService,
  DeleteServiceFunction,
  addCompPlanFunction,
} from "../../services/accountant";
import { formatDate } from "../../utils/time";
import * as jose from "jose";
import EarningCalendar from "./EarningCalendar/index";
import { LoadingAnimation1 } from "../../components/LoadingAnimation1";
import { uploadImageFunc } from "../../utils/imageUpload";
import Vaults from "../Vaults";
import Toggle from "../../components/Toggle";
import Tasks from "../Tasks";
import arrow from "./arrow.svg";
import trash from "./trash.svg";
import { useCoinContextData } from "../../context/CoinContext";
import MyPaths from "../MyPaths";
import NewStep1 from "../../globalComponents/GlobalDrawer/NewStep1";
import VaultTransactions from "../VaultTransactions/index.jsx";
import backIcon from "../../static/images/icons/backIcon.svg";
import nextIcon from "../../static/images/icons/nextIcon.svg";
import dropdownIcon from "../../static/images/icons/dropdownIcon.svg";

const AccDashboard = () => {
  const {
    accsideNav,
    setaccsideNav,
    ispopular,
    setispopular,
    coinType,
    setCoinType,
    balanceToggle,
    setBalanceToggle,
  } = useStore();
  const [search, setSearch] = useState("");
  const [crmMenu, setcrmMenu] = useState("Users");
  const [servicesMenu, setservicesMenu] = useState("Services");
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [isCatoading, setIsCatLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [followCount, setfollowCount] = useState(0);
  const [followData, setfollowData] = useState([]);
  const [coverImageS3url, setCoverImageS3url] = useState("");
  const [selectedFollower, setSelectedFollower] = useState({});
  const [pstep, setpstep] = useState(1);
  const [selectNew, setselectNew] = useState("");
  const [billingType, setbillingType] = useState("");
  const [categoriesData, setcategoriesData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [selectCategory, setselectCategory] = useState("");
  const [serviceNameInput, setServiceNameInput] = useState("");
  const [serviceCodeInput, setServiceCodeInput] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [serviceTagline, setServiceTagline] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [showDrop, setShowDrop] = useState(false);
  const [isCurrencies, setIsCurrencies] = useState(false);
  const [allCurrencies, setallCurrencies] = useState([]);
  const [searchCurrency, setSearchCurrency] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [firstMonthPrice, setfirstMonthPrice] = useState("");
  const [monthlyPrice, setmonthlyPrice] = useState("");
  const [gracePeriod, setgracePeriod] = useState("");
  const [secondChargeAttempt, setsecondChargeAttempt] = useState("");
  const [thirdChargeAttempt, setthirdChargeAttempt] = useState("");
  const [image, setImage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isServicesAcc, setIsServicesAcc] = useState(false);
  const [servicesAcc, setservicesAcc] = useState([]);
  const [serviceActionEnabled, setServiceActionEnabled] = useState(false);
  const [serviceActionStep, setServiceActionStep] = useState(1);
  const [selectedService, setSelectedService] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [updatedIcon, setUpdatedIcon] = useState("");

  //add compPlan
  const [addCompPlan, setAddCompPlan] = useState(false);
  const [addCompPlanStep, setAddCompPlanStep] = useState("step1");
  const [userCreatedApps, setUserCreatedApps] = useState([]);
  const [compPlanApp, setCompPlanApp] = useState("");
  const [levels, setLevels] = useState();
  const [addingComp, setAddingComp] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [multiplier, setMultiplier] = useState([]);
  const [isfetching, setIsfetching] = useState(false);

  //with compPlan
  const [withCompPlanData, setWithCompPlanData] = useState([]);
  const [gettingData, setGettingData] = useState(false);

  // new step
  const [mainMenu, setMainMenu] = useState("");
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);
  const [backupPathList, setBackupPathList] = useState([]);
  const [showBackupPathList, setShowBackupPathList] = useState(false);

  // new path
  const [grade, setGrade] = useState([]);
  const [gradeAvg, setGradeAvg] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [stream, setStream] = useState([]);
  const [finance, setFinance] = useState([]);
  const [personality, setPersonality] = useState("");
  const streamList = ["MPC", "BIPC", "CEC", "HEC", "MEC"];
  const curriculumList = ["IB", "CBSE", "IG", "CSE", "ICSE", "Nordic"];
  const gradeList = ["9", "10", "11", "12"];
  const gradePointAvg = [
    "0-35",
    "36-60",
    "61-75",
    "76-85",
    "86-95",
    "96-100",
  ];
  const financeList = ["0-25L", "25L-75L", "75L-3CR", "3CR+"];
  const personalityList = [
    "realistic",
    "social",
    "enterprising",
    "artistic",
    "conventional",
  ];
  const [pathTypeList, setPathTypeList] = useState([
    "education",
    "career",
    "immigration",
  ]);
  const [degreeList, setDegreeList] = useState([
    "Bachelor",
    "Master",
    "Diploma",
  ]);
  const [universityList, setUniversityList] = useState([]);
  const [pathStatusList, setPathStatusList] = useState([
    "active",
    "inactive",
  ]);
  // addpath dropdown
  const [pathTypeDropdown, setPathTypeDropdown] = useState(false);
  const [pathGradeDropdown, setPathGradeDropdown] = useState(false);
  const [pathGradePointDropdown, setPathGradePointDropdown] = useState(false);
  const [pathCurriculumDropdown, setPathCurriculumDropdown] = useState(false);
  const [pathStreamDropdown, setPathStreamDropdown] = useState(false);
  const [pathFinancialDropdown, setPathFinancialDropdown] = useState(false);
  const [pathUniversityDropdown, setPathUniversityDropdown] = useState(false);
  const [pathPersonalityDropdown, setPathPersonalityDropdown] = useState(false);
  const [pathDestinationDropdown, setPathDestinationDropdown] = useState(false);
  const [pathStatusDropdown, setPathStatusDropdown] = useState(false);
  // addpath selected
  const [pathTypeSelected, setPathTypeSelected] = useState(false);
  const [pathGradeSelected, setPathGradeSelected] = useState(false);
  const [pathGradePointSelected, setPathGradePointSelected] = useState(false);
  const [pathCurriculumSelected, setPathCurriculumSelected] = useState(false);
  const [pathStreamSelected, setPathStreamSelected] = useState(false);
  const [pathFinancialSelected, setPathFinancialSelected] = useState(false);
  const [pathUniversitySelected, setPathUniversitySelected] = useState(false);
  const [pathPersonalitySelected, setPathPersonalitySelected] = useState(false);
  const [pathDestinationSelected, setPathDestinationSelected] = useState(false);
  const [pathStatusSelected, setPathStatusSelected] = useState(false);
  // addpath values
  const [pathTypeVal, setpathTypeVal] = useState();
  const [pathNameVal, setPathNameVal] = useState();
  const [pathDescriptionVal, setPathDescriptionVal] = useState();
  const [pathCurrentCoordinatesVal, setPathCurrentCoordinatesVal] = useState();
  const [pathFutureCoordinatesVal, setPathFutureCoordinatesVal] = useState();
  const [pathProgramVal, setPathProgramVal] = useState();
  const [pathUniversityVal, setPathUniversityVal] = useState(null);
  const [pathDestinationVal, setPathDestinationVal] = useState();
  const [universityLoading, setUniversityLoading] = useState(true);
  const [pathStatusVal, setPathStatusVal] = useState();
  const [localRefresh, setLocalRefresh] = useState(false);
  const [universitySearch, setUniversitySearch] = useState('');

  let navigate = useNavigate();

  //users data
  const [crmUserData, setCrmUserData] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);

  //clients data
  const [crmClientData, setCrmClientData] = useState([]);
  const [isClientLoading, setClientLoading] = useState(false);

  const {
    allSteps,
    setAllSteps,
    stepsToggle,
    setStepsToggle,
    pathSteps,
    setPathSteps,
    creatingPath,
    setCreatingPath,
    mypathsMenu,
    setMypathsMenu,
    selectedSteps,
    setSelectedSteps,

    //vault action
    transactionSelected,
    setTransactionSelected,
    setTransactionData,
    setSelectedCoin,
    coinActionEnabled,
    setCoinActionEnabled,
    coinAction,
    setCoinAction,
    selectedCoin,

    // Forex Currency Add Action
    addActionStep,
    setAddActionStep,
    paymentMethodData,
    setPaymentMethodData,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    addForexAmount,
    setAddForexAmount,
    forexPathId,
    setForexPathId,
    forexQuote,
    setForexQuote,
  } = useCoinContextData();

  const [profileId, setProfileId] = useState("");

  // add service new format
  const [serviceTypeList, setServiceTypeList] = useState([
    "distributor",
    "vendor",
    "mentor",
  ]);
  const [serviceAccessList, setServiceAccessList] = useState([
    "Free",
    "Covered under Subscription",
  ]);
  const [serviceCostList, setServiceCostList] = useState(["free", "paid"]);
  const [serviceDiscountList, setServiceDiscountList] = useState([
    "percentage",
    "flat",
    "discounted price",
  ]);
  const [serviceStatusList, setServiceStatusList] = useState([
    "active",
    "inactive",
  ]);
  const [serviceGradeList, setServiceGradeList] = useState([
    "9",
    "10",
    "11",
    "12",
  ]);
  const [serviceFinancialList, setServiceFinancialList] = useState([
    "0-25L",
    "25-75L",
    "75L-3CR",
    "3CR+",
  ]);
  const [serviceStreamList, setServiceStreamList] = useState([
    "MPC",
    "BIPC",
    "CEC",
    "HEC",
    "MEC",
  ]);
  const [serviceStepList, setServiceStepList] = useState([
    "Micro",
    "Macro",
    "Nano",
  ]);
  const [serviceIterationList, setServiceIterationList] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "Unlimited",
  ]);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(false);
  const [serviceAccessSelected, setServiceAccessSelected] = useState(false);
  const [serviceCostSelected, setServiceCostSelected] = useState(false);
  const [serviceDiscountSelected, setServiceDiscountSelected] = useState(false);
  const [serviceStatusSelected, setServiceStatusSelected] = useState(false);
  const [serviceGradeSelected, setServiceGradeSelected] = useState(false);
  const [serviceFinancialSelected, setServiceFinancialSelected] =
    useState(false);
  const [serviceStreamSelected, setServiceStreamSelected] = useState(false);
  const [serviceStepSelected, setServiceStepSelected] = useState(false);
  const [serviceIterationSelected, setServiceIterationSelected] =
    useState(false);
  // addservice dropdown
  const [serviceTypeDropdown, setServiceTypeDropdown] = useState(false);
  const [serviceAccessDropdown, setServiceAccessDropdown] = useState(false);
  const [serviceCostDropdown, setServiceCostDropdown] = useState(false);
  const [serviceDiscountDropdown, setServiceDiscountDropdown] = useState(false);
  const [serviceStatusDropdown, setServiceStatusDropdown] = useState(false);
  const [serviceGradeDropdown, setServiceGradeDropdown] = useState(false);
  const [serviceFinancialDropdown, setServiceFinancialDropdown] =
    useState(false);
  const [serviceStreamDropdown, setServiceStreamDropdown] = useState(false);
  const [serviceStepDropdown, setServiceStepDropdown] = useState(false);
  const [serviceIterationDropdown, setServiceIterationDropdown] =
    useState(false);
  //addservice value
  const [serviceNameVal, setServiceNameVal] = useState();
  const [serviceDescriptionVal, setServiceDescriptionVal] = useState();
  const [typeVal, setTypeVal] = useState();
  const [accessVal, setAccessVal] = useState();
  const [goalVal, setGoalVal] = useState();
  const [costVal, setCostVal] = useState();
  const [priceVal, setPriceVal] = useState();
  const [discountVal, setDiscountVal] = useState();
  const [discountInputVal, setDiscountInputVal] = useState();
  const [durationVal, setDurationVal] = useState();
  const [featureVal, setFeatureVal] = useState();
  const [statusVal, setStatusVal] = useState();
  const [gradeVal, setGradeVal] = useState([]);
  const [finVal, setFinVal] = useState([]);
  const [streamVal, setStreamVal] = useState([]);
  const [outcomeVal, setOutcomeVal] = useState();
  const [stepVal, setStepVal] = useState();
  const [iterationVal, setIterationVal] = useState();
  // edit service
  const [editService, setEditService] = useState({});

  // add step new format
  const [macroStepTypeList, setMacroStepTypeList] = useState(["free", "paid"]);
  const [microStepTypeList, setMicroStepTypeList] = useState(["free", "paid"]);
  const [nanoStepTypeList, setNanoStepTypeList] = useState(["free", "paid"]);
  const stepStatusList = ["active", "inactive"];
  const [pathList, setPathList] = useState([]);
  const [macroServiceList, setMacroServiceList] = useState([]);
  const [microServiceList, setMicroServiceList] = useState([]);
  const [nanoServiceList, setNanoServiceList] = useState([]);
  // addstep selected
  const [generalMapPathSelected, setGeneralMapPathSelected] = useState(false);
  const [macroServiceSelected, setMacroServiceSelected] = useState(false);
  const [microServiceSelected, setMicroServiceSelected] = useState(false);
  const [nanoServiceSelected, setNanoServiceSelected] = useState(false);
  // addstep dropdown
  const [generalMapPathDropDown, setGeneralMapPathDropdown] = useState(false);
  const [macroStepTypeDropdown, setMacroStepTypeDropdown] = useState(false);
  const [macroMapServiceDropDown, setMacroMapServiceDropDown] = useState(false);
  const [microStepTypeDropdown, setMicroStepTypeDropdown] = useState(false);
  const [microMapServiceDropDown, setMicroMapServiceDropDown] = useState(false);
  const [nanoStepTypeDropdown, setNanoStepTypeDropdown] = useState(false);
  const [nanoMapServiceDropDown, setNanoMapServiceDropDown] = useState(false);
  const [stepStatusDropdown, setStepStatusDropdown] = useState(false);
  // addstep values
  const [stepNameVal, setStepNameVal] = useState();
  const [stepOrderVal, setStepOrderVal] = useState();
  const [macroStepNameVal, setMacroStepNameVal] = useState();
  const [microStepNameVal, setMicroStepNameVal] = useState();
  const [nanoStepNameVal, setNanoStepNameVal] = useState();
  const [macroStepDescriptionVal, setMacroStepDescriptionVal] = useState();
  const [microStepDescriptionVal, setMicroStepDescriptionVal] = useState();
  const [nanoStepDescriptionVal, setNanoStepDescriptionVal] = useState();
  const [macroStepLengthVal, setMacroStepLengthVal] = useState();
  const [microStepLengthVal, setMicroStepLengthVal] = useState();
  const [nanoStepLengthVal, setNanoStepLengthVal] = useState();
  const [macroStepChanceVal, setMacroStepChanceVal] = useState();
  const [microStepChanceVal, setMicroStepChanceVal] = useState();
  const [nanoStepChanceVal, setNanoStepChanceVal] = useState();
  const [stepStatusVal, setStepStatusVal] = useState();
  const [macroStepTypeVal, setMacroStepTypeVal] = useState();
  const [microStepTypeVal, setMicroStepTypeVal] = useState();
  const [nanoStepTypeVal, setNanoStepTypeVal] = useState();
  const [selectedPathVal, setSelectedPathVal] = useState();
  const [macroServiceVal, setMacroServiceVal] = useState([]);
  const [microServiceVal, setMicroServiceVal] = useState([]);
  const [nanoServiceVal, setNanoServiceVal] = useState([]);
  const [mapPathSearchTerm, setMapPathSearchTerm] = useState('');
  const [macroServiceSearchTerm, setMacroServiceSearchTerm] = useState('');
  const [microServiceSearchTerm, setMicroServiceSearchTerm] = useState('');
  const [nanoServiceSearchTerm, setNanoServiceSearchTerm] = useState('');

  //upload part starts here

  const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
  const emailDev = "rahulrajsb@outlook.com"; // email of the developer.
  const userDetails = JSON.parse(localStorage.getItem("user"));

  // console.log(userDetails, 'userDetails');

  const handleGrade = (item) => {
    if (grade.some((o) => o.grade === item)) {
      // If the grade is already selected, remove it
      setGrade(grade.filter((o) => o.grade !== item));
    } else {
      // If the grade is not selected, add it
      setGrade([...grade, { grade: item }]);
    }
  };

  const handleGradeAvg = (item) => {
    if (gradeAvg.some((o) => o.grade_avg === item)) {
      // If the gradeAvg is already selected, remove it
      setGradeAvg(gradeAvg.filter((o) => o.grade_avg !== item));
    } else {
      // If the gradeAvg is not selected, add it
      setGradeAvg([...gradeAvg, { grade_avg: item }]);
    }
  };

  const handleCurriculum = (item) => {
    if (curriculum.some((o) => o.curriculum === item)) {
      // If the curriculum is already selected, remove it
      setCurriculum(curriculum.filter((o) => o.curriculum !== item));
    } else {
      // If the curriculum is not selected, add it
      setCurriculum([...curriculum, { curriculum: item }]);
    }
  };

  const handleStream = (item) => {
    if (stream.some((o) => o.stream === item)) {
      // If the stream is already selected, remove it
      setStream(stream.filter((o) => o.stream !== item));
    } else {
      // If the stream is not selected, add it
      setStream([...stream, { stream: item }]);
    }
  };

  const handleFinance = (item) => {
    if (finance.some((o) => o.finance === item)) {
      // If the finance is already selected, remove it
      setFinance(finance.filter((o) => o.finance !== item));
    } else {
      // If the finance is not selected, add it
      setFinance([...finance, { finance: item }]);
    }
  };

  const handlePersonality = (item) => {
    setPersonality(item);
  };

  useEffect(() => {
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        // console.log(result, "all paths fetched");
        setBackupPathList(result);
      });
  }, []);

  const addBackupPath = (backupPathId, selectedStepId) => {
    // console.log(pathSteps, "kjedkjweld");

    pathSteps.the_ids.map((item) => {
      if (item.step_id === selectedStepId) {
        item.backup_pathId = backupPathId;
      }
    });
    setShowBackupPathList(false);
    // console.log(selectedSteps, "lkashclkweoiuk");
    // const found = pathSteps.find((element) => element._id === backupPathId);
  };

  useEffect(() => {
    if (userDetails) {
      setPathSteps((prev) => {
        return {
          ...prev,
          email: userDetails?.user?.email,
        };
      });
    }
  }, []);

  useEffect(() => {
    handleFollowerPerAccountants();
    handleGetCurrencies();
    // setaccsideNav("CRM")
    resetpop();
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails === null || userDetails === undefined) {
      navigate("/login");
    }
  }, []);

  const uploadCoverImage = async (file) => {
    setIsUploadLoading(true);

    const fileName = `${new Date().getTime()}${file.name.substr(
      file.name.lastIndexOf(".")
    )}`;

    const formData = new FormData();
    const newfile = renameFile(file, fileName);
    formData.append("files", newfile);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await axios.post(
      `https://insurance.apimachine.com/insurance/general/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (data?.length > 0) {
      console.log(data[0], "dfile name upload");
      setCoverImageS3url(data[0]?.urlName);
      setIsUploadLoading(false);
      return data[0]?.urlName;
    } else {
      // setIsUploadLoading(false);
      console.log("error in uploading image");
    }
  };

  const uploadCoverImage1 = async (file) => {
    setIsUploadLoading(true);

    const fileName = `${new Date().getTime()}${file.name.substr(
      file.name.lastIndexOf(".")
    )}`;

    const formData = new FormData();
    const newfile = renameFile(file, fileName);
    formData.append("files", newfile);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    // console.log(jwts, "lkjkswedcf");
    let { data } = await axios.post(
      `https://insurance.apimachine.com/insurance/general/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (data?.length > 0) {
      console.log(data[0], "dfile name upload1");
      setEditService((prev) => {
        return {
          ...prev,
          icon: data[0]?.urlName,
        };
      });
      setIsUploadLoading(false);
      return data[0]?.urlName;
    } else {
      // setIsUploadLoading(false);
      console.log("error in uploading image1");
    }
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  //upload end here

  const handleFollowerPerAccountants = () => {
    setIsLoading(true);
    let mailId = userDetails?.user?.email;
    GetFollowersPerAccount(mailId)
      .then((res) => {
        let result = res?.data;
        if (result?.status) {
          setfollowCount(result?.data?.count);
          setfollowData(result?.data?.followers);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "jkjkk");
        setIsLoading(false);
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleAllCustomerLicenses = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    setIsPurchaseLoading(true);
    GetAllCustomerLicenses(userDetails.user.email)
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setPurchaseData(result.licenses);
          setIsPurchaseLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setIsPurchaseLoading(false);
      });
  };

  const handleCategories = () => {
    setIsCatLoading(true);
    GetCategoriesAcc()
      .then((res) => {
        let result = res.data;
        if (result.status) {
          setcategoriesData(result.categories);
          setIsCatLoading(false);
        }
      })
      .catch((err) => {
        setIsCatLoading(false);
        console.log(err, "jkjkk");
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleGetCurrencies = () => {
    setIsCurrencies(true);
    GetAllCurrencies()
      .then((res) => {
        let result = res?.data;
        if (result?.status) {
          setallCurrencies(result?.coins);
          setIsCurrencies(false);
        }
      })
      .catch((err) => {
        console.log(err, "jkjkk");
        setIsCurrencies(false);
        toast.error("Something Went Wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const resetpop = () => {
    setispopular(false);
    setpstep(1);
    setbillingType("");
    setselectNew("");
    setselectCategory("");
    setcategoriesData([]);
    setSearch("");
    setSelectedCurrency({});
    setServiceNameInput("");
    setServiceCodeInput("");
    setProductLabel("");
    setServiceTagline("");
    setServiceDescription("");
    setfirstMonthPrice("");
    setmonthlyPrice("");
    setgracePeriod("");
    setsecondChargeAttempt("");
    setthirdChargeAttempt("");
    setfirstMonthPrice("");
    setmonthlyPrice("");
    setgracePeriod("");
    setsecondChargeAttempt("");
    setthirdChargeAttempt("");
    setCoverImageS3url("");
    setImage(null);
    setPathSteps({
      email: userDetails?.user?.email,
      nameOfPath: "",
      description: "",
      length: "",
      path_type: "",
      the_ids: [],
      destination_institution: "",
    });
    setSelectedSteps([]);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setSearchCurrency("");

    // addservice new form fields
    setServiceTypeSelected(false);
    setServiceAccessSelected(false);
    setServiceCostSelected(false);
    setServiceDiscountSelected(false);
    setServiceStatusSelected(false);
    setServiceGradeSelected(false);
    setServiceFinancialSelected(false);
    setServiceStreamDropdown(false);
    setServiceStepSelected(false);
    setServiceIterationSelected(false);

    setServiceTypeDropdown(false);
    setServiceAccessDropdown(false);
    setServiceCostDropdown(false);
    setServiceDiscountDropdown(false);
    setServiceStatusDropdown(false);
    setServiceGradeDropdown(false);
    setServiceFinancialDropdown(false);
    setServiceStreamDropdown(false);
    setServiceStepDropdown(false);
    setServiceIterationDropdown(false);

    setServiceNameVal("");
    setServiceDescriptionVal("");
    setTypeVal("");
    setAccessVal("");
    setGoalVal("");
    setCostVal("");
    setPriceVal("");
    setDiscountVal("");
    setDiscountInputVal("");
    setDurationVal("");
    setFeatureVal("");
    setStatusVal("");
    setGradeVal([]);
    setFinVal([]);
    setStreamVal([]);
    setOutcomeVal("");
    setStepVal("");
    setIterationVal("");

    // addpath new form fields
    setPathTypeDropdown(false);
    setPathGradeDropdown(false);
    setPathGradePointDropdown(false);
    setPathCurriculumDropdown(false);
    setPathStreamDropdown(false);
    setPathFinancialDropdown(false);
    setPathUniversityDropdown(false);
    setPathPersonalityDropdown(false);
    setPathDestinationDropdown(false);
    setPathStatusDropdown(false);

    setPathTypeSelected(false);
    setPathGradeSelected(false);
    setPathGradePointSelected(false);
    setPathCurriculumSelected(false);
    setPathStreamSelected(false);
    setPathFinancialSelected(false);
    setPathUniversitySelected(false);
    setPathPersonalitySelected(false);
    setPathDestinationSelected(false);
    setPathStatusSelected(false);

    setpathTypeVal('');
    setPathNameVal('');
    setPathDescriptionVal('');
    setPathCurrentCoordinatesVal('');
    setPathFutureCoordinatesVal('');
    setPathProgramVal('');
    setPathUniversityVal(null);
    setPathDestinationVal('');
    setUniversityLoading(false);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setPathStatusVal('');
    setUniversityList([]);
    setUniversitySearch('');

    // addstep new form fields
    setGeneralMapPathDropdown(false);
    setMacroStepTypeDropdown(false);
    setMacroMapServiceDropDown(false);
    setMicroStepTypeDropdown(false);
    setMicroMapServiceDropDown(false);
    setNanoStepTypeDropdown(false);
    setNanoMapServiceDropDown(false);
    setStepStatusDropdown(false);

    setGeneralMapPathSelected(false);
    setMacroServiceSelected(false);
    setMicroServiceSelected(false);
    setNanoServiceSelected(false);

    setStepNameVal('');
    setStepOrderVal('');
    setMacroStepNameVal('');
    setMicroStepNameVal('');
    setNanoStepNameVal('');
    setMacroStepDescriptionVal('');
    setMicroStepDescriptionVal('');
    setNanoStepDescriptionVal('');
    setMacroStepLengthVal('');
    setMicroStepLengthVal('');
    setNanoStepLengthVal('');
    setMacroStepChanceVal('');
    setMicroStepChanceVal('');
    setNanoStepChanceVal('');
    setMacroStepTypeVal("");
    setMicroStepTypeVal("");
    setNanoStepTypeVal("");
    setSelectedPathVal('');
    setMacroServiceVal([]);
    setMicroServiceVal([]);
    setNanoServiceVal([]);
    setMapPathSearchTerm('');
    setMacroServiceSearchTerm('');
    setMicroServiceSearchTerm('');
    setNanoServiceSearchTerm('');
    setStepStatusVal('');
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleServicesForLogged = (value) => {
    setIsServicesAcc(true);
    GetLogServices(value)
      .then((res) => {
        // console.log(res?.data, "all services");
        let result = res?.data;
        if (result?.data) {
          setservicesAcc(result?.data);
          setIsServicesAcc(false);
        }
      })
      .catch((err) => {
        console.log(err, "error in GetLogServices");
        setIsServicesAcc(false);
      });
  };

  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleFileInputChange = (e) => {
    setImage(e.target.files[0]);
    uploadCoverImage(e.target.files[0]);
  };

  const handleFileInputChange1 = (e) => {
    setImage(e.target.files[0]);
    uploadCoverImage1(e.target.files[0]);
  };

  const myTimeoutService = () => {
    setTimeout(reloadService, 3000);
  };

  function reloadService() {
    setpstep(1);
    setispopular(false);
    setaccsideNav("My Services");
    setservicesMenu("Services");
    handleServicesForLogged(userDetails.user.email);

    // addservice new form fields
    setServiceTypeSelected(false);
    setServiceAccessSelected(false);
    setServiceCostSelected(false);
    setServiceDiscountSelected(false);
    setServiceStatusSelected(false);
    setServiceGradeSelected(false);
    setServiceFinancialSelected(false);
    setServiceStreamDropdown(false);
    setServiceStepSelected(false);
    setServiceIterationSelected(false);

    setServiceTypeDropdown(false);
    setServiceAccessDropdown(false);
    setServiceCostDropdown(false);
    setServiceDiscountDropdown(false);
    setServiceStatusDropdown(false);
    setServiceGradeDropdown(false);
    setServiceFinancialDropdown(false);
    setServiceStreamDropdown(false);
    setServiceStepDropdown(false);
    setServiceIterationDropdown(false);

    setServiceNameVal("");
    setServiceDescriptionVal("");
    setTypeVal("");
    setAccessVal("");
    setGoalVal("");
    setCostVal("");
    setPriceVal("");
    setDiscountVal("");
    setDiscountInputVal("");
    setDurationVal("");
    setFeatureVal("");
    setStatusVal("");
    setGradeVal([]);
    setFinVal([]);
    setStreamVal([]);
    setOutcomeVal("");
    setStepVal("");
    setIterationVal("");
  }

  const handleFinalSubmit = () => {
    setIsSubmit(true);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let objmonthly = {
      email: userDetails.user.email,
      token: userDetails.token,
      product_code: serviceCodeInput,
      product_name: serviceNameInput,
      product_icon: coverImageS3url,
      revenue_account: userDetails.user.email,
      client_app: "naavi",
      product_category_code: "CoE",
      sub_category_code: "",
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: serviceTagline,
      full_description: serviceDescription,
      first_purchase: {
        price: firstMonthPrice !== "" ? parseFloat(firstMonthPrice) : 0,
        coin: selectedCurrency.coinSymbol,
      },
      billing_cycle: {
        monthly: {
          price:
            billingType === "One Time"
              ? firstMonthPrice !== ""
                ? parseFloat(firstMonthPrice)
                : 0
              : monthlyPrice !== ""
              ? parseFloat(monthlyPrice)
              : 0,
          coin: selectedCurrency.coinSymbol,
        },
      },
      grace_period:
        billingType === "One Time"
          ? 0
          : gracePeriod !== ""
          ? parseFloat(gracePeriod)
          : 0,
      first_retry:
        billingType === "One Time"
          ? 0
          : secondChargeAttempt !== ""
          ? parseFloat(secondChargeAttempt)
          : 0,
      second_retry:
        billingType === "One Time"
          ? 0
          : thirdChargeAttempt !== ""
          ? parseFloat(thirdChargeAttempt)
          : 0,
      staking_allowed: false,
      staking_details: {},
    };

    let objone = {
      email: userDetails.user.email,
      token: userDetails.token,
      product_code: serviceCodeInput,
      product_name: serviceNameInput,
      product_icon: coverImageS3url,
      revenue_account: userDetails.user.email,
      client_app: "naavi",
      product_category_code: "CoE",
      sub_category_code: "",
      custom_product_label: productLabel,
      points_creation: false,
      sub_text: serviceTagline,
      full_description: serviceDescription,
      first_purchase: {
        price: firstMonthPrice !== "" ? parseFloat(firstMonthPrice) : 0,
        coin: selectedCurrency.coinSymbol,
      },
      billing_cycle: {
        lifetime: {
          price:
            billingType === "One Time"
              ? firstMonthPrice !== ""
                ? parseFloat(firstMonthPrice)
                : 0
              : monthlyPrice !== ""
              ? parseFloat(monthlyPrice)
              : 0,
          coin: selectedCurrency.coinSymbol,
        },
      },
      grace_period:
        billingType === "One Time"
          ? 0
          : gracePeriod !== ""
          ? parseFloat(gracePeriod)
          : 0,
      first_retry:
        billingType === "One Time"
          ? 0
          : secondChargeAttempt !== ""
          ? parseFloat(secondChargeAttempt)
          : 0,
      second_retry:
        billingType === "One Time"
          ? 0
          : thirdChargeAttempt !== ""
          ? parseFloat(thirdChargeAttempt)
          : 0,
      staking_allowed: false,
      staking_details: {},
    };

    let obj = billingType === "One Time" ? objone : objmonthly;
    CreatePopularService(obj)
      .then((res) => {
        let result = res.data;
        if (result.status) {
          myTimeoutService();
          setpstep(7);
          setbillingType("");
          setselectNew("");
          setselectCategory("");
          setcategoriesData([]);
          setSearch("");
          setSelectedCurrency({});
          setServiceNameInput("");
          setServiceCodeInput("");
          setProductLabel("");
          setServiceTagline("");
          setServiceDescription("");
          setfirstMonthPrice("");
          setmonthlyPrice("");
          setgracePeriod("");
          setsecondChargeAttempt("");
          setthirdChargeAttempt("");
          setfirstMonthPrice("");
          setmonthlyPrice("");
          setgracePeriod("");
          setsecondChargeAttempt("");
          setthirdChargeAttempt("");
          setIsSubmit(false);
          setCoverImageS3url("");
          setImage(null);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  useEffect(() => {
    resetpop();
    // console.log(
    //   accsideNav == "My Services" && servicesMenu == "Services",
    //   "uyuyuy"
    // );
    if (accsideNav == "CRM" && crmMenu == "Followers") {
      handleFollowerPerAccountants();
    } else if (accsideNav == "CRM" && crmMenu == "Purchases") {
      handleAllCustomerLicenses();
    } else if (accsideNav == "My Services" && servicesMenu == "Services") {
      const userDetails = JSON.parse(localStorage.getItem("user"));
      // console.log(userDetails, "kkk");
      handleServicesForLogged(userDetails.user.email);
    }
  }, [crmMenu, servicesMenu, accsideNav]);

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    setServiceActionEnabled(false);
    setServiceActionStep(1);
    setSelectedService("");
    setUpdatedIcon("");
    setEditService({});
    handleServicesForLogged(userDetails?.user?.email);
  }

  const deleteService = () => {
    setIsloading(true);
    axios
      .delete(
        `https://oldnaavi.apimachine.com/services/delete/${selectedService?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        console.log(result, "delete service response");
        if (result?.status) {
          setIsloading(false);
          setServiceActionStep(3);
          myTimeout();
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error in delete service");
      });
  };

  // edit service fields starts here

  const editServiceFields = () => {
    let obj = editService;

    const areArraysEqualByGrade = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].grade !== array2[i].grade) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByStream = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].stream !== array2[i].stream) {
          return false;
        }
      }
      return true;
    };

    const areArraysEqualByFinance = (array1, array2) => {
      if (array1.length !== array2.length) return false;
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].finance !== array2[i].finance) {
          return false;
        }
      }
      return true;
    };

    if (
      obj?.grade &&
      selectedService?.grade &&
      areArraysEqualByGrade(obj?.grade, selectedService?.grade)
    ) {
      const { grade, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.stream &&
      selectedService?.stream &&
      areArraysEqualByStream(obj?.stream, selectedService.stream)
    ) {
      const { stream, ...rest } = obj;
      obj = rest;
    }

    if (
      obj?.financialSituation &&
      selectedService?.financialSituation &&
      areArraysEqualByFinance(obj?.financialSituation, selectedService?.financialSituation)
    ) {
      const { financialSituation, ...rest } = obj;
      obj = rest;
    }

    // console.log(obj, "final api body");

    setIsloading(true);
    axios
      .put(
        `https://oldnaavi.apimachine.com/services/update/${selectedService?._id}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "editServiceFields result");
        if (result?.status) {
          setIsloading(false);
          setServiceActionStep(7);
          myTimeout();
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error in editServiceFields");
      });
  };

  // edit service fields ends here

  const getAppsforUser = () => {
    setIsfetching(true);
    axios
      .get(
        "https://comms.globalxchange.io/gxb/apps/get"
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((response) => {
        let result = response?.data?.apps;
        // console.log(result, 'getAppsforUser result');
        setUserCreatedApps(result);
        setIsfetching(false);
      })
      .catch((error) => {
        console.log(error, "getAppsforUser error");
      });
  };

  const myTimeout1 = () => {
    setTimeout(reload1, 3000);
  };

  function reload1() {
    setAddCompPlan(false);
    setAddCompPlanStep("step1");
    setUserCreatedApps([]);
    setCompPlanApp("");
    setLevels();
    setInputValues([]);
    setMultiplier([]);
    getWithCompPlan();
    setservicesMenu("With CompPlan");
  }

  const addComplan = () => {
    setAddingComp(true);

    let fixedPayouts = inputValues.map((e, i) => {
      return {
        level: i,
        percentage: e,
      };
    });
    // console.log(fixedPayouts, 'fixedPayouts');

    let numValues = multiplier.map((e, i) => {
      return {
        level: i,
        numerator: e,
      };
    });
    // console.log(numValues, 'numValues');

    let obj = {
      email: userDetails?.user?.email,
      token: userDetails?.token,
      app_code: compPlanApp,
      product_id: selectedService?.product_id,
      comp_plan_id: "comp4",
      fixed_payouts: fixedPayouts,
      numeratorValues: numValues,
    };
    // console.log(obj, 'object');

    addCompPlanFunction(obj).then((response) => {
      let result = response?.data;
      console.log(result);
      if (result?.status) {
        setAddingComp(false);
        setAddCompPlanStep("step6");
        myTimeout1();
      } else {
        setAddingComp(false);
      }
    });
  };

  const styles = {
    opacity: "0.25",
    pointerEvents: "none",
  };
  const applyStyle = (condition) => (condition ? {} : styles);

  function spreadFunc(value) {
    if (value.length > 0) {
      const result = value.reduce((acc, val) => acc && val);
      // console.log(result, 'resultttt');
      return result;
    }
  }

  const handleLevelChange = (event) => {
    const newLevel = parseInt(event.target.value);
    if (newLevel >= 1) {
      setLevels(newLevel);
      setInputValues(Array(newLevel).fill(""));
      setMultiplier(Array(newLevel).fill(""));
    }
  };

  const handleInputChange = (index, event, funcValue, func) => {
    const newInputValues = [...funcValue];
    newInputValues[index] = event.target.value;
    // console.log(newInputValues, 'newInputValues');
    func(newInputValues);
  };

  const renderLevelInputs = (funcValue, func) => {
    return funcValue.map((value, index) => (
      <div className="each-action1" key={index}>
        <div className="partition">
          <div>{index}</div>
          <input
            type="number"
            value={value}
            onChange={(event) =>
              handleInputChange(index, event, funcValue, func)
            }
            placeholder="0.00%"
          />
        </div>
      </div>
    ));
  };

  const getWithCompPlan = () => {
    setGettingData(true);
    let obj = {
      product_creator: userDetails?.user?.email,
    };
    axios
      .post(
        `https://comms.globalxchange.io/gxb/product/price/with/fees/get`,
        obj
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((response) => {
        let result = response?.data?.products;
        setWithCompPlanData(result);
        setGettingData(false);
      })
      .catch((error) => {
        console.log(error, "error in getWithCompPlan");
      });
  };

  useEffect(() => {
    getWithCompPlan();
  }, []);

  useEffect(() => {
    let email = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/steps/get?email=${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "all steps fetched");
        setAllSteps(result);
      })
      .catch((error) => {
        console.log(error, "error in fetching all steps");
      });
  }, []);

  // const removeStep = (stepId) => {
  //   const updatedSelectedSteps = selectedSteps.filter(
  //     (step) => step._id !== stepId
  //   );
  //   setSelectedSteps(updatedSelectedSteps);

  //   const updatedStepIds = pathSteps?.step_ids?.filter((id) => id !== stepId);
  //   setPathSteps({
  //     ...pathSteps,
  //     step_ids: updatedStepIds,
  //   });
  // };

  const removeStep = (stepId) => {
    // Remove the step from selectedSteps
    const updatedSelectedSteps = selectedSteps.filter(
      (step) => step._id !== stepId
    );
    setSelectedSteps(updatedSelectedSteps);

    // Remove the step_id from pathSteps
    const updatedTheIds = pathSteps?.the_ids?.filter(
      (obj) => obj.step_id !== stepId
    );
    setPathSteps({
      ...pathSteps,
      the_ids: updatedTheIds,
    });
  };

  useEffect(() => {
    setIsUserLoading(true);
    let body = { email: userDetails?.user?.email, status: "active" };
    axios
      .post("https://oldnaavi.apimachine.com/userpaths/getcmrusers", body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "crm users data");
        setIsUserLoading(false);
        setCrmUserData(result);
      })
      .catch((error) => {
        console.log(error, "error in fetching crm users data");
      });
  }, []);

  useEffect(() => {
    let email = userDetails?.user?.email;
    setClientLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/user/product/purchases?email=${email}`
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((response) => {
        let result = response?.data?.productPurchases;
        // console.log(result, "brands crm clients data");
        setClientLoading(false);
        setCrmClientData(result);
      })
      .catch((error) => {
        console.log(error, "error in fetching crm clients data");
      });
  }, []);

  function customDateFormat(date) {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "long" });
      const year = date.getFullYear();

      const suffix =
        day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";

      const customFormattedDate = `${month} ${day}${suffix} ${year}`;
      return customFormattedDate;
    } else {
      console.log("Invalid date object");
    }
  }

  // coin action
  const resetCoinAction = () => {
    setCoinActionEnabled(false);
    setCoinAction(["Menu"]);
    setAddActionStep(1);
    setSelectedCoin({});
    setProfileId("");
    setPaymentMethodData([]);
    setSelectedPaymentMethod("");
    setForexPathId("");
    setAddForexAmount("");
    setForexQuote([]);
  };

  // get profile id
  useEffect(() => {
    let email = userDetails?.user?.email;
    if (coinAction?.includes("Add") && addActionStep === 1) {
      axios
        .get(
          `https://comms.globalxchange.io/user/details/get?email=${email}`
          // {
          //   headers: {
          //     Authorization: `Bearer ${localStorage.getItem("token")}`,
          //   },
          // }
        )
        .then((res) => {
          const { data } = res;
          if (data?.status) {
            // console.log(data?.user["naavi_profile_id"], "profile id");
            setProfileId(data?.user["naavi_profile_id"]);
          }
        });
    }
  }, [coinAction, addActionStep]);

  // get payment methods for forex add action
  useEffect(() => {
    if (coinAction?.includes("Add") && selectedCoin?.coinSymbol) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?select_type=fund&to_currency=${selectedCoin?.coinSymbol}&from_currency=${selectedCoin?.coinSymbol}&country=India&banker=shorupan@indianotc.com`
          // {
          //   headers: {
          //     Authorization: `Bearer ${localStorage.getItem("token")}`,
          //   },
          // }
        )
        .then((response) => {
          let result = response?.data?.pathData?.paymentMethod;
          // console.log(result, "payment methods result");
          setPaymentMethodData(result);
        })
        .catch((error) => {
          console.log(error, "error in fetching payment methods");
        });
    }
  }, [coinAction, selectedCoin]);

  const getPathId = () => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?from_currency=${selectedCoin?.coinSymbol}&to_currency=${selectedCoin?.coinSymbol}&select_type=fund&banker=shorupan@indianotc.com&paymentMethod=${selectedPaymentMethod}`
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((response) => {
        let result = response?.data?.paths;
        // console.log(result, "getPathId result");
        if (result?.length > 0) {
          setForexPathId(result[0]?.path_id);
          // console.log(result[0]?.path_id, "pathId");
        }
      })
      .catch((error) => {
        console.log(error, "error in getPathId");
      });
  };

  const onBlur = (e) => {
    const float = parseFloat(e.target.value);
    setAddForexAmount(float.toFixed(2));
  };

  const getQuote = () => {
    let obj = {
      token: userDetails?.token,
      email: userDetails?.user?.email,
      app_code: "naavi",
      profile_id: profileId,
      coin_purchased: selectedCoin?.coinSymbol,
      purchased_from: selectedCoin?.coinSymbol,
      from_amount: addForexAmount,
      stats: true,
      identifier: `Add ${addForexAmount} ${selectedCoin?.coinSymbol} Via ${selectedPaymentMethod}`,
      path_id: forexPathId,
    };

    axios
      .post(
        `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
        obj
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "getQuote result");
        if (result?.status) {
          setForexQuote(result);
          setAddActionStep(3);
        }
      })
      .catch((error) => {
        console.log(error, "error in getQuote");
      });
  };

  // addservice new
  const handleGradeForAddService = (item) => {
    if (gradeVal.some((o) => o.grade === item)) {
      // If the grade is already selected, remove it
      setGradeVal(gradeVal.filter((o) => o.grade !== item));
    } else {
      // If the grade is not selected, add it
      setGradeVal([...gradeVal, { grade: item }]);
    }
  };

  const handleFinancialForAddService = (item) => {
    if (finVal.some((o) => o.finance === item)) {
      // If the financial is already selected, remove it
      setFinVal(finVal.filter((o) => o.finance !== item));
    } else {
      // If the financial is not selected, add it
      setFinVal([...finVal, { finance: item }]);
    }
  };

  const handleStreamForAddService = (item) => {
    if (streamVal.some((o) => o.stream === item)) {
      // If the stream is already selected, remove it
      setStreamVal(streamVal.filter((o) => o.stream !== item));
    } else {
      // If the stream is not selected, add it
      setStreamVal([...streamVal, { stream: item }]);
    }
  };

  const handleAddNewService = () => {
    // console.log(gradeVal, 'grade values');
    // console.log(finVal, 'financial values');
    // console.log(streamVal, 'stream values');
    setIsSubmit(true);
    let obj = {
      email: userDetails?.user?.email,
      nameOfService: serviceNameVal,
      icon: coverImageS3url,
      description: serviceDescriptionVal,
      serviceProvider: typeVal,
      access: accessVal,
      goal: goalVal,
      gradeData: gradeVal,
      financialData: finVal,
      stream: streamVal,
      cost: costVal,
      price: priceVal,
      discountType: discountVal,
      discountAmount: discountInputVal,
      duration: durationVal,
      features: featureVal,
      status: statusVal,
      outcome: outcomeVal,
      type: stepVal,
      iterations: iterationVal,
    };

    axios
      .post(`https://oldnaavi.apimachine.com/services/add`, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data;
        // console.log(result, "add new service response");
        if (result?.status) {
          setIsSubmit(false);
          setpstep(5);
          myTimeoutService();
        }
      })
      .catch((error) => {
        console.log(error, "error in adding a new service");
      });
  };

  const handleMultipleInputArray = (item, array, object) => {
    // Check if the item is already selected

    const isSelected = editService[array]?.filter((itemm) => {
      return itemm[object] === item;
    });

    // If it's already selected, remove it from the array, otherwise add it
    if (Array.isArray(isSelected) && isSelected?.length > 0) {
      setEditService((prev) => {
        return {
          ...prev,
          [array]: Array.isArray(prev[array])
            ? prev[array]?.filter(
                (selectedItem) => selectedItem[object] !== item
              )
            : [{ [object]: item }],
        };
      });
    } else {
      setEditService((prev) => {
        return {
          ...prev,
          [array]: Array.isArray(prev[array])
            ? [
                ...prev[array],
                {
                  [object]: item,
                },
              ]
            : [{ [object]: item }],
        };
      });
    }
  };

  // addstep new
  const getAllPaths = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(`https://oldnaavi.apimachine.com/paths/get?email=${mailId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getAllPaths result');
        setPathList(result);
      })
      .catch((error) => {
        console.log(error, "error in getAllPaths");
      });
  };

  const getServiceForMacro = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Macro&cost=${macroStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForMacro result');
        setMacroServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForMacro");
      });
  };

  const getServiceForMicro = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Micro&cost=${microStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForMicro result');
        setMicroServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForMicro");
      });
  };

  const getServiceForNano = () => {
    let mailId = userDetails?.user?.email;
    axios
      .get(
        `https://oldnaavi.apimachine.com/services/get?email=${mailId}&type=Nano&cost=${nanoStepTypeVal}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, 'getServiceForNano result');
        setNanoServiceList(result);
      })
      .catch((error) => {
        console.log(error, "error in getServiceForNano");
      });
  };

  const handleMacroServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected macro service ids');
    if (macroServiceVal.some((o) => o?.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setMacroServiceVal(macroServiceVal.filter((o) => o?.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setMacroServiceVal([...macroServiceVal, item]);
    }
  };

  const handleMicroServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected micro service ids');
    if (microServiceVal.some((o) => o.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setMicroServiceVal(microServiceVal.filter((o) => o.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setMicroServiceVal([...microServiceVal, item]);
    }
  };

  const handleNanoServiceForAddStep = (item) => {
    // console.log(item?._id, 'selected nano service ids');
    if (nanoServiceVal.some((o) => o.nameOfService === item?.nameOfService)) {
      // If the service is already selected, remove it
      setNanoServiceVal(nanoServiceVal.filter((o) => o.nameOfService !== item?.nameOfService));
    } else {
      // If the service is not selected, add it
      setNanoServiceVal([...nanoServiceVal, item]);
    }
  };

  useEffect(() => {
    if (ispopular) {
      getAllPaths();
    }

    if (macroStepTypeVal) {
      getServiceForMacro();
    }

    if (microStepTypeVal) {
      getServiceForMicro();
    }

    if (nanoStepTypeVal) {
      getServiceForNano();
    }
  }, [ispopular, macroStepTypeVal, microStepTypeVal, nanoStepTypeVal]);

  const myTimeoutStep = () => {
    setTimeout(reloadStep, 3000);
  };

  function reloadStep() {
    setpstep(1);
    setispopular(false);
    setaccsideNav("Paths");
    setMypathsMenu("Steps");
    setLocalRefresh(!localRefresh);

    // addstep new form fields
    setGeneralMapPathDropdown(false);
    setMacroStepTypeDropdown(false);
    setMacroMapServiceDropDown(false);
    setMicroStepTypeDropdown(false);
    setMicroMapServiceDropDown(false);
    setNanoStepTypeDropdown(false);
    setNanoMapServiceDropDown(false);
    setStepStatusDropdown(false);

    setGeneralMapPathSelected(false);
    setMacroServiceSelected(false);
    setMicroServiceSelected(false);
    setNanoServiceSelected(false);

    setStepNameVal('');
    setStepOrderVal('');
    setMacroStepNameVal('');
    setMicroStepNameVal('');
    setNanoStepNameVal('');
    setMacroStepDescriptionVal('');
    setMicroStepDescriptionVal('');
    setNanoStepDescriptionVal('');
    setMacroStepLengthVal('');
    setMicroStepLengthVal('');
    setNanoStepLengthVal('');
    setMacroStepChanceVal('');
    setMicroStepChanceVal('');
    setNanoStepChanceVal('');
    setMacroStepTypeVal("");
    setMicroStepTypeVal("");
    setNanoStepTypeVal("");
    setSelectedPathVal("");
    setMacroServiceVal([]);
    setMicroServiceVal([]);
    setNanoServiceVal([]);
    setMapPathSearchTerm("");
    setMacroServiceSearchTerm("");
    setMicroServiceSearchTerm("");
    setNanoServiceSearchTerm("");
    setStepStatusVal('');
  }

  const handleNewStep = () => {
    const macroIds = macroServiceVal?.map(obj => obj._id);
    const microIds = microServiceVal?.map(obj => obj._id);
    const nanoIds = nanoServiceVal?.map(obj => obj._id);

    // console.log(macroIds, 'selected macroIds');
    // console.log(microIds, 'selected microIds');
    // console.log(nanoIds, 'selected nanoIds');

    let obj = {
      email: userDetails?.user?.email,
      name: stepNameVal,
      micro_name: microStepNameVal,
      micro_description: microStepDescriptionVal,
      micro_length: microStepLengthVal,
      micro_cost: microStepTypeVal,
      micro_chances: microStepChanceVal,
      microservices: microIds,
      macro_name: macroStepNameVal,
      macro_description: macroStepDescriptionVal,
      macro_length: macroStepLengthVal,
      macro_cost: macroStepTypeVal,
      macro_chances: macroStepChanceVal,
      macroservices: macroIds,
      nano_name: nanoStepNameVal,
      nano_description: nanoStepDescriptionVal,
      nano_length: nanoStepLengthVal,
      nano_cost: nanoStepTypeVal,
      nano_chances: nanoStepChanceVal,
      nanoservices: nanoIds,
      step_order: stepOrderVal,
      path_id: selectedPathVal?._id,
      status: stepStatusVal,
    };

    // console.log(obj, 'addNewStep api body');

    axios.post(`https://oldnaavi.apimachine.com/steps/add`, obj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      let result = response?.data;
      console.log(result, 'handleNewStep result');
      setpstep(14);
      myTimeoutStep();
    }).catch((error) => {
      console.log(error, 'error in handleNewStep');
    })
  };

  // add path new 
  const myTimeoutPath = () => {
    setTimeout(reloadPath, 3000);
  };

  function reloadPath() {
    setpstep(1);
    setispopular(false);
    setaccsideNav("Paths");
    setMypathsMenu("Paths");
    setLocalRefresh(!localRefresh);

    // addpath new form fields
    setPathTypeDropdown(false);
    setPathGradeDropdown(false);
    setPathGradePointDropdown(false);
    setPathCurriculumDropdown(false);
    setPathStreamDropdown(false);
    setPathFinancialDropdown(false);
    setPathUniversityDropdown(false);
    setPathPersonalityDropdown(false);
    setPathDestinationDropdown(false);
    setPathStatusDropdown(false);

    setPathTypeSelected(false);
    setPathGradeSelected(false);
    setPathGradePointSelected(false);
    setPathCurriculumSelected(false);
    setPathStreamSelected(false);
    setPathFinancialSelected(false);
    setPathUniversitySelected(false);
    setPathPersonalitySelected(false);
    setPathDestinationSelected(false);
    setPathStatusSelected(false);

    setpathTypeVal('');
    setPathNameVal('');
    setPathDescriptionVal('');
    setPathCurrentCoordinatesVal('');
    setPathFutureCoordinatesVal('');
    setPathProgramVal('');
    setPathUniversityVal(null);
    setPathDestinationVal('');
    setUniversityLoading(false);
    setGrade([]);
    setGradeAvg([]);
    setCurriculum([]);
    setStream([]);
    setFinance([]);
    setPersonality("");
    setPathStatusVal('');
  }

  const handleAddNewPath = () => {
    setCreatingPath(true);
    let obj = {
      email: userDetails?.user?.email,
      nameOfPath: pathNameVal,
      description: pathDescriptionVal,
      current_coordinates: pathCurrentCoordinatesVal,
      feature_coordinates: pathFutureCoordinatesVal,
      program: pathProgramVal,
      university: [pathUniversityVal?._id],
      path_type: pathTypeVal,
      personality: personality,
      destination_degree: pathDestinationVal,
      status: pathStatusVal,
      grade: grade,
      grade_avg: gradeAvg,
      financialSituation: finance,
      stream: stream,
      curriculum: curriculum,
    };
    // console.log(obj, 'obj');
    axios
      .post(`https://oldnaavi.apimachine.com/paths/add`, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let result = response?.data;
        console.log(result, "handleAddNewPath result");
        if (result?.status) {
          setCreatingPath(false);
          setpstep(9);
          myTimeoutPath();
        } else {
          setCreatingPath(false);
        }
      })
      .catch((error) => {
        setCreatingPath(false);
        console.log(error, "error in handleAddNewPath")
      });
  };

  const getAllUniversities = () => {
    setUniversityLoading(true);
    axios.get(`https://oldnaavi.apimachine.com/universities/get`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      let result = response?.data?.data;
      // console.log(result, 'getAllUniversities result');
      setUniversityList(result);
      setUniversityLoading(false);
    }).catch((error) => {
      console.log('error in getAllUniversities');
    })
  }

  useEffect(() => {
    if(ispopular) {
      getAllUniversities();
    }
  }, [ispopular])


  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <div className="dashboard-main">
        <div className="dashboard-body">
          <div onClick={() => setShowDrop(false)}>
            <AccDashsidebar />
          </div>
          <div className="dashboard-screens" onClick={() => resetpop()}>
            <div style={{ height: "100%" }}>
              {accsideNav === "CRM" ? (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder={
                          crmMenu === "Followers"
                            ? "Search Followers.."
                            : crmMenu === "Purchases"
                            ? "Search Purchases.."
                            : crmMenu === "Users"
                            ? "Search Users.."
                            : "Search Clients..."
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="crm-main" onClick={() => setShowDrop(false)}>
                    <div
                      className="crm-all-menu"
                      style={{ padding: "12px 35px" }}
                    >
                      {/* <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu === "Followers" ? "" : "none",
                          background:
                            crmMenu === "Followers"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight: crmMenu === "Followers" ? "700" : "",
                          marginLeft: "0",
                        }}
                        onClick={() => {
                          setcrmMenu("Followers");
                          setSearch("");
                        }}
                      >
                        Followers (<span>{followCount}</span>)
                      </div>

                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu !== "Followers" ? "" : "none",
                        }}
                        onClick={() => {
                          setcrmMenu("Followers");
                          setSearch("");
                        }}
                      >
                        Followers
                      </div> */}

                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu === "Users" ? "" : "none",
                          background:
                            crmMenu === "Users"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight: crmMenu === "Users" ? "700" : "",
                          marginLeft: "0",
                        }}
                        onClick={() => {
                          setcrmMenu("Users");
                          setSearch("");
                        }}
                      >
                        Users ({crmUserData?.length})
                      </div>

                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu !== "Users" ? "" : "none",
                          marginLeft: "0",
                        }}
                        onClick={() => {
                          setcrmMenu("Users");
                          setSearch("");
                        }}
                      >
                        Users
                      </div>

                      {/* <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu === "Clients" ? "" : "none",
                          background:
                            crmMenu === "Clients"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight: crmMenu === "Clients" ? "700" : "",
                        }}
                        onClick={() => {
                          setcrmMenu("Clients");
                          setSearch("");
                        }}
                      >
                        Clients ({crmClientData?.length})
                      </div>

                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu !== "Clients" ? "" : "none",
                        }}
                        onClick={() => {
                          setcrmMenu("Clients");
                          setSearch("");
                        }}
                      >
                        Clients
                      </div> */}

                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu === "Purchases" ? "" : "none",
                          background:
                            crmMenu === "Purchases"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight: crmMenu === "Purchases" ? "700" : "",
                        }}
                        onClick={() => {
                          setcrmMenu("Purchases");
                          setSearch("");
                        }}
                      >
                        Purchases (<span>{purchaseData.length}</span>)
                      </div>
                      <div
                        className="crm-each-menu"
                        style={{
                          display: crmMenu !== "Purchases" ? "" : "none",
                        }}
                        onClick={() => {
                          setcrmMenu("Purchases");
                          setSearch("");
                        }}
                      >
                        Purchases
                      </div>
                    </div>
                    <div className="crm-all-box">
                      {crmMenu === "Followers" ? (
                        <>
                          <div
                            className="crm-follow-tab"
                            style={{ padding: "10px 35px" }}
                          >
                            <div className="crm-follow-col1">Name</div>
                            <div className="crm-follow-col2">
                              Following Since
                            </div>
                          </div>
                          <>
                            {followData.length > 0 && !isLoading ? (
                              <div className="follow-data-main">
                                {followData
                                  .filter((element) => {
                                    return element.userEmail
                                      .toLowerCase()
                                      .startsWith(search.toLowerCase());
                                  })
                                  .map((each, i) => (
                                    <div
                                      className="follower-box"
                                      style={{
                                        background:
                                          selectedFollower === each
                                            ? "rgba(241, 241, 241, 0.5)"
                                            : "",
                                        padding: "22px 35px",
                                        width: "100%",
                                      }}
                                      onClick={() => setSelectedFollower(each)}
                                    >
                                      <div className="follower-details">
                                        <div>
                                          <img
                                            className="user-icon"
                                            src={each.profile_img}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <div className="follower-mail">
                                            {each.username}
                                          </div>
                                          <div
                                            className="follower-name"
                                            style={{
                                              textTransform: "lowercase",
                                            }}
                                          >
                                            {each.userEmail}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="follow-time">
                                        {formatDate(each.timeStamp)}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            ) : isLoading ? (
                              <div className="follow-data-main">
                                {[1, 2, 3, 4, 5, 6].map((each, i) => (
                                  <div className="follower-box">
                                    <div className="follower-details">
                                      <div>
                                        <Skeleton className="user-icon" />
                                      </div>
                                      <Skeleton
                                        className="follower-mail"
                                        style={{ width: "200px" }}
                                      />
                                    </div>
                                    <Skeleton
                                      className="follow-time"
                                      style={{ width: "150px" }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        </>
                      ) : crmMenu === "Purchases" ? (
                        <>
                          <div className="crm-purchase-tab">
                            <div className="crm-purchase-col1">Client</div>
                            <div className="crm-purchase-col2">Service</div>
                            <div className="crm-purchase-col3">Receipt</div>
                            <div className="crm-purchase-col4">Status</div>
                          </div>
                          <div className="purchase-alldata">
                            {!isPurchaseLoading && purchaseData.length > 0 ? (
                              <>
                                {purchaseData
                                  .filter(
                                    (item) =>
                                      item.name
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.email
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.product_name
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.license_code
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.license_id
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.license_status
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase())
                                  )
                                  .map((each, i) => (
                                    <div className="each-purchase">
                                      <div className="each-purchase-clients">
                                        <div className="each-purchase-head">
                                          {each.name}
                                        </div>
                                        <div className="each-purchase-text">
                                          {each.email}
                                        </div>
                                      </div>
                                      <div className="each-purchase-services">
                                        <div className="each-product-iconbox">
                                          <img
                                            className="each-product-icon"
                                            src={each.product_icon}
                                            alt=""
                                          />
                                        </div>
                                        <div className="each-purchase-data">
                                          <div className="each-purchase-head">
                                            {each.product_name}
                                          </div>
                                          <div className="each-purchase-text">
                                            {each.product_id}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="each-purchase-receipt">
                                        <div className="each-purchase-head">
                                          {each.license_id}
                                        </div>
                                        <div className="each-purchase-text">
                                          {each.license_code}
                                        </div>
                                      </div>
                                      <div className="each-purchase-status">
                                        <div className="each-purchase-statustext">
                                          {each.license_status}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            ) : isPurchaseLoading ? (
                              <>
                                {[1, 2, 3, 4, 5, 6].map((each) => (
                                  <div className="each-purchase">
                                    <div className="each-purchase-clients">
                                      <Skeleton
                                        className="each-purchase-head"
                                        style={{ width: "150px" }}
                                      />
                                      <Skeleton
                                        className="each-purchase-text"
                                        style={{ width: "150px" }}
                                      />
                                    </div>
                                    <div
                                      className="each-purchase-services"
                                      style={{ display: "flex" }}
                                    >
                                      <div className="each-product-iconbox">
                                        <Skeleton className="each-product-icon" />
                                      </div>
                                      <div className="each-purchase-data">
                                        <Skeleton
                                          className="each-purchase-head"
                                          style={{ width: "150px" }}
                                        />
                                        <Skeleton
                                          className="each-purchase-text"
                                          style={{ width: "150px" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="each-purchase-receipt">
                                      <Skeleton
                                        className="each-purchase-head"
                                        style={{ width: "150px" }}
                                      />
                                      <Skeleton
                                        className="each-purchase-text"
                                        style={{ width: "150px" }}
                                      />
                                    </div>
                                    <div className="each-purchase-status">
                                      <Skeleton
                                        className="each-purchase-statustext"
                                        style={{ width: "150px" }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : crmMenu === "Clients" ? (
                        <>
                          <div
                            className="crm-tab"
                            style={{ padding: "10px 35px" }}
                          >
                            <div
                              className="crm-each-col"
                              style={{ margin: "0", width: "25%" }}
                            >
                              Name
                            </div>
                            <div
                              className="crm-each-col"
                              style={{
                                margin: "0",
                                width: "25%",
                                paddingLeft: "1rem",
                              }}
                            >
                              Email
                            </div>
                          </div>
                          <div className="clients-alldata">
                            {isClientLoading
                              ? Array(10)
                                  .fill("")
                                  .map((e, i) => {
                                    return (
                                      <div className="each-clientData" key={i}>
                                        <div className="each-client-name">
                                          <Skeleton width={150} height={30} />
                                        </div>
                                        <div className="each-client-email">
                                          <Skeleton width={250} height={30} />
                                        </div>
                                      </div>
                                    );
                                  })
                              : crmClientData
                                  ?.filter(
                                    (item) =>
                                      item.name
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase()) ||
                                      item.email
                                        .toLowerCase()
                                        .startsWith(search.toLowerCase())
                                  )
                                  ?.map((e, i) => {
                                    return (
                                      <div className="each-clientData" key={i}>
                                        <div className="each-client-name">
                                          {e?.name}
                                        </div>
                                        <div className="each-client-email">
                                          {e?.email}
                                        </div>
                                      </div>
                                    );
                                  })}
                          </div>
                        </>
                      ) : crmMenu === "Users" ? (
                        <>
                          <div
                            className="crm-tab"
                            style={{ padding: "10px 35px" }}
                          >
                            <div
                              className="crm-each-col"
                              style={{
                                textAlign: "left",
                                margin: "0",
                                width: "25%",
                              }}
                            >
                              Name
                            </div>
                            <div
                              className="crm-each-col"
                              style={{
                                textAlign: "left",
                                margin: "0",
                                width: "25%",
                                paddingLeft: "1rem",
                              }}
                            >
                              Email
                            </div>
                            <div
                              className="crm-each-col"
                              style={{
                                textAlign: "left",
                                margin: "0",
                                width: "25%",
                                paddingLeft: "1rem",
                              }}
                            >
                              User Since
                            </div>
                            <div
                              className="crm-each-col"
                              style={{
                                textAlign: "left",
                                margin: "0",
                                width: "25%",
                                paddingLeft: "1rem",
                              }}
                            >
                              Path Name
                            </div>
                          </div>
                          <div className="users-alldata">
                            {isUserLoading
                              ? Array(10)
                                  .fill("")
                                  .map((e, i) => {
                                    return (
                                      <div className="each-userData" key={i}>
                                        <div
                                          className="each-user-email"
                                          style={{ width: "25%" }}
                                        >
                                          <Skeleton width={100} height={25} />
                                        </div>
                                        <div className="each-user-email">
                                          <Skeleton width={100} height={25} />
                                        </div>
                                        <div
                                          className="each-user-email"
                                          style={{ width: "25%" }}
                                        >
                                          <Skeleton width={100} height={25} />
                                        </div>
                                        <div
                                          className="each-user-email"
                                          style={{
                                            width: "25%",
                                          }}
                                        >
                                          <Skeleton width={100} height={25} />
                                        </div>
                                      </div>
                                    );
                                  })
                              : crmUserData
                                  ?.filter(
                                    (item) =>
                                      item?.username
                                        ?.toLowerCase()
                                        .startsWith(search?.toLowerCase()) ||
                                      item?.email
                                        ?.toLowerCase()
                                        .startsWith(search?.toLowerCase())
                                  )
                                  .map((e, i) => {
                                    return (
                                      <div className="each-userData" key={i}>
                                        <div
                                          className="each-user-email"
                                          style={{ width: "25%" }}
                                        >
                                          {e?.username}
                                        </div>
                                        <div
                                          className="each-user-email"
                                          style={{
                                            textTransform: "none",
                                            paddingLeft: "1rem",
                                            width: "25%",
                                          }}
                                        >
                                          {e?.email}
                                        </div>
                                        <div
                                          className="each-user-email"
                                          style={{
                                            width: "25%",
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {e?.createdAt
                                            ? customDateFormat(
                                                new Date(e.createdAt)
                                              )
                                            : ""}
                                        </div>
                                        <div
                                          className="each-user-email"
                                          style={{
                                            width: "25%",
                                            textTransform: "capitalize",
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {e?.nameOfPath}
                                        </div>
                                      </div>
                                    );
                                  })}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : accsideNav === "My Services" ? (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search Services.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="services-main"
                    onClick={() => setShowDrop(false)}
                  >
                    <div
                      className="services-all-menu"
                      style={{ borderBottom: "0.5px solid #E5E5E5" }}
                    >
                      <div
                        className="services-each-menu"
                        style={{
                          display: servicesMenu === "Services" ? "" : "none",
                          background:
                            servicesMenu === "Services"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight: servicesMenu === "Services" ? "700" : "",
                        }}
                        onClick={() => {
                          setservicesMenu("Services");
                          setSearch("");
                        }}
                      >
                        Services (<span>{servicesAcc?.length}</span>)
                      </div>
                      <div
                        className="services-each-menu"
                        style={{
                          display: servicesMenu !== "Services" ? "" : "none",
                        }}
                        onClick={() => {
                          setservicesMenu("Services");
                          setSearch("");
                        }}
                      >
                        Services
                      </div>
                      <div
                        className="services-each-menu"
                        style={{
                          background:
                            servicesMenu === "With CompPlan"
                              ? "rgba(241, 241, 241, 0.5)"
                              : "",
                          fontWeight:
                            servicesMenu === "With CompPlan" ? "700" : "",
                        }}
                        onClick={() => {
                          setservicesMenu("With CompPlan");
                          setSearch("");
                        }}
                      >
                        With CompPlan
                      </div>
                    </div>
                    <div>
                      <>
                        {servicesMenu === "Services" ? (
                          <div className="service-body">
                            <div className="service-body-left">
                              <>
                                {isServicesAcc ? (
                                  <>
                                    {[1, 2, 3, 4, 5].map((each, i) => (
                                      <div className="each-service-map" key={i}>
                                        <div className="dot-box">
                                          <img
                                            className="dot-icon"
                                            src={threedot}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <Skeleton
                                            className="each-service-img"
                                            style={{ marginBottom: "10px" }}
                                          />
                                        </div>
                                        <Skeleton
                                          className="serv-price"
                                          style={{
                                            width: "100px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                        <Skeleton
                                          className="serv-subtext"
                                          style={{
                                            width: "200px",
                                            height: "50px",
                                          }}
                                        />
                                        <div>
                                          <Skeleton
                                            className="serv-price"
                                            style={{ width: "100px" }}
                                          />
                                        </div>

                                        {/* {`${(allCurrencies.filter((item) => item?.coinSymbol === each?.billingType[`${Object.keys(each?.billingType)[0]}`].coin))[0]}`} */}
                                      </div>
                                    ))}
                                  </>
                                ) : servicesAcc?.length > 0 ? (
                                  <>
                                    {servicesAcc
                                      .filter((item) =>
                                        item?.nameOfService
                                          .toLowerCase()
                                          .startsWith(search.toLowerCase())
                                      )
                                      .map((each, i) => (
                                        <div
                                          className="each-service-map"
                                          key={i}
                                        >
                                          <div
                                            className="dot-box"
                                            onClick={() => {
                                              // console.log(each, "selected service");
                                              setServiceActionEnabled(true);
                                              setSelectedService(each);
                                              setEditService((prev) => {
                                                const arrayWithoutId =
                                                  Array?.isArray(each?.grade)
                                                    ? each?.grade?.map(
                                                        (obj) => {
                                                          const {
                                                            _id,
                                                            ...rest
                                                          } = obj;
                                                          return rest;
                                                        }
                                                      )
                                                    : [];
                                                const arrayWithoutIdFinance =
                                                  Array?.isArray(
                                                    each?.financialSituation
                                                  )
                                                    ? each?.financialSituation?.map(
                                                        (obj) => {
                                                          const {
                                                            _id,
                                                            ...rest
                                                          } = obj;
                                                          return rest;
                                                        }
                                                      )
                                                    : [];
                                                const arrayWithoutIdStream =
                                                  Array?.isArray(each?.stream)
                                                    ? each?.stream?.map(
                                                        (obj) => {
                                                          const {
                                                            _id,
                                                            ...rest
                                                          } = obj;
                                                          return rest;
                                                        }
                                                      )
                                                    : [];
                                                // console.log(arrayWithoutId, 'arrayWithoutId', arrayWithoutIdFinance, 'arrayWithoutIdFinance', arrayWithoutIdStream, 'arrayWithoutIdStream' )
                                                return {
                                                  ...prev,
                                                  grade: arrayWithoutId,
                                                  financialSituation:
                                                    arrayWithoutIdFinance,
                                                  stream: arrayWithoutIdStream,
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              className="dot-icon"
                                              src={threedot}
                                              alt=""
                                            />
                                          </div>
                                          <div>
                                            <img
                                              className="each-service-img"
                                              src={each.icon}
                                              alt=""
                                            />
                                          </div>
                                          <div className="serv-title">
                                            {each.nameOfService}
                                          </div>
                                          <div className="serv-subtext">
                                            {each.description}
                                          </div>
                                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            {/* {each.billing_cycle !== undefined &&
                                            each.billing_cycle !== null &&
                                            Object.keys(
                                              each.billing_cycle
                                            )[0] === "monthly" ? (
                                              <div className="serv-price">
                                                {
                                                  allCurrencies?.filter(
                                                    (item) =>
                                                      item?.coinSymbol ===
                                                      each?.billing_cycle
                                                        ?.monthly?.coin
                                                  )[0]?.symbol
                                                }{" "}
                                                {
                                                  each.billing_cycle.monthly
                                                    .price
                                                }{" "}
                                                /{" "}
                                                <span
                                                  style={{ fontWeight: "300" }}
                                                >
                                                  Monthly
                                                </span>
                                              </div>
                                            ) : each.billing_cycle !==
                                                undefined &&
                                              each.billing_cycle !== null &&
                                              Object.keys(
                                                each.billing_cycle
                                              )[0] === "lifetime" ? (
                                              <div className="serv-price">
                                                {
                                                  allCurrencies?.filter(
                                                    (item) =>
                                                      item?.coinSymbol ===
                                                      each?.billing_cycle
                                                        ?.lifetime?.coin
                                                  )[0]?.symbol
                                                }{" "}
                                                {
                                                  each?.billing_cycle?.lifetime
                                                    ?.price
                                                }{" "}
                                                /{" "}
                                                <span
                                                  style={{ fontWeight: "300" }}
                                                >
                                                  Lifetime
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )} */}
                                            <div className="serv-price">
                                              {each.price != ""
                                                ? "Rs."
                                                : "Free"}{" "}
                                              {each.price}
                                            </div>
                                            <div>{each?.type}</div>
                                          </div>

                                          {/* {`${(allCurrencies.filter((item) => item?.coinSymbol === each?.billingType[`${Object.keys(each?.billingType)[0]}`].coin))[0]}`} */}
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                            {/* <div className="service-body-right">
                          <div className="service-box1">
                          <div className="service-right-title">Country</div>
                          <div className="service-right-btn">See All</div>
                          </div>
                          <div className="service-box1">
                          <div className="service-right-title">Country</div>
                          <div className="service-right-btn">See All</div>
                          </div>
                          <div className="service-box1">
                          <div className="service-right-title">Country</div>
                          <div className="service-right-btn">See All</div>
                          </div>
                        </div> */}
                          </div>
                        ) : (
                          <div className="service-body">
                            <div className="service-body-left">
                              {gettingData ? (
                                <>
                                  {[1, 2, 3, 4, 5].map((each, i) => (
                                    <div className="each-service-map" key={i}>
                                      <div className="dot-box">
                                        <img
                                          className="dot-icon"
                                          src={threedot}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <Skeleton
                                          className="each-service-img"
                                          style={{ marginBottom: "10px" }}
                                        />
                                      </div>
                                      <Skeleton
                                        className="serv-price"
                                        style={{
                                          width: "100px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <Skeleton
                                        className="serv-subtext"
                                        style={{
                                          width: "200px",
                                          height: "50px",
                                        }}
                                      />
                                      <div>
                                        <Skeleton
                                          className="serv-price"
                                          style={{ width: "100px" }}
                                        />
                                      </div>

                                      {/* {`${(allCurrencies.filter((item) => item?.coinSymbol === each?.billingType[`${Object.keys(each?.billingType)[0]}`].coin))[0]}`} */}
                                    </div>
                                  ))}
                                </>
                              ) : withCompPlanData.length > 0 ? (
                                <>
                                  {withCompPlanData
                                    ?.filter((item) =>
                                      item?.product?.product_name
                                        ?.toLowerCase()
                                        ?.startsWith(search?.toLowerCase())
                                    )
                                    ?.map((each, i) => (
                                      <div
                                        className="each-service-map"
                                        key={i}
                                        style={{ height: "250px" }}
                                      >
                                        <div
                                          className="dot-box"
                                          // onClick={() => {
                                          //   setServiceActionEnabled(true);
                                          //   setSelectedService(each?.product);
                                          // }}
                                        >
                                          <img
                                            className="dot-icon"
                                            src={threedot}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <img
                                            className="each-service-img"
                                            src={each?.product?.product_icon}
                                            alt=""
                                          />
                                        </div>
                                        <div className="serv-title">
                                          {each?.product?.product_name}
                                        </div>
                                        <div className="serv-subtext">
                                          {each?.product?.sub_text}
                                        </div>
                                        <div>
                                          {each?.product?.billing_cycle !==
                                            undefined &&
                                          each?.product?.billing_cycle !==
                                            null &&
                                          Object.keys(
                                            each?.product?.billing_cycle
                                          )[0] === "monthly" ? (
                                            <div className="serv-price">
                                              {
                                                allCurrencies?.filter(
                                                  (item) =>
                                                    item?.coinSymbol ===
                                                    each?.product?.billing_cycle
                                                      ?.monthly.coin
                                                )[0].symbol
                                              }{" "}
                                              {
                                                each?.product?.billing_cycle
                                                  ?.monthly?.price
                                              }{" "}
                                              /{" "}
                                              <span
                                                style={{ fontWeight: "300" }}
                                              >
                                                Monthly
                                              </span>
                                            </div>
                                          ) : each?.product?.billing_cycle !==
                                              undefined &&
                                            each?.product?.billing_cycle !==
                                              null &&
                                            Object.keys(
                                              each?.product?.billing_cycle
                                            )[0] === "lifetime" ? (
                                            <div className="serv-price">
                                              {
                                                allCurrencies?.filter(
                                                  (item) =>
                                                    item?.coinSymbol ===
                                                    each?.product?.billing_cycle
                                                      ?.lifetime.coin
                                                )[0].symbol
                                              }{" "}
                                              {
                                                each?.product?.billing_cycle
                                                  ?.lifetime.price
                                              }{" "}
                                              /{" "}
                                              <span
                                                style={{ fontWeight: "300" }}
                                              >
                                                Lifetime
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        {/* {`${(allCurrencies.filter((item) => item?.coinSymbol === each?.billingType[`${Object.keys(each?.billingType)[0]}`].coin))[0]}`} */}
                                      </div>
                                    ))}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </>
              ) : accsideNav === "Calendar" ? (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search Services.."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="services-main"
                    onClick={() => setShowDrop(false)}
                  >
                    <EarningCalendar />
                  </div>
                </>
              ) : accsideNav === "Wallet" ? (
                transactionSelected ? (
                  <>
                    <div className="dash-nav">
                      <div
                        className="search-input-box"
                        onClick={() => setShowDrop(false)}
                      >
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Search..."
                          // value={searchVault}
                          // onChange={(e) => setSearchVault(e.target.value)}
                        />
                      </div>
                      <div
                        className="search-box"
                        onClick={() => setShowDrop(false)}
                      >
                        <img className="search-icon" src={searchic} alt="" />
                      </div>
                      <div
                        className="full-user"
                        onClick={() => setShowDrop(!showDrop)}
                      >
                        <div className="user-box">
                          <img
                            className="user-icon"
                            src={
                              JSON.parse(localStorage.getItem("user"))?.user
                                ?.profile_img !== undefined
                                ? JSON.parse(localStorage.getItem("user"))?.user
                                    ?.profile_img
                                : profile
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="arrow-box"
                          style={{
                            transform: showDrop ? "rotate(180deg)" : "",
                            cursor: "pointer",
                          }}
                        >
                          <img className="arrow-icon" src={downarrow} alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="services-main"
                      style={{ height: "calc(100% - 70px)" }}
                      onClick={() => setShowDrop(false)}
                    >
                      <div
                        className="services-all-menu"
                        style={{ borderBottom: "0.5px solid #E5E5E5" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "calc(100% - 110px)",
                          }}
                        >
                          <div
                            className="services-each-menu"
                            style={{
                              background:
                                coinType === "fiat"
                                  ? "rgba(241, 241, 241, 0.5)"
                                  : "",
                              fontWeight: coinType === "fiat" ? "700" : "",
                            }}
                            onClick={() => {
                              setCoinType("fiat");
                              setSearch("");
                            }}
                          >
                            Forex
                          </div>

                          {/* <div
                          className="services-each-menu"
                          style={{
                            background:
                              coinType === "crypto"
                                ? "rgba(241, 241, 241, 0.5)"
                                : "",
                            fontWeight: coinType === "crypto" ? "700" : "",
                          }}
                          onClick={() => {
                            setCoinType("crypto");
                            setSearch("");
                          }}
                        >
                          Crypto
                        </div> */}
                        </div>

                        <div
                          style={{
                            fontWeight: "600",
                            textDecorationLine: "underline",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                          onClick={() => {
                            setTransactionSelected(false);
                            setTransactionData([]);
                            setSelectedCoin({});
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <VaultTransactions />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dash-nav">
                      <div
                        className="search-input-box"
                        onClick={() => setShowDrop(false)}
                      >
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Search Wallet..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <div
                        className="search-box"
                        onClick={() => setShowDrop(false)}
                      >
                        <img className="search-icon" src={searchic} alt="" />
                      </div>
                      <div
                        className="full-user"
                        onClick={() => setShowDrop(!showDrop)}
                      >
                        <div className="user-box">
                          <img
                            className="user-icon"
                            src={
                              JSON.parse(localStorage.getItem("user"))?.user
                                ?.profile_img !== undefined
                                ? JSON.parse(localStorage.getItem("user"))?.user
                                    ?.profile_img
                                : profile
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="arrow-box"
                          style={{
                            transform: showDrop ? "rotate(180deg)" : "",
                            cursor: "pointer",
                          }}
                        >
                          <img className="arrow-icon" src={downarrow} alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="services-main"
                      style={{ height: "calc(100% - 70px)" }}
                      onClick={() => setShowDrop(false)}
                    >
                      <div
                        className="services-all-menu"
                        style={{ borderBottom: "0.5px solid #E5E5E5" }}
                      >
                        <div style={{ display: "flex", width: "83%" }}>
                          {/* <div
                          className="services-each-menu"
                          style={{
                            background:
                              coinType === "crypto"
                                ? "rgba(241, 241, 241, 0.5)"
                                : "",
                            fontWeight: coinType === "crypto" ? "700" : "",
                          }}
                          onClick={() => {
                            setCoinType("crypto");
                            setSearch("");
                          }}
                        >
                          Crypto
                        </div> */}

                          <div
                            className="services-each-menu"
                            style={{
                              background:
                                coinType === "fiat"
                                  ? "rgba(241, 241, 241, 0.5)"
                                  : "",
                              fontWeight: coinType === "fiat" ? "700" : "",
                            }}
                            onClick={() => {
                              setCoinType("fiat");
                              setSearch("");
                            }}
                          >
                            Forex
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <Toggle
                            toggle={balanceToggle}
                            setToggle={setBalanceToggle}
                            coinType={coinType}
                          />
                        </div>
                      </div>
                      <Vaults searchedValue={search} />
                    </div>
                  </>
                )
              ) : accsideNav === "Tasks" ? (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search ..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="services-main"
                    style={{ height: "calc(100% - 70px)" }}
                    onClick={() => setShowDrop(false)}
                  >
                    <Tasks />
                  </div>
                </>
              ) : accsideNav === "Paths" ? (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder={
                          mypathsMenu === "Paths"
                            ? "Search For Paths..."
                            : "Search For Steps..."
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="services-main"
                    style={{ height: "calc(100% - 70px)" }}
                    onClick={() => setShowDrop(false)}
                  >
                    <MyPaths search={search} localRefresh={localRefresh} />
                  </div>
                </>
              ) : (
                <>
                  <div className="dash-nav">
                    <div
                      className="search-input-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        value={search}
                        // onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className="search-box"
                      onClick={() => setShowDrop(false)}
                    >
                      <img className="search-icon" src={searchic} alt="" />
                    </div>
                    <div
                      className="full-user"
                      onClick={() => setShowDrop(!showDrop)}
                    >
                      <div className="user-box">
                        <img
                          className="user-icon"
                          src={
                            JSON.parse(localStorage.getItem("user"))?.user
                              ?.profile_img !== undefined
                              ? JSON.parse(localStorage.getItem("user"))?.user
                                  ?.profile_img
                              : profile
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="arrow-box"
                        style={{
                          transform: showDrop ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      >
                        <img className="arrow-icon" src={downarrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="services-main"
                    style={{ height: "calc(100% - 70px)" }}
                    onClick={() => setShowDrop(false)}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "600",
                        fontSize: "1.5rem",
                      }}
                    >
                      Coming Soon
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: "25",
          top: "0",
          left: "0",
          display: ispopular ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {ispopular ? (
          <div
            className="acc-popular"
            onClick={() => setShowDrop(false)}
            onMouseDown={(e) => e.stopPropagation()}
            style={{ background: "#e5e5e5" }}
          >
            <div
              className="acc-popular-top"
              style={{
                display:
                  pstep === 5
                    ? "none"
                    : pstep === 9
                    ? "none"
                    : pstep === 14
                    ? "none"
                    : "flex",
              }}
            >
              <div className="acc-popular-head">
                {pstep > 5 && pstep < 9
                  ? "Add Path"
                  : pstep > 1 && pstep < 5
                  ? "Add Service"
                  : pstep === 10
                  ? "Choose Path"
                  : pstep === 11
                  ? "Add Macro Step"
                  : pstep === 12
                  ? "Add Micro Step"
                  : pstep === 13
                  ? "Add Nano Step"
                  : "Actions"}
              </div>
              <div
                className="acc-popular-img-box"
                onClick={() => resetpop()}
                style={{ cursor: "pointer" }}
              >
                <img className="acc-popular-img" src={closepop} alt="" />
              </div>
            </div>
            <>
              {pstep === 1 && (
                <div>
                  <div className="acc-step-text">New</div>
                  <div>
                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Service");
                        setpstep(2);
                      }}
                      style={{
                        background: selectNew === "Service" ? "#182542" : "",
                        color: selectNew === "Service" ? "#FFF" : "",
                      }}
                    >
                      Service
                    </div>

                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Path");
                        setpstep(6);
                      }}
                      style={{
                        background: selectNew === "Path" ? "#182542" : "",
                        color: selectNew === "Path" ? "#FFF" : "",
                      }}
                    >
                      Path
                    </div>

                    <div
                      className="acc-step-box"
                      onClick={() => {
                        setselectNew("Step");
                        setpstep(10);
                      }}
                      style={{
                        background: selectNew === "Step" ? "#182542" : "",
                        color: selectNew === "Step" ? "#FFF" : "",
                      }}
                    >
                      Step
                    </div>
                  </div>
                </div>
              )}

              {pstep === 2 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Name"
                        value={serviceNameVal}
                        onChange={(e) => {
                          setServiceNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Description"
                        value={serviceDescriptionVal}
                        onChange={(e) => {
                          setServiceDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceTypeDropdown(!serviceTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceTypeSelected ? typeVal : "Type"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceTypeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceTypeSelected(true);
                                setTypeVal(e);
                                setServiceTypeDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceAccessDropdown(!serviceAccessDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {serviceAccessSelected ? accessVal : "Access"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceAccessDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceAccessDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceAccessList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceAccessSelected(true);
                                setAccessVal(e);
                                setServiceAccessDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Goal"
                        value={goalVal}
                        onChange={(e) => {
                          setGoalVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="acc-upload" style={{ marginBottom: "0" }}>
                      <div className="acc-upload-title">
                        Upload Logo
                        {/* <span style={{ color: "red", marginLeft: "2px" }}>*</span> */}
                      </div>
                      <div className="acc-upload-imgbox">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileInputChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <img
                          className="acc-upload-img"
                          src={
                            isUploadLoading
                              ? upgif
                              : coverImageS3url !== ""
                              ? coverImageS3url
                              : uploadv
                          }
                          alt=""
                          onClick={handleImageClick}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(3);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 3 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceCostDropdown(!serviceCostDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceCostSelected ? costVal : "Cost"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceCostDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceCostDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceCostList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceCostSelected(true);
                                setCostVal(e);
                                setServiceCostDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        display: costVal === "paid" ? "flex" : "none",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="Price"
                        onChange={(e) => {
                          setPriceVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={priceVal}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        flexDirection: "column",
                        background: "white",
                        display:
                          costVal === "paid" && priceVal ? "flex" : "none",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceDiscountDropdown(!serviceDiscountDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceDiscountSelected ? discountVal : "Discount"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceDiscountDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceDiscountDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceDiscountList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceDiscountSelected(true);
                                setDiscountVal(e);
                                setServiceDiscountDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{
                        display: discountVal ? "flex" : "none",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="Discount value"
                        onChange={(e) => {
                          setDiscountInputVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={discountInputVal}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="Duration"
                        onChange={(e) => {
                          setDurationVal(e.target.value);
                          // console.log(e.target.value, 'durVal')
                        }}
                        value={durationVal}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Features"
                        value={featureVal}
                        onChange={(e) => {
                          setFeatureVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStatusDropdown(!serviceStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceStatusSelected ? statusVal : "Status"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStatusList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStatusSelected(true);
                                setStatusVal(e);
                                setServiceStatusDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceGradeDropdown(!serviceGradeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceGradeSelected && gradeVal?.length > 0 ? (
                            <>
                              {gradeVal?.map((e, i) => {
                                return gradeVal?.length - 1 === i
                                  ? e?.grade + "th" + " "
                                  : e?.grade + "th" + "," + " ";
                              })}
                            </>
                          ) : (
                            "Grade Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceGradeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceGradeDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceGradeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceGradeSelected(true);
                                handleGradeForAddService(e);
                                // setServiceGradeDropdown(false);
                              }}
                            >
                              <div key={i}>{e + "th"}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(2);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(4);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 4 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceFinancialDropdown(
                            !serviceFinancialDropdown
                          );
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceFinancialSelected && finVal?.length > 0 ? (
                            <>
                              {finVal?.map((e, i) => {
                                return finVal?.length - 1 === i
                                  ? e?.finance + " "
                                  : e?.finance + "," + " ";
                              })}
                            </>
                          ) : (
                            "Financial Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceFinancialDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceFinancialDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceFinancialList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceFinancialSelected(true);
                                handleFinancialForAddService(e);
                                // setServiceFinancialDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStreamDropdown(!serviceStreamDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {serviceStreamSelected && streamVal?.length > 0 ? (
                            <>
                              {streamVal?.map((e, i) => {
                                return streamVal?.length - 1 === i
                                  ? e?.stream + " "
                                  : e?.stream + "," + " ";
                              })}
                            </>
                          ) : (
                            "Stream Data"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStreamDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStreamDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStreamList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStreamSelected(true);
                                handleStreamForAddService(e);
                                // setServiceStreamDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Outcome"
                        onChange={(e) => {
                          setOutcomeVal(e.target.value);
                          // console.log(e.target.value, 'outcomeVal')
                        }}
                        value={outcomeVal}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceStepDropdown(!serviceStepDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {serviceStepSelected ? stepVal : "Suitable Step"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceStepDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceStepDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceStepList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceStepSelected(true);
                                setStepVal(e);
                                setServiceStepDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceIterationDropdown(
                            !serviceIterationDropdown
                          );
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {serviceIterationSelected
                            ? iterationVal
                            : "Iterations"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: serviceIterationDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: serviceIterationDropdown ? "flex" : "none",
                        }}
                      >
                        {serviceIterationList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setServiceIterationSelected(true);
                                setIterationVal(e);
                                setServiceIterationDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(3);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        handleAddNewService();
                      }}
                    >
                      Add Service
                    </div>
                  </div>
                </>
              )}

              {pstep === 5 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Service Added Successfully
                </div>
              )}

              {pstep === 6 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Path Name"
                        value={pathNameVal}
                        onChange={(e) => {
                          setPathNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Description"
                        value={pathDescriptionVal}
                        onChange={(e) => {
                          setPathDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        placeholder="Current Coordinates"
                        value={pathCurrentCoordinatesVal}
                        onChange={(e) => {
                          setPathCurrentCoordinatesVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        placeholder="Future Coordinates"
                        value={pathFutureCoordinatesVal}
                        onChange={(e) => {
                          setPathFutureCoordinatesVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathTypeDropdown(!pathTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: 'capitalize'
                          }}
                        >
                          {pathTypeSelected ? pathTypeVal : "Type of Path"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {pathTypeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathTypeSelected(true);
                                setpathTypeVal(e);
                                setPathTypeDropdown(false);
                              }}
                            >
                              <div key={i} style={{textTransform: 'capitalize'}}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathGradeDropdown(!pathGradeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathGradeSelected && grade?.length > 0 ? (
                            <>
                              {grade?.map((e, i) => {
                                return grade?.length - 1 === i
                                  ? e?.grade + "th" + " "
                                  : e?.grade + "th" + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal Grade"
                          )}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathGradeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathGradeDropdown ? "flex" : "none",
                        }}
                      >
                        {gradeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathGradeSelected(true);
                                handleGrade(e);
                                // setPathGradeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(7);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 7 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathGradePointDropdown(!pathGradePointDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathGradePointSelected && gradeAvg?.length > 0 ? (
                            <>
                              {gradeAvg?.map((e, i) => {
                                return gradeAvg?.length - 1 === i
                                  ? e?.grade_avg + " "
                                  : e?.grade_avg + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal grade points average"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathGradePointDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathGradePointDropdown ? "flex" : "none",
                        }}
                      >
                        {gradePointAvg?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathGradePointSelected(true);
                                handleGradeAvg(e);
                                // setPathGradePointDropdown(false);
                              }}
                            >
                              <div key={i}>{e + "%"}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathCurriculumDropdown(!pathCurriculumDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathCurriculumSelected && curriculum?.length > 0 ? (
                            <>
                              {curriculum?.map((e, i) => {
                                return curriculum?.length - 1 === i
                                  ? e?.curriculum + " "
                                  : e?.curriculum + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal curriculum"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathCurriculumDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathCurriculumDropdown ? "flex" : "none",
                        }}
                      >
                        {curriculumList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathCurriculumSelected(true);
                                handleCurriculum(e);
                                // setPathCurriculumDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathStreamDropdown(!pathStreamDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathStreamSelected && stream?.length > 0 ? (
                            <>
                              {stream?.map((e, i) => {
                                return stream?.length - 1 === i
                                  ? e?.stream + " "
                                  : e?.stream + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal stream"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathStreamDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathStreamDropdown ? "flex" : "none",
                        }}
                      >
                        {streamList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathStreamSelected(true);
                                handleStream(e);
                                // setPathStreamDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathFinancialDropdown(!pathFinancialDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathFinancialSelected && finance?.length > 0 ? (
                            <>
                              {finance?.map((e, i) => {
                                return finance?.length - 1 === i
                                  ? e?.finance + " "
                                  : e?.finance + "," + " ";
                              })}
                            </>
                          ) : (
                            "Ideal financial situation"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathFinancialDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathFinancialDropdown ? "flex" : "none",
                        }}
                      >
                        {financeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathFinancialSelected(true);
                                handleFinance(e);
                                // setPathFinancialDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="What program will they be studying?"
                        value={pathProgramVal}
                        onChange={(e) => {
                          setPathProgramVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathUniversityDropdown(!pathUniversityDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          { pathUniversitySelected && pathUniversityVal ? (
                            pathUniversityVal?.name
                          ) : (
                            "University"
                          )}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathUniversityDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: pathUniversityDropdown ? "flex" : "none",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search University"
                          onChange={(e) => {
                            setUniversitySearch(e.target.value);
                          }}
                          value={universitySearch}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathUniversityDropdown ? "flex" : "none",
                          height: '50vh',
                          overflowY: 'scroll',
                        }}
                      >
                        {universityLoading ? Array(10)
                          .fill(' ')
                          .map((item, index) => {
                            return (
                              <div key={index} className="each-hidden-step">
                                <Skeleton width={"100%"} height={30}  />
                              </div>
                            );
                          }) : universityList?.filter((each) =>
                          each?.name
                            ?.toLowerCase()
                            .includes(universitySearch?.toLowerCase())
                        )?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathUniversitySelected(true);
                                setPathUniversityVal(e);
                                setPathUniversityDropdown(false);
                                setUniversitySearch('');
                              }}
                            >
                              <div key={i}>{e?.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(6);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(8);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 8 && (
                <>
                  <div className="acc-addservice">
                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathPersonalityDropdown(!pathPersonalityDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textTransform:
                              pathPersonalitySelected && personality
                                ? "capitalize"
                                : "none",
                          }}
                        >
                          {pathPersonalitySelected && personality
                            ? personality
                            : "What personality suits this path?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathPersonalityDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathPersonalityDropdown ? "flex" : "none",
                        }}
                      >
                        {personalityList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathPersonalitySelected(true);
                                handlePersonality(e);
                                setPathPersonalityDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathDestinationDropdown(!pathDestinationDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {pathDestinationSelected && pathDestinationVal
                            ? pathDestinationVal
                            : "Destination Degree Type"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathDestinationDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathDestinationDropdown ? "flex" : "none",
                        }}
                      >
                        {degreeList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathDestinationSelected(true);
                                setPathDestinationVal(e);
                                setPathDestinationDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPathStatusDropdown(!pathStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {pathStatusSelected ? pathStatusVal : "Status"}
                        </div>

                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: pathStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: pathStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {pathStatusList?.map((e, i) => {
                          return (
                            <div
                              className="each-hidden-step"
                              onClick={() => {
                                setPathStatusSelected(true);
                                setPathStatusVal(e);
                                setPathStatusDropdown(false);
                              }}
                            >
                              <div
                                key={i}
                                style={{ textTransform: "capitalize" }}
                              >
                                {e}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(7);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        handleAddNewPath();
                      }}
                    >
                      Add Path
                    </div>
                  </div>
                  {creatingPath && (
                    <div className="popularlogo">
                      <img className="popularlogoimg" src={lg1} alt="" />
                    </div>
                  )}
                </>
              )}

              {pstep === 9 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Path Added Successfully
                </div>
              )}

              {pstep === 10 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={stepNameVal}
                        onChange={(e) => {
                          setStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: generalMapPathDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setGeneralMapPathDropdown(!generalMapPathDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Path
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: generalMapPathDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: generalMapPathDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Path"
                          onChange={(e) => {
                            setMapPathSearchTerm(e.target.value);
                          }}
                          value={mapPathSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: generalMapPathDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {pathList
                          ?.filter((entry) =>
                            entry?.nameOfPath
                              ?.toLowerCase()
                              .includes(mapPathSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setSelectedPathVal(e);
                                  setGeneralMapPathSelected(true);
                                  setGeneralMapPathDropdown(false);
                                  setMapPathSearchTerm("");
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfPath}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {generalMapPathSelected && selectedPathVal ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            background: "white",
                            borderRadius: "15px",
                            padding: "1.5rem",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            {selectedPathVal?.nameOfPath}
                          </div>
                          <div
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "300",
                              lineHeight: "1.6",
                            }}
                          >
                            {selectedPathVal?.description}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="Order"
                        value={stepOrderVal}
                        onChange={(e) => {
                          setStepOrderVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setStepStatusDropdown(!stepStatusDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {stepStatusVal
                            ? stepStatusVal
                            : "Step status"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: stepStatusDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: stepStatusDropdown ? "flex" : "none",
                        }}
                      >
                        {stepStatusList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setStepStatusVal(e);
                                setStepStatusDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(1);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(11);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 11 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={macroStepNameVal}
                        onChange={(e) => {
                          setMacroStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={macroStepLengthVal}
                        onChange={(e) => {
                          setMacroStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={macroStepDescriptionVal}
                        onChange={(e) => {
                          setMacroStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMacroStepTypeDropdown(!macroStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {macroStepTypeVal
                            ? macroStepTypeVal
                            : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: macroStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: macroStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {macroStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setMacroStepTypeVal(e);
                                setMacroStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: macroMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setMacroMapServiceDropDown(!macroMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: macroMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: macroMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMacroServiceSearchTerm(e.target.value);
                          }}
                          value={macroServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: macroMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {macroServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(macroServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setMacroServiceSelected(true);
                                  handleMacroServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {macroServiceSelected && macroServiceVal?.length > 0
                        ? macroServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={macroStepChanceVal}
                        onChange={(e) => {
                          setMacroStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(10);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(12);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 12 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={microStepNameVal}
                        onChange={(e) => {
                          setMicroStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={microStepLengthVal}
                        onChange={(e) => {
                          setMicroStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={microStepDescriptionVal}
                        onChange={(e) => {
                          setMicroStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMicroStepTypeDropdown(!microStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {microStepTypeVal
                            ? microStepTypeVal
                            : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: microStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: microStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {microStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setMicroStepTypeVal(e);
                                setMicroStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: microMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setMicroMapServiceDropDown(!microMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: microMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: microMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setMicroServiceSearchTerm(e.target.value);
                          }}
                          value={microServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: microMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {microServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(microServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setMicroServiceSelected(true);
                                  handleMicroServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {microServiceSelected && microServiceVal?.length > 0
                        ? microServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={microStepChanceVal}
                        onChange={(e) => {
                          setMicroStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-goBack-next">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(11);
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={nextIcon}
                        alt=""
                        onClick={() => {
                          setpstep(13);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {pstep === 13 && (
                <>
                  <div className="acc-addservice">
                    <div className="each-acc-addservice-field-input">
                      <input
                        type="text"
                        placeholder="Step Name"
                        value={nanoStepNameVal}
                        onChange={(e) => {
                          setNanoStepNameVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="How Long does this step take?"
                        value={nanoStepLengthVal}
                        onChange={(e) => {
                          setNanoStepLengthVal(e.target.value);
                        }}
                      />
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <textarea
                        type="text"
                        placeholder="Instructions / Description"
                        value={nanoStepDescriptionVal}
                        onChange={(e) => {
                          setNanoStepDescriptionVal(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", background: "white" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNanoStepTypeDropdown(!nanoStepTypeDropdown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {nanoStepTypeVal ? nanoStepTypeVal : "Type of step?"}
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: nanoStepTypeDropdown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: nanoStepTypeDropdown ? "flex" : "none",
                        }}
                      >
                        {nanoStepTypeList?.map((e, i) => {
                          return (
                            <div
                              style={{ textTransform: "capitalize" }}
                              className="each-hidden-step"
                              onClick={() => {
                                setNanoStepTypeVal(e);
                                setNanoStepTypeDropdown(false);
                              }}
                            >
                              <div key={i}>{e}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      className="each-acc-addservice-field-input"
                      style={{ flexDirection: "column", gap: "1rem" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: nanoMapServiceDropDown
                            ? "15px 15px 0px 0px"
                            : "15px",
                        }}
                        onClick={() => {
                          setNanoMapServiceDropDown(!nanoMapServiceDropDown);
                        }}
                      >
                        <div
                          style={{
                            width: "85%",
                            cursor: "pointer",
                            padding: "1.5rem",
                            borderRadius: "15px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          Map Services
                        </div>
                        <div className="arrow-box">
                          <img
                            src={dropdownIcon}
                            alt=""
                            style={{
                              transform: nanoMapServiceDropDown
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: nanoMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0px",
                          marginTop: "-1rem",
                        }}
                      >
                        <input
                          style={{ borderRadius: "0px" }}
                          type="text"
                          placeholder="Search Service"
                          onChange={(e) => {
                            setNanoServiceSearchTerm(e.target.value);
                          }}
                          value={nanoServiceSearchTerm}
                        />
                      </div>
                      <div
                        className="hidden-steps"
                        style={{
                          display: nanoMapServiceDropDown ? "flex" : "none",
                          borderRadius: "0 0px 15px 15px",
                          marginTop: "-1rem",
                          background: "white",
                        }}
                      >
                        {nanoServiceList
                          ?.filter((entry) =>
                            entry?.nameOfService
                              ?.toLowerCase()
                              ?.includes(nanoServiceSearchTerm?.toLowerCase())
                          )
                          ?.map((e, i) => {
                            return (
                              <div
                                key={e?._id}
                                className="each-hidden-step"
                                style={{
                                  padding: "0 0 1.5rem 0",
                                  borderBottom: "1px solid #e5e5e5",
                                }}
                                onClick={() => {
                                  setNanoServiceSelected(true);
                                  handleNanoServiceForAddStep(e);
                                }}
                              >
                                <div className="stepp-textt">
                                  {e?.nameOfService}
                                </div>
                                <div className="stepp-textt1">
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {nanoServiceSelected && nanoServiceVal?.length > 0
                        ? nanoServiceVal?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  background: "white",
                                  borderRadius: "15px",
                                  padding: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e?.nameOfService}
                                </div>
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "300",
                                    lineHeight: "1.6",
                                  }}
                                >
                                  {e?.description}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <div className="each-acc-addservice-field-input">
                      <input
                        type="number"
                        placeholder="No of chances"
                        value={nanoStepChanceVal}
                        onChange={(e) => {
                          setNanoStepChanceVal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="acc-addservice-submit">
                    <div>
                      <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                          setpstep(12);
                        }}
                      />
                    </div>
                    <div
                      className="acc-addservice-submit-btn"
                      onClick={() => {
                        
                        handleNewStep();
                      }}
                    >
                      Add Step
                    </div>
                  </div>
                </>
              )}

              {pstep === 14 && (
                <div
                  className="success-box"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Step Added Successfully
                </div>
              )}
            </>
          </div>
        ) : (
          ""
        )}
      </div>

      <>
        {showDrop ? (
          <div className="m-drop" onMouseDown={(e) => e.stopPropagation()}>
            <div
              className="m-each"
              onClick={() => {
                setaccsideNav("");
                navigate("/dashboard/accountants/profile");
              }}
            >
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={profilea} alt="" />
                </div>
                <div className="m-left-text">Profile</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
            <div className="m-each-line"> </div>
            <div className="m-each" onClick={() => handleLogout()}>
              <div className="m-left">
                <div className="m-left-icon-box">
                  <img className="m-left-icon" src={logout} alt="" />
                </div>
                <div className="m-left-text">Logout</div>
              </div>
              <div className="m-right-icon-box">
                <img className="m-right-icon" src={sidearrow} alt="" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>

      <>
        {coinActionEnabled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.6)",
              zIndex: "25",
              top: "0",
              left: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="acc-popular"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div
                className="acc-popular-top"
                style={{ height: "3rem", marginBottom: "0" }}
              >
                <div className="acc-popular-head">
                  {selectedCoin?.coinName} Actions
                </div>
                <div
                  className="acc-popular-img-box"
                  onClick={() => resetCoinAction()}
                  style={{ cursor: "pointer" }}
                >
                  <img className="acc-popular-img" src={closepop} alt="" />
                </div>
              </div>
              <>
                {coinAction.includes("Menu") ? (
                  <div>
                    <div className="acc-step-text1">
                      What would you like to do?
                    </div>
                    <div
                      className="acc-step-box2"
                      onClick={() => {
                        setCoinAction(["Add"]);
                      }}
                    >
                      Add
                    </div>
                    <div
                      className="acc-step-box2"
                      // onClick={() => {
                      //   setCoinAction(["Withdraw"]);
                      // }}
                    >
                      Withdraw
                    </div>
                    <div
                      className="acc-step-box2"
                      // onClick={() => {
                      //   setCoinAction(["Transfer"]);
                      // }}
                    >
                      Transfer
                    </div>
                  </div>
                ) : coinAction.includes("Add") ? (
                  <div
                    style={{
                      height: "calc(100% - 3rem)",
                    }}
                  >
                    {addActionStep === 1 ? (
                      <>
                        <div className="acc-step-text1">
                          How do you want to add money?
                        </div>
                        <div className="scroll-box">
                          {paymentMethodData?.map((e, i) => {
                            return (
                              <div
                                className="acc-step-box2"
                                key={e?._id}
                                onClick={() => {
                                  setSelectedPaymentMethod(e?.metadata?.name);
                                }}
                                style={{
                                  borderColor:
                                    selectedPaymentMethod === e?.metadata?.name
                                      ? "#182542"
                                      : "#e7e7e7",
                                }}
                              >
                                <div>
                                  <img src={e?.metadata?.icon} alt="" />
                                </div>
                                <div>{e?.metadata?.name}</div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="back-next-btns">
                          <div
                            className="back-Btn"
                            onClick={() => {
                              setCoinAction(["Menu"]);
                              setForexPathId("");
                              setSelectedPaymentMethod("");
                            }}
                          >
                            Go Back
                          </div>
                          <div
                            className="next-Btn"
                            onClick={() => {
                              if (selectedPaymentMethod?.length > 0) {
                                setAddActionStep(2);
                                getPathId();
                              }
                            }}
                            style={{
                              opacity:
                                selectedPaymentMethod?.length > 0 ? "1" : "0.5",
                            }}
                          >
                            Next Step
                          </div>
                        </div>
                      </>
                    ) : addActionStep === 2 ? (
                      <>
                        <div className="acc-step-text1">
                          How much do you want to add?
                        </div>
                        <div className="scroll-box">
                          <div className="acc-step-box3">
                            <div className="coin-details-div">
                              <div style={{ width: "30px" }}>
                                <img src={selectedCoin?.coinImage} alt="" />
                              </div>
                              <div>{selectedCoin?.coinSymbol}</div>
                            </div>
                            <div className="amount-details-div">
                              <input
                                type="number"
                                placeholder="0.00"
                                onChange={(e) => {
                                  setAddForexAmount(e.target.value);
                                }}
                                value={addForexAmount}
                                onBlur={onBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="back-next-btns">
                          <div
                            className="back-Btn"
                            onClick={() => {
                              setAddActionStep(1);
                              setAddForexAmount("");
                            }}
                          >
                            Go Back
                          </div>
                          <div
                            className="next-Btn"
                            style={{ opacity: addForexAmount ? "1" : "0.5" }}
                            onClick={() => {
                              if (addForexAmount) {
                                getQuote();
                              }
                            }}
                          >
                            Next Step
                          </div>
                        </div>
                      </>
                    ) : addActionStep === 3 ? (
                      <>
                        <div className="acc-step-text1">
                          You will be depositing
                        </div>
                        <div className="scroll-box">
                          <div className="acc-step-box3">
                            <div className="coin-details-div">
                              <div style={{ width: "30px" }}>
                                <img src={selectedCoin?.coinImage} alt="" />
                              </div>
                              <div>{selectedCoin?.coinSymbol}</div>
                            </div>
                            <div className="amount-details-div">
                              {forexQuote?.finalFromAmount
                                ? forexQuote?.finalFromAmount?.toFixed(2)
                                : "0.00"}
                            </div>
                          </div>
                          <div
                            className="acc-step-text1"
                            style={{ marginTop: "4rem" }}
                          >
                            You will be recieving
                          </div>
                          <div className="acc-step-box3">
                            <div className="coin-details-div">
                              <div style={{ width: "30px" }}>
                                <img src={selectedCoin?.coinImage} alt="" />
                              </div>
                              <div>{selectedCoin?.coinSymbol}</div>
                            </div>
                            <div className="amount-details-div">
                              {forexQuote?.finalToAmount
                                ? forexQuote?.finalToAmount?.toFixed(2)
                                : "0.00"}
                            </div>
                          </div>
                        </div>
                        <div className="back-next-btns">
                          <div
                            className="back-Btn"
                            onClick={() => {
                              setAddActionStep(2);
                            }}
                          >
                            Go Back
                          </div>
                          <div className="next-Btn">Next Step</div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            </div>
          </div>
        )}
      </>

      {serviceActionEnabled && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="acc-popular" style={{ background: "#e5e5e5" }}>
            <div
              className="acc-popular-top"
              style={{
                display:
                  serviceActionStep === 3
                    ? "none"
                    : serviceActionStep === 7
                    ? "none"
                    : "flex",
              }}
            >
              <div className="acc-popular-head">
                {serviceActionStep === 1 || serviceActionStep === 2
                  ? "Service Actions"
                  : "Edit Service"}
              </div>
              <div
                className="acc-popular-img-box"
                onClick={() => {
                  setServiceActionEnabled(false);
                  setServiceActionStep(1);
                  setEditService({});
                }}
                style={{ cursor: "pointer" }}
              >
                <img className="acc-popular-img" src={closepop} alt="" />
              </div>
            </div>
            {serviceActionStep === 1 && (
              <>
                <div className="acc-addservice">
                  <div
                    className="each-acc-editservice-button"
                    onClick={() => {
                      setServiceActionStep(2);
                    }}
                  >
                    Delete Service
                  </div>
                  <div
                    className="each-acc-editservice-button"
                    onClick={() => {
                      setServiceActionStep(4);
                    }}
                  >
                    Edit Service
                  </div>
                </div>
              </>
            )}

            {serviceActionStep === 2 && (
              <>
                <div className="acc-addservice">
                  <div
                    className="each-acc-editservice-button"
                    onClick={() => {
                      deleteService();
                    }}
                  >
                    Confirm And Delete
                  </div>

                  <div
                    className="each-acc-editservice-button"
                    onClick={() => {
                      setServiceActionStep(1);
                    }}
                  >
                    Never Mind
                  </div>
                </div>

                {isloading && (
                  <div
                    className="loading-component"
                    style={{
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      display: "flex",
                    }}
                  >
                    <LoadingAnimation1 icon={lg1} width={200} />
                  </div>
                )}
              </>
            )}

            {serviceActionStep === 3 && (
              <div
                className="successMsg"
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                You have successfully deleted {selectedService?.nameOfService}.
                You will be redirected to the updated services list.
              </div>
            )}

            {serviceActionStep === 4 && (
              <>
                <div className="acc-addservice">
                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Name</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.nameOfService}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Name"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              nameOfService: e.target.value,
                            };
                          });
                        }}
                        value={editService?.nameOfService}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">
                      Description
                    </div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.description}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Description"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              description: e.target.value,
                            };
                          });
                        }}
                        value={editService?.description}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Type</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.serviceProvider}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceTypeList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.serviceProvider === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  serviceProvider: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Access</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.access}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceAccessList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.access === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  access: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Goal</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.goal}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Goal"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              goal: e.target.value,
                            };
                          });
                        }}
                        value={editService?.goal}
                      />
                    </div>
                  </div>

                  <div className="acc-upload" style={{ marginBottom: "0" }}>
                    <div
                      className="acc-upload-title"
                      style={{
                        width: "70px",
                        height: "70px",
                      }}
                    >
                      <img
                        src={selectedService?.icon}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="acc-upload-imgbox">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange1}
                        style={{ display: "none" }}
                        ref={fileInputRef1}
                      />
                      <img
                        className="acc-upload-img"
                        src={
                          isUploadLoading
                            ? upgif
                            : editService?.icon && editService?.icon !== ""
                            ? editService?.icon
                            : uploadv
                        }
                        alt=""
                        onClick={handleImageClick1}
                      />
                    </div>
                  </div>
                </div>
                <div className="acc-addservice-goBack-next">
                  <div>
                    <img
                      src={backIcon}
                      alt=""
                      onClick={() => {
                        setServiceActionStep(1);
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={nextIcon}
                      alt=""
                      onClick={() => {
                        setServiceActionStep(5);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {serviceActionStep === 5 && (
              <>
                <div className="acc-addservice">
                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Cost</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.cost}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceCostList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.cost === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  cost: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="each-acc-editservice-field"
                    style={{
                      display:
                        editService?.cost && editService?.cost === "free"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="each-editservice-fieldname">Price</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.price}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Price"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              price: e.target.value,
                            };
                          });
                        }}
                        value={editService?.price}
                      />
                    </div>
                  </div>

                  <div
                    className="each-acc-editservice-field"
                    style={{
                      display:
                        editService?.cost && editService?.cost === "free"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="each-editservice-fieldname">Discount</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.discountType}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceDiscountList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.discountType === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  discountType: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="each-acc-editservice-field"
                    style={{
                      display:
                        editService?.cost && editService?.cost === "free"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="each-editservice-fieldname">
                      Discount value
                    </div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.discountAmount}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Discount value"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              discountAmount: e.target.value,
                            };
                          });
                        }}
                        value={editService?.discountAmount}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Duration</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.duration}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Duration"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              duration: e.target.value,
                            };
                          });
                        }}
                        value={editService?.duration}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Features</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.features}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Features"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              features: e.target.value,
                            };
                          });
                        }}
                        value={editService?.features}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Status</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.status}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceStatusList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.status === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  status: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Grade Data</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.grade?.length > 0 &&
                          selectedService?.grade.map((e, i) => {
                            return selectedService?.grade?.length - 1 === i
                              ? e?.grade + "th" + " "
                              : e?.grade + "th" + "," + " ";
                          })}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceGradeList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                Array.isArray(editService?.grade) &&
                                editService?.grade.filter((itemm) => {
                                  return itemm?.grade === e;
                                })?.length > 0
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() =>
                              handleMultipleInputArray(e, "grade", "grade")
                            }
                          >
                            {e + "th"}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="acc-addservice-goBack-next">
                  <div>
                    <img
                      src={backIcon}
                      alt=""
                      onClick={() => {
                        setServiceActionStep(4);
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={nextIcon}
                      alt=""
                      onClick={() => {
                        setServiceActionStep(6);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {serviceActionStep === 6 && (
              <>
                <div className="acc-addservice">
                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">
                      Financial Data
                    </div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.financialSituation?.length > 0 &&
                          selectedService?.financialSituation.map((e, i) => {
                            return selectedService?.financialSituation?.length -
                              1 ===
                              i
                              ? e?.finance + " "
                              : e?.finance + "," + " ";
                          })}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceFinancialList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                Array.isArray(
                                  editService?.financialSituation
                                ) &&
                                editService?.financialSituation.filter(
                                  (itemm) => {
                                    return itemm?.finance === e;
                                  }
                                )?.length > 0
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() =>
                              handleMultipleInputArray(
                                e,
                                "financialSituation",
                                "finance"
                              )
                            }
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">
                      Stream Data
                    </div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.stream?.length > 0 &&
                          selectedService?.stream.map((e, i) => {
                            return selectedService?.stream?.length - 1 === i
                              ? e?.stream + " "
                              : e?.stream + "," + " ";
                          })}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceStreamList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                Array.isArray(editService?.stream) &&
                                editService?.stream.filter((itemm) => {
                                  return itemm?.stream === e;
                                })?.length > 0
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() =>
                              handleMultipleInputArray(e, "stream", "stream")
                            }
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Outcome</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values">
                        {selectedService?.outcome}
                      </div>
                    </div>
                    <div className="each-editservice-fied-input">
                      <input
                        type="text"
                        placeholder="Enter new Outcome"
                        onChange={(e) => {
                          setEditService((prev) => {
                            return {
                              ...prev,
                              outcome: e.target.value,
                            };
                          });
                        }}
                        value={editService?.outcome}
                      />
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">
                      Suitable Step
                    </div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.type}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceStepList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.type === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  type: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="each-acc-editservice-field">
                    <div className="each-editservice-fieldname">Iterations</div>
                    <div
                      className="each-editservice-fied-input"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="existing-values" style={{background: 'silver'}}>
                        {selectedService?.iterations}
                      </div>
                    </div>
                    <div className="each-editservice-fied-dd-ss">
                      {serviceIterationList?.map((e, i) => {
                        return (
                          <div
                            className="dd-fields"
                            style={{
                              border:
                                editService?.iterations === e
                                  ? "2px solid #59A2DD"
                                  : "",
                            }}
                            onClick={() => {
                              setEditService((prev) => {
                                return {
                                  ...prev,
                                  iterations: e,
                                };
                              });
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="acc-addservice-submit">
                  <div>
                    <img
                      src={backIcon}
                      alt=""
                      onClick={() => {
                        setServiceActionStep(5);
                      }}
                    />
                  </div>
                  <div
                    className="acc-addservice-submit-btn"
                    onClick={() => {
                      editServiceFields();
                    }}
                  >
                    Apply Changes
                  </div>
                </div>
                {isloading && (
                  <div
                    className="loading-component"
                    style={{
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      display: "flex",
                    }}
                  >
                    <LoadingAnimation1 icon={lg1} width={200} />
                  </div>
                )}
              </>
            )}

            {serviceActionStep === 7 && (
              <div
                className="successMsg"
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                You have successfully updated the changes for{" "}
                {selectedService?.nameOfService}
              </div>
            )}
          </div>
        </div>
      )}

      {addCompPlan && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            zIndex: "25",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="popularS">
            {addCompPlanStep === "step1" && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 9.5rem)" }}
                >
                  <div
                    className="each-action1"
                    onClick={() => {
                      setAddCompPlanStep("step2");
                      getAppsforUser();
                    }}
                  >
                    <div>Unilevel Standard</div>
                  </div>

                  <div
                    className="each-action1"
                    style={{ opacity: "0.5", cursor: "not-allowed" }}
                  >
                    <div>Binary Standard</div>
                  </div>
                </div>

                <div className="stepBtnss">
                  <div
                    style={{ background: "#1F304F" }}
                    onClick={() => {
                      setServiceActionEnabled(true);
                      setAddCompPlan(false);
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: "#59A2DD",
                      opacity: "0.25",
                      cursor: "not-allowed",
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === "step2" && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep("step1");
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 9.5rem)" }}
                >
                  <div className="subbTxt">
                    Which Marketplace Are You Listing On?
                  </div>
                  {isfetching
                    ? Array(10)
                        .fill(" ")
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : userCreatedApps &&
                      userCreatedApps?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            onClick={() => {
                              setAddCompPlanStep("step3");
                              setCompPlanApp(e?.app_code);
                            }}
                          >
                            <div>
                              <img src={e?.app_icon} alt="" />
                            </div>
                            <div>{e?.app_name}</div>
                          </div>
                        );
                      })}
                </div>

                <div className="stepBtnss">
                  <div
                    style={{ background: "#1F304F" }}
                    onClick={() => {
                      setAddCompPlanStep("step1");
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: "#59A2DD",
                      opacity: "0.25",
                      cursor: "not-allowed",
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === "step3" && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep("step1");
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 9.5rem)" }}
                >
                  <div className="subbTxt">
                    How Many Levels Will Be Paid Out?
                  </div>
                  <div className="each-action1">
                    <input type="number" onChange={handleLevelChange} />
                  </div>
                </div>

                <div className="stepBtnss">
                  <div
                    style={{ background: "#1F304F" }}
                    onClick={() => {
                      setAddCompPlanStep("step2");
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: "#59A2DD",
                      opacity: levels > 0 ? "1" : "0.25",
                      cursor: levels > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (levels > 0) {
                        setAddCompPlanStep("step4");
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === "step4" && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep("step1");
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 9.5rem)" }}
                >
                  <div className="subbTxt">
                    Enter Payout Percentage Per Level
                  </div>
                  {renderLevelInputs(inputValues, setInputValues)}
                </div>

                <div className="stepBtnss">
                  <div
                    style={{ background: "#1F304F" }}
                    onClick={() => {
                      setAddCompPlanStep("step3");
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      ...applyStyle(spreadFunc(inputValues)),
                      background: "#59A2DD",
                    }}
                    onClick={() => {
                      if (levels == inputValues.length) {
                        setAddCompPlanStep("step5");
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addCompPlanStep === "step5" && (
              <>
                <div className="head-txt">
                  <div>Add Comp Plan</div>
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep("step1");
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 9.5rem)" }}
                >
                  <div className="subbTxt">Enter Multiplier Per Level</div>
                  {renderLevelInputs(multiplier, setMultiplier)}
                </div>

                <div className="stepBtnss">
                  <div
                    style={{ background: "#1F304F" }}
                    onClick={() => {
                      setAddCompPlanStep("step4");
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: "#59A2DD",
                      ...applyStyle(spreadFunc(multiplier)),
                    }}
                    onClick={() => {
                      addComplan();
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {addingComp && (
              <div
                className="loading-component"
                style={{
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                }}
              >
                <LoadingAnimation1 icon={lg1} width={200} />
              </div>
            )}

            {addCompPlanStep === "step6" && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    onClick={() => {
                      setAddCompPlan(false);
                      setAddCompPlanStep("step1");
                      setSelectedService("");
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div
                  className="overall-div"
                  style={{ height: "calc(100% - 6rem)" }}
                >
                  <div className="successMsg">
                    You Have Successfully Added A CompPlan To{" "}
                    {selectedService?.product_name}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AccDashboard;

export const ImageUploadDivProfilePic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useStore();

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown("")}
      style={{
        width: "120px",
        height: "120px",
        border: "0.5px solid #e7e7e7",
        borderRadius: "50%",
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: "100%", width: "100%", marginRight: "0" }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: "100%",
            height: "100%",
            marginBottom: "0",
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};
