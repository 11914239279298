import axios from "axios";
import Axios from "axios";
export const getRegisteredApp = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  let email = userDetails?.user?.email;
  return axios.get(
    `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`, 
    // {
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // },
    // }
  );
};

export const getUserDetails = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  let email = userDetails?.user?.email;
  return axios.get(
    `https://comms.globalxchange.io/user/details/get?email=${email}`, 
    // {
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // },
    // }
  );
};

export const fetchAllBankers = () => {
  return Axios(`https://teller2.apimachine.com/admin/allBankers`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export function fetchAllCoins() {
  return Axios.get(`https://comms.globalxchange.io/coin/vault/get/all/coins`, 
  // {
    // headers: {
    //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    // },
  // }
  );
}
export const conversionAPI = (from, to) => {
  return Axios(
    `https://comms.globalxchange.io/forex/convert?buy=${from}&from=${to}`, 
    // {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // }
  );
};
export const allCoinsConversion = (coin) => {
  return Axios.get(
    `https://comms.globalxchange.io/coin/getCmcPrices?convert=${coin}`, 
    // {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // }
  );
};

export const bondEarningList = (email, coin) => {
  return axios.get(
    `https://comms.globalxchange.io/coin/iced/interest/logs/get?email=${email}&coin=${coin}`, 
    // {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // }
  );
};

export const moneyMarketList = (email, app, coin) => {
  return Axios.get(
    `https://comms.globalxchange.io/coin/vault/service/user/app/interest/logs/get?email=${email}&app_code=${app}&coin=${coin}`, 
    // {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // }
  );
};
