import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// images
import close from "../../images/close.svg";
import backIcon from "../../static/images/icons/backIcon.svg";
import nextIcon from "../../static/images/icons/nextIcon.svg";

const UpdatedLevelThree = ({
  profileData,
  createLevelThree,
  setCreateLevelThree,
  handleProfileData,
}) => {
  const [loading, setLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState(0);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://oldnaavi.apimachine.com/question/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(({ data }) => {
        const initialData = data?.data;
        const sorted = initialData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        // console.log(sorted, "allQuestions");
        setAllQuestions(sorted);
        setLoading(false);
      });
  }, []);

  const handlePageClickNext = () => {
    if (page === 1) {
      setPage(2);
      // console.log(selectedAnswers, 'selectedAnswers');
    } else if (page === 2) {
      setPage(3);
      // console.log(selectedAnswers, 'selectedAnswers');
    } else if (page === 3) {
      setPage(4);
      // console.log(selectedAnswers, 'selectedAnswers');
    } else if (page === 4) {
      setPage(5);
      // console.log(selectedAnswers, 'selectedAnswers');
    } else if (page === 5) {
      setPage(6);
      // console.log(selectedAnswers, 'selectedAnswers');
    }
  };

  const handlePageClickBack = () => {
    if (page === 2) {
      setPage(1);
    } else if (page === 3) {
      setPage(2);
    } else if (page === 4) {
      setPage(3);
    } else if (page === 5) {
      setPage(4);
    } else if (page === 6) {
      setPage(5);
    }
  };

  const handleAnswer = (ans, qn) => {
    // Check if the question has already been answered
    if (!selectedAnswers[qn._id]) {
      setAnsweredQuestions((prev) => prev + 1);
    }

    setSelectedAnswers((prev) => ({
      ...prev,
      [qn._id]: ans,
    }));
  };

  const AnswerOption = ({ option, isSelected, handleClick }) => {
    return (
      <div
        className={`answer-option ${isSelected ? "selected" : ""}`}
        onClick={handleClick}
      >
        {option}
      </div>
    );
  };

  const Question = ({
    question,
    answerOptions,
    handleAnswer,
    selectedAnswers,
  }) => {
    const [selectedAnswer, setSelectedAnswer] = useState(
      selectedAnswers[question._id] || null
    );

    useEffect(() => {
      setSelectedAnswer(selectedAnswers[question._id] || null);
    }, [selectedAnswers, question._id]);

    const handleClick = (ans) => {
      setSelectedAnswer(ans);
      handleAnswer(ans, question);
    };

    return (
      <div className="each-qn-ans-area">
        <div className="each-qn-area">{question?.question}</div>
        <div className="each-ans-area">
          {answerOptions.map((option, index) => (
            <AnswerOption
              key={index}
              option={option}
              isSelected={selectedAnswer === option}
              handleClick={() => handleClick(option)}
            />
          ))}
        </div>
      </div>
    );
  };

  const QuestionsArea = ({
    allQuestions,
    page,
    handleAnswer,
    selectedAnswers,
  }) => {
    return (
      <>
        {allQuestions
          ?.slice((page - 1) * 8, page * 8)
          .map((question, index) => (
            <Question
              key={question._id}
              question={question}
              answerOptions={[1, 2, 3, 4, 5]}
              handleAnswer={handleAnswer}
              selectedAnswers={selectedAnswers}
            />
          ))}
      </>
    );
  };

  const reset = () => {
    setCreateLevelThree(false);
    setAllQuestions([]);
    setPage(1);
    setSelectedAnswers({});
    setAnsweredQuestions(0);
  };

  const handleSubmitAnswers = () => {
    console.log("submitting answers");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.6)",
        zIndex: "25",
        top: "0px",
        left: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="popularS"
        style={{
          minWidth: "70%",
          maxWidth: "75%",
          background: "#e7e7e7",
        }}
      >
        <div
          className="head-txt"
          style={{
            height: "3rem",
          }}
        >
          <div>Naavi Profile Level Three</div>
          <div
            onClick={() => {
              reset();
            }}
            className="close-div"
          >
            <img src={close} alt="" />
          </div>
        </div>
        <div
          className="overall-div"
          style={{
            height: "calc(100% - 6rem)",
          }}
        >
          <div className="points-definition">
            <div>1: Strongly Agree</div>
            <div>2: Agree</div>
            <div>3: Agree or Disagree</div>
            <div>4: Disagree</div>
            <div>5: Strongly Disagree</div>
          </div>
          <div className="questions-area">
            {loading ? (
              Array(5)
                .fill("")
                .map((e, i) => {
                  return (
                    <div className="each-qn-ans-area" key={i}>
                      <Skeleton width={600} style={{ height: "40px" }} />
                      <Skeleton width={200} style={{ height: "40px" }} />
                    </div>
                  );
                })
            ) : (
              <QuestionsArea
                allQuestions={allQuestions}
                page={page}
                handleAnswer={handleAnswer}
                selectedAnswers={selectedAnswers}
              />
            )}
          </div>
        </div>
        <div className="backNext-Btns">
          <div
            style={{
              opacity: page === 1 ? "0.25" : "1",
              cursor: page === 1 ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              handlePageClickBack();
            }}
          >
            <img src={backIcon} alt="" />
          </div>
          <div style={{ width: "auto", height: "auto" }}>
            Test progress: {answeredQuestions} / 48
          </div>
          {page === 6 ? (
            <div
              className="submit-answers"
              style={{
                opacity: answeredQuestions === 48 ? "1" : "0.25",
                cursor: answeredQuestions === 48 ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (answeredQuestions === 48) {
                  handleSubmitAnswers();
                }
              }}
            >
              Submit
            </div>
          ) : (
            <div
              onClick={() => {
                handlePageClickNext();
              }}
            >
              <img src={nextIcon} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatedLevelThree;
