import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./homepage.scss";
import { useCoinContextData } from "../../context/CoinContext";
import useWindowDimensions from "../../utils/WindowSize";
import { useStore } from "../../components/store/store.ts";
import MobMenu from "../../components/mobMenu/mobMenu";
import Navbar from "../../components/Navbar/index.jsx";

//images
import homepageImg from "../../static/images/homepageImg.png";
import discoverIcon from "../../static/images/homepage/discoverIcon.svg";
import refineIcon from "../../static/images/homepage/refineIcon.svg";
import mentorIcon from "../../static/images/homepage/mentorIcon.svg";
import analyzeIcon from "../../static/images/homepage/analyzeIcon.svg";
import adjustIcon from "../../static/images/homepage/adjustIcon.svg";
import accomplishIcon from "../../static/images/homepage/accomplishIcon.svg";
import heroImg from "../../static/images/homepage/heroImg.png";
import close from "./close.svg";

const HomePage = () => {
  const navigate = useNavigate();
  const { setSchoolSearch } = useCoinContextData();
  const { width } = useWindowDimensions();
  const { mobMenuOpen } = useStore();
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);

  const hiwData = [
    {
      id: 1,
      name: "Discover",
      icon: discoverIcon,
      content: [
        {
          Purpose:
            "Begin by helping the individual discover their interests, strengths, weaknesses, and passions.",
          Activities:
            "Conduct assessments, interviews, and discussions to identify their goals, preferences, and values.",
          Outcome:
            "A clearer understanding of the individual's educational aspirations and potential career paths.",
        },
      ],
    },
    {
      id: 2,
      name: "Refine",
      icon: refineIcon,
      content: [
        {
          Purpose:
            "Refine the identified goals by narrowing down options based on the individual's skills, preferences, and available opportunities",
          Activities:
            "Review and discuss potential educational paths, majors, and career options. Identify areas of improvement and set realistic goals.",
          Outcome: "A more focused and tailored educational and career plan.",
        },
      ],
    },
    {
      id: 3,
      name: "Get Mentored",
      icon: mentorIcon,
      content: [
        {
          Purpose:
            "Seek guidance and support from experienced mentors or counselors who can provide insights and advice.",
          Activities:
            "Connect the individual with mentors, professionals, or experts in their chosen field. Facilitate mentorship sessions and encourage networking.",
          Outcome:
            "Enhanced understanding, perspective, and guidance from mentors to help navigate the educational and career journey.",
        },
      ],
    },
    {
      id: 4,
      name: "Analyze",
      icon: analyzeIcon,
      content: [
        {
          Purpose:
            "Analyze the progress made towards the educational and career goals.",
          Activities:
            "Evaluate academic performance, skills development, and any challenges faced. Reflect on experiences and feedback.",
          Outcome:
            "A comprehensive understanding of the individual's growth and areas that may need further attention or adjustment.",
        },
      ],
    },
    {
      id: 5,
      name: "Adjust",
      icon: adjustIcon,
      content: [
        {
          Purpose:
            "Make necessary adjustments to the educational and career plan based on the analysis.",
          Activities:
            "Identify any setbacks or areas of improvement. Make changes to the plan, such as exploring additional courses, gaining more experience, or refining goals.",
          Outcome:
            "A revised and adaptable plan that aligns with the individual's evolving aspirations and circumstances.",
        },
      ],
    },
    {
      id: 6,
      name: "Accomoplish",
      icon: accomplishIcon,
      content: [
        {
          Purpose:
            "Celebrate achievements and milestones as the individual progresses towards their educational and career goals.",
          Activities:
            "Recognize accomplishments, whether they are academic achievements, skill development, or successful career steps.",
          Outcome:
            "A sense of accomplishment, motivation, and readiness for the next phase in the individual's educational and career journey.",
        },
      ],
    },
  ];

  return (
    <div className="homepage">
      <Navbar />
      {width > 768 ? (
        <div className="homepage-content">
          <div className="cover-Img">
            <img src={homepageImg} alt="" />
            <div className="background-tint"></div>
            <div className="mid-text">Find Your Next Adventure</div>
            <div className="background-tint1"></div>
            <div className="input-box-container">
              <div className="input-box1">
                <input
                  type="text"
                  placeholder="Which school do you want to go to?"
                  onChange={(e) => {
                    setSchoolSearch(e.target.value);
                  }}
                />
              </div>
              <div
                className="createPath-btn"
                onClick={() => {
                  navigate("/maps");
                }}
              >
                Find Path
              </div>
            </div>
          </div>
          <div className="hiw-container">
            <div className="hiw-text">How It Works</div>
            <div className="hiw-options">
              {hiwData.map((e, i) => {
                return (
                  <div
                    className="each-hiw-option"
                    key={e.id}
                    onClick={() => {
                      setSelectedOption(true);
                      setSelectedContent(e);
                    }}
                  >
                    <div className="img-border">
                      <img src={e.icon} alt="" />
                    </div>
                    <div className="each-hiw-option-name">{e.name}</div>
                  </div>
                );
              })}
              <div className="centre-line"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!mobMenuOpen ? (
            <div className="homepage-content">
              <div className="cover-Img-mobile">
                <img src={heroImg} alt="" />
                <div className="background-tint-mobile"></div>
                <div className="mid-text-mobile">Find Your Next Adventure</div>
                <div className="background-tint1-mobile"></div>
                <div className="input-box-container-mobile">
                  <div className="input-box1-mobile">
                    <input type="text" placeholder="What Do You Want?" />
                    <div
                      className="createPath-btn-mobile"
                      onClick={() => {
                        navigate("/maps");
                      }}
                    >
                      Go
                    </div>
                  </div>
                </div>
              </div>
              <div className="hiw-container">
                <div className="hiw-text">How It Works</div>
                <div className="hiw-options">
                  {hiwData.map((e, i) => {
                    return (
                      <div className="each-hiw-option" key={e.id}>
                        <div className="img-border">
                          <img src={e.icon} alt="" />
                        </div>
                        <div className="each-hiw-option-name">{e.name}</div>
                      </div>
                    );
                  })}
                  <div className="centre-line"></div>
                </div>
              </div>
            </div>
          ) : (
            <MobMenu />
          )}
        </>
      )}

      {width > 768 && selectedOption && (
        <div className="homepage-overlay">
          <div
            className="steps-content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="steps-content-close"
              onClick={() => {
                setSelectedOption(false);
                setSelectedContent([]);
              }}
            >
              <img src={close} alt="" />
            </div>
            <div className="each-content1">
              <div className="each-content-heading1">
                {selectedContent?.name}
              </div>
              <div className="each-content-img">
                <img src={selectedContent?.icon} alt="" />
              </div>
            </div>
            <div className="each-content">
              <div className="each-content-heading">Purpose:</div>
              <div>{selectedContent?.content[0].Purpose}</div>
            </div>
            <div className="each-content">
              <div className="each-content-heading">Activities:</div>
              <div>{selectedContent?.content[0].Activities}</div>
            </div>
            <div className="each-content">
              <div className="each-content-heading">Outcome:</div>
              <div>{selectedContent?.content[0].Outcome}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
